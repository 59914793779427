import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { createSlice } from "@reduxjs/toolkit";
import { handleTokenErrors } from "./handleTokenErrors";

const baseurl = process.env.REACT_APP_API_BASE_URL;

const initialState = {
  data: [],
  isLoading: false,
  isSuccess: false,
  errorMessage: "",
};
export const searchlist = createAsyncThunk(
  "search/searchlist",
  async ({ category, name, metal, color, sku, freeze }, { rejectWithValue }) => {
    const token = localStorage.getItem("accessToken");
    try {    
      const payload = {};
      if (category && category?.length > 0) payload.category = category;
      if (name) payload.name = name;
      if (metal) payload.metal = metal;
      if (color) payload.color = color;
      if (sku) payload.sku = sku;
      if (freeze) payload.freeze = freeze;

      const response = await axios.post(
        `${baseurl}/api/productall`,
        payload,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Accept": "*/*",
          },
        }
      );
      
      return response?.data;
    } catch (error) {
      handleTokenErrors(error);
      return rejectWithValue(error?.message);
    }
  }
);

export const getSearchlistSlice = createSlice({
  name: "searchlist",
  initialState,
  extraReducers: {
    [searchlist.pending]: (state) => {
      state.isLoading = true;
    },
    [searchlist.fulfilled]: (state, { payload }) => {
      state.isLoading = false;
      state.isSuccess = true;
      state.data = payload;
    },
    [searchlist.rejected]: (state, { payload }) => {
      state.isLoading = false;
      state.isSuccess = false;
      state.errorMessage = payload;
    },
  },
});

export default getSearchlistSlice.reducer;

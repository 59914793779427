import React, { useState, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { toast } from 'react-toastify';

const url = process.env.REACT_APP_API_BASE_URL;

const EmailSettings = () => {
    const { register, handleSubmit, setValue } = useForm();
    const [mailReceiverData, setMailReceiverData] = useState([]);
    const [bccFields, setBccFields] = useState([]);
    const [ccFields, setCcFields] = useState([]);
    const [selectedSection, setSelectedSection] = useState(null);

    useEffect(() => {
        const getMailReceiver = async () => {
            const accessToken = localStorage.getItem('accessToken');
            try {
                const response = await fetch(`${url}/api/admin/emailreceiver`, {
                    method: "GET",
                    headers: {
                        "Accept": "*/*",
                        'Authorization': `Bearer ${accessToken}`,
                    },
                });
                const data = await response.json();
                               
                setMailReceiverData(data.document);
                if (data.document.length > 0 ) {
                    const firstSection = data.document[0].name;
                    setSelectedSection(firstSection);
                    const filteredData = data.document.find(mail => mail.name === selectedSection);
                    if (filteredData) {
                        setValue('to', filteredData.to);
                        setCcFields(filteredData.cc || []);
                        setBccFields(filteredData.bcc || []);
                    }
                }
            } catch (error) {
                toast.error("Error fetching mail receiver data:", error);
            }
        };
        getMailReceiver();
    }, [selectedSection, setValue]);

    const handleSectionChange = (section) => {
        setSelectedSection(section);
    };

    const onSubmit = async (formData) => {
        const accessToken = localStorage.getItem('accessToken');
    
        const filteredMailData = mailReceiverData.filter((mail) => mail.name === selectedSection);
        const selectedMailConfig = filteredMailData[0];
    
        if (!selectedMailConfig) {
            toast.error("No mail configuration found.");
            return;
        }
    
        try {
            const response = await fetch(`${url}/api/admin/emailreceiver`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${accessToken}`,
                    "Accept": "*/*",
                },
                body: JSON.stringify({
                    id: selectedMailConfig._id,
                    name: selectedSection,
                    to: formData.to.trim(),
                    cc: ccFields.filter(email => email?.trim() !== ''),
                    bcc: bccFields.filter(email => email?.trim() !== ''),
                }),
            });
    
            const data = await response.json();
         
          if(data.error) {
            toast.error(data?.error)
        }else{
            toast.success(data?.message)
          }
          
        } catch (error) {
            toast.error('Error:', error);
        }
    };
    
    const handleCcChange = (index, value) => {
        const updatedCcFields = [...ccFields];
        updatedCcFields[index]   = value;
        setCcFields(updatedCcFields);
    };

    const addNewCcField = () => {
        setCcFields([...ccFields, '']);
    };

    const handleBccChange = (index, value) => {
        const updatedBccFields = [...bccFields];
        updatedBccFields[index] = value;
        setBccFields(updatedBccFields);
    };

    const addNewBccField = () => {
        setBccFields([...bccFields, '']);
    };

    return (
        <div className=" mt-4">
            <div className="card ">
                <div className="card-body ">
                    <div className='text-center'>
            <h2>Email receiving Ids</h2>
            <div className="section-selector mb-3">
                {mailReceiverData.map((data, id)=>{
                    return(
                        <button
                        key={id}
                        className={`btn-sm btn-outline-dark mx-1 my-3 ${selectedSection === data.name ? 'active' : ''}`}
                        onClick={() => handleSectionChange(data.name)}
                    >
                        {data.name}
                    </button>
                    )
                } )}              
            </div>
            </div>
            <div className="row justify-content-center">
            <div className="col-lg-6 mt-4  ">
            <form noValidate onSubmit={handleSubmit(onSubmit)} className="border p-4 rounded shadow">
                {/* <h5>{selectedSection.replace(/([A-Z])/g, ' $1').replace(/^./, str => str.toUpperCase())}</h5> */}

                <div className="my-2">
                <p className='mb-3'>To</p>
                    {/* <label htmlFor="to" className="form-label">To</label> */}
                    <input id="to" type="email" className="form-control" {...register('to', { required: true })} />
                </div>
                <div className='d-flex justify-content-between'>
                <p>CC</p>
                <button type="button" className="btn-sm btn-secondary mb-3" onClick={addNewCcField}>+ Add CC</button>
                </div>
                {ccFields.map((field, index) => (
                    <div className="input-group mb-2" key={index}>
                        <input
                            type="text"
                            className="form-control"
                            defaultValue={field}
                            onChange={(e) => handleCcChange(index, e.target.value)}
                        />
                    </div>
                ))}
               
                <div className='d-flex justify-content-between my-2'>
                <p>BCC</p>
                <button type="button"  className="btn-sm btn-secondary" onClick={addNewBccField}>+ Add BCC</button>
                </div>
                {bccFields.map((field, index) => (
                    <div className="input-group mb-2" key={index}>
                        <input
                            type="text"
                            className="form-control"
                            defaultValue={field}
                            onChange={(e) => handleBccChange(index, e.target.value)}
                        />
                    </div>
                ))}            
                <div className='text-center'>
                <button type="submit" className="btn-sm custom-btn">Save</button>
                </div>
            </form>
            </div>
            </div>
        </div>
        </div>
        </div>
    );
};

export default EmailSettings;



// import React from 'react'
// import { useState } from 'react';
// import { toast } from 'react-toastify';
// import { useEffect } from 'react';
// import { useForm } from 'react-hook-form';
// const url = process.env.REACT_APP_API_BASE_URL;

// const EmailSettings = () => {
//     const [selectedSection, setSelectedSection] = useState('productinquiry');
//     const {
//         register,
//         handleSubmit,
//     } = useForm();

//     const [mailReceiverData, setMailReceiverData] = useState([]);

//     useEffect(() => {
//         const getMailReceiver = async () => {
//             let accessToken = localStorage.getItem('accessToken');
//             try {
//                 const response = await fetch(`${url}/api/admin/emailreceiver`, {
//                     method: "GET",
//                     headers: {
//                         "Accept": "*/*",
//                         'Authorization': `Bearer ${accessToken}`
//                     },
//                 });

//                 const data = await response.json();
//                 setMailReceiverData(data.document);
//             } catch (error) {
//                 toast.error("Error fetching mail receiver data:", error);
//             }
//         }

//         getMailReceiver();
//     }, []);

//     //BCC
//     const [bccFields, setBccFields] = useState([]);
//     const [bccFields1, setBccFields1] = useState([]);
//     const [bccFields2, setBccFields2] = useState([]);
//     const [bccFields3, setBccFields3] = useState([]);

//     const addNewBccField = (section) => {
//         if (section === 'productinquiry') {
//             setBccFields([...bccFields, { id: bccFields.length + 1, value: '' }]);
//         } else if (section === 'contactinquiry') {
//             setBccFields1([...bccFields1, { id: bccFields1.length + 1, value: '' }]);
//         } else if (section === 'bookappointment') {
//             setBccFields2([...bccFields2, { id: bccFields2.length + 1, value: '' }]);
//         }
//     };

//     const handleBccChange = (id, value) => {
//         const updatedBccFields = bccFields.map((field) =>
//             field.id === id ? { ...field, value } : field
//         );
//         setBccFields(updatedBccFields);
//     };

//     //CC
//     const [ccFields, setCcFields] = useState([]);
//     const [ccFields1, setCcFields1] = useState([]);
//     const [ccFields2, setCcFields2] = useState([]);

//     const addNewCcField = (section) => {
//         if (section === 'productinquiry') {
//             setCcFields((prevFields) => [...prevFields, { id: prevFields.length + 1, value: '' }]);
//         } else if (section === 'contactinquiry') {
//             setCcFields1((prevFields) => [...prevFields, { id: prevFields.length + 1, value: '' }]);
//         } else if (section === 'bookappointment') {
//             setCcFields2((prevFields) => [...prevFields, { id: prevFields.length + 1, value: '' }]);
//         }
//     };

//     const handleCcChange = (id, value, section) => {
//         if (section === 'productinquiry') {
//             const updatedCcFields = ccFields.map((field) =>
//                 field.id === id ? { ...field, value } : field
//             );
//             setCcFields(updatedCcFields);
//         } else if (section === 'contactinquiry') {
//             const updatedCcFields = ccFields1.map((field) =>
//                 field.id === id ? { ...field, value } : field
//             );
//             setCcFields1(updatedCcFields);
//         } else if (section === 'bookappointment') {
//             const updatedCcFields = ccFields2.map((field) =>
//                 field.id === id ? { ...field, value } : field
//             );
//             setCcFields2(updatedCcFields);
//         }
//     };
//     const handleSectionChange = (section) => {
//         setSelectedSection(section);
//     };
//     const filteredMailData = mailReceiverData?.filter((mail) => mail.name === selectedSection);
//     const emailName = filteredMailData?.[0]?.name
//     const _id = filteredMailData?.[0]?._id
//     const onSubmit = async (formData) => {
//         let accessToken = localStorage.getItem('accessToken');

//         try {
//             const response = await fetch(`${url}/api/admin/emailreceiver`, {
//                 method: 'POST',
//                 headers: {
//                     'Content-Type': 'application/json',
//                     'Authorization': `Bearer ${accessToken}`,
//                     "Accept": "*/*",
//                 },
//                 body: JSON.stringify({
//                     name: emailName,
//                     id: _id,
//                     to: emailName === "bookappointment" ? formData?.to2?.trim() :
//                         emailName === "contactinquiry" ? formData?.to1?.trim() :
//                             emailName === "productinquiry" ? formData?.to?.trim() : '',
//                     cc: emailName === "bookappointment" ? (Array.isArray(formData?.cc2) ? formData.cc2.filter(email => email?.trim() !== '') : []) :
//                         emailName === "contactinquiry" ? (Array.isArray(formData?.cc1) ? formData.cc1.filter(email => email?.trim() !== '') : []) :
//                             emailName === "productinquiry" ? (Array.isArray(formData?.cc) ? formData.cc.filter(email => email?.trim() !== '') : []) : [],
//                     bcc: emailName === "bookappointment" ? (Array.isArray(formData?.bcc2) ? formData.bcc2.filter(email => email?.trim() !== '') : []) :
//                         emailName === "contactinquiry" ? (Array.isArray(formData?.bcc1) ? formData.bcc1.filter(email => email?.trim() !== '') : []) :
//                             emailName === "productinquiry" ? (Array.isArray(formData?.bcc) ? formData.bcc.filter(email => email?.trim() !== '') : []) : []
//                 }),
//             });

//             const data = await response.json();
//             data?.code ? toast.error(data?.originalError) : toast.success(data?.message);

//         } catch (error) {
//             toast.error('Error:', error);
//         }
//     };



//     return (
//         <div>
//             <div className="mt-4">
//                 <div className="card">
//                     <div className="card-body">
//                         <form noValidate onSubmit={handleSubmit(onSubmit)}>
//                             <h4 className='title'>Email receiving Ids:</h4>
//                             <div className="row">
//                                 <div className="col-lg-4 mt-4">
//                                     <div className='subtitle'>
//                                         <h6>Product Inquiry</h6>
//                                     </div>
//                                     {mailReceiverData?.map((mail, i) => {
//                                         return (
//                                             mail.name === "productinquiry" ?
//                                                 <div className="form-group" key={i}>
//                                                     <label className="form-control-label">To</label>
//                                                     <input type="email"
//                                                         placeholder="email"
//                                                         formcontrolname="email"
//                                                         name="email"
//                                                         className="form-control border"
//                                                         defaultValue={mail.to}
//                                                         required
//                                                         {...register(`to`)}
//                                                     />
//                                                 </div> : ''
//                                         )
//                                     }
//                                     )}
//                                     {mailReceiverData?.map((mail, i) => {
//                                         return (
//                                             mail.name === "productinquiry" ?
//                                                 <div className="form-group" key={i}>
//                                                     <div className="form-group d-flex justify-content-between">
//                                                         <label className="form-control-label">CC</label>
//                                                         <span type="button" onClick={() => addNewCcField('productinquiry')} className="">
//                                                             +Add
//                                                         </span>
//                                                     </div>
//                                                     {mail?.cc?.map((ccData, index) => (
//                                                         <div key={index} className="input-group mb-2">
//                                                             <input
//                                                                 type="email"
//                                                                 placeholder="email"
//                                                                 formcontrolname="email"
//                                                                 name="email"
//                                                                 className="form-control border"
//                                                                 defaultValue={ccData}
//                                                                 onChange={(e) => handleCcChange(index, e.target.value)}
//                                                                 {...register(`cc[${index}]`)}
//                                                             />
//                                                         </div>
//                                                     ))}
//                                                     {ccFields.map((field, index) => (
//                                                         <div key={field.id} className="input-group mb-2">
//                                                             <input
//                                                                 type="email"
//                                                                 placeholder="email"
//                                                                 formcontrolname="email"
//                                                                 name="email"
//                                                                 className="form-control border"
//                                                                 defaultValue={field.value}
//                                                                 onChange={(e) => handleCcChange(index + mail?.cc?.length, e.target.value)}
//                                                                 {...register(`cc[${mail?.cc?.length + index}]`)}
//                                                             />
//                                                         </div>
//                                                     ))}

//                                                 </div> : ''
//                                         );
//                                     })}
//                                     {mailReceiverData?.map((mail, i) => {
//                                         return (
//                                             mail.name === "productinquiry" ? (
//                                                 <div className="form-group" key={i}>
//                                                     <div className="form-group d-flex justify-content-between">
//                                                         <label className="form-control-label">BCC</label>
//                                                         <span type="button" onClick={() => addNewBccField('productinquiry')} className="">
//                                                             +Add
//                                                         </span>
//                                                     </div>

//                                                     {mail?.bcc?.map((bccData, index) => (
//                                                         <div key={index} className="input-group mb-2">
//                                                             <input
//                                                                 type="email"
//                                                                 placeholder="email"
//                                                                 formcontrolname="email"
//                                                                 name="email"
//                                                                 className="form-control border"
//                                                                 defaultValue={bccData}
//                                                                 onChange={(e) => handleBccChange(index, e.target.value)}
//                                                                 {...register(`bcc[${index}]`)}
//                                                             />
//                                                         </div>
//                                                     ))}

//                                                     {bccFields.map((field, index) => (
//                                                         <div key={field.id} className="input-group mb-2">
//                                                             <input
//                                                                 type="email"
//                                                                 placeholder="email"
//                                                                 formcontrolname="email"
//                                                                 name="email"
//                                                                 className="form-control border"
//                                                                 defaultValue={field.value}
//                                                                 onChange={(e) => handleBccChange(mail?.bcc?.length + index, e.target.value)}
//                                                                 {...register(`bcc[${mail?.bcc?.length + index}]`)}
//                                                             />
//                                                         </div>
//                                                     ))}
//                                                 </div>
//                                             ) : null
//                                         );
//                                     })}

//                                     <div className="form-group d-flex justify-content-end">
//                                         <button type="submit" value="Save" className="btn-sm custom-btn" onClick={() => handleSectionChange('productinquiry')} >Save
//                                         </button>
//                                     </div>
//                                 </div>

//                                 <div className="col-lg-4 mt-4">
//                                     <div className='subtitle'>
//                                         <h6>Contact Inquiry</h6>
//                                     </div>
//                                     {mailReceiverData?.map((mail, i) => {
//                                         return (
//                                             mail.name === "contactinquiry" ? <div className="form-group">
//                                                 <label className="form-control-label">To</label>
//                                                 <input type="email"
//                                                     placeholder="email"
//                                                     formcontrolname="email"
//                                                     name="email"
//                                                     className="form-control border"
//                                                     defaultValue={mail.to}
//                                                     required
//                                                     {...register(`to1`)}
//                                                 />
//                                             </div> : ''
//                                         )
//                                     }
//                                     )}
//                                     {mailReceiverData?.map((mail, i) => {
//                                         return (
//                                             mail.name === "contactinquiry" ?
//                                                 <div className="form-group" key={i}>
//                                                     <div className="form-group d-flex justify-content-between">
//                                                         <label className="form-control-label">CC</label>
//                                                         <span type="button" onClick={() => addNewCcField('contactinquiry')} className="">
//                                                             +Add
//                                                         </span>
//                                                     </div>
//                                                     {mail?.cc?.map((bccData, index) => (
//                                                         <div key={index} className="input-group mb-2">
//                                                             <input
//                                                                 type="text"
//                                                                 placeholder="email"
//                                                                 formcontrolname="email"
//                                                                 name="email"
//                                                                 className="form-control border"
//                                                                 defaultValue={bccData}
//                                                                 onChange={(e) => handleBccChange(index, e.target.value)}
//                                                                 {...register(`cc1[${index}]`)}
//                                                             />
//                                                         </div>
//                                                     ))}
//                                                     {ccFields1.map((field, index) => (
//                                                         <div key={field.id} className="input-group mb-2">
//                                                             <input
//                                                                 type="text"
//                                                                 placeholder="email"
//                                                                 formcontrolname="email"
//                                                                 name="email"
//                                                                 className="form-control border"
//                                                                 defaultValue={field.value}
//                                                                 onChange={(e) => handleBccChange(index + mail?.bcc?.length, e.target.value)}
//                                                                 {...register(`cc1[${mail?.cc?.length + index}]`)}
//                                                             />
//                                                         </div>
//                                                     ))}
//                                                 </div> : ''
//                                         );
//                                     })}
//                                     {mailReceiverData?.map((mail, i) => {
//                                         return (
//                                             mail.name === "contactinquiry" ?
//                                                 <div className="form-group" key={i}>
//                                                     <div className="form-group d-flex justify-content-between">
//                                                         <label className="form-control-label">BCC</label>
//                                                         <span type="button" onClick={() => addNewBccField('contactinquiry')} className="">
//                                                             +Add
//                                                         </span>
//                                                     </div>
//                                                     {mail?.bcc?.map((bccData, index) => (
//                                                         <div key={index} className="input-group mb-2">
//                                                             <input
//                                                                 type="text"
//                                                                 placeholder="email"
//                                                                 formcontrolname="email"
//                                                                 name="email"
//                                                                 className="form-control border"
//                                                                 defaultValue={bccData}
//                                                                 onChange={(e) => handleBccChange(index, e.target.value)}
//                                                                 {...register(`bcc1[${index}]`)}
//                                                             />
//                                                         </div>
//                                                     ))}
//                                                     {bccFields1.map((field, index) => (
//                                                         <div key={field.id} className="input-group mb-2">
//                                                             <input
//                                                                 type="text"
//                                                                 placeholder="email"
//                                                                 formcontrolname="email"
//                                                                 name="email"
//                                                                 className="form-control border"
//                                                                 defaultValue={field.value}
//                                                                 onChange={(e) => handleBccChange(index + mail?.bcc?.length, e.target.value)}
//                                                                 {...register(`bcc1[${mail?.bcc?.length + index}]`)}
//                                                             />
//                                                         </div>
//                                                     ))}
//                                                 </div> : ''
//                                         );
//                                     })}
//                                     <div className="form-group d-flex justify-content-end">
//                                         <button type="submit" value="Save" className="btn-sm custom-btn" onClick={() => handleSectionChange('contactinquiry')} >Save
//                                         </button>

//                                     </div>
//                                 </div>

//                                 <div className="col-lg-4 mt-4">
//                                     <div className='subtitle'>
//                                         <h6>Book Appointment</h6>
//                                     </div>
//                                     {mailReceiverData?.map((mail, i) => {
//                                         return (
//                                             mail.name === "bookappointment" ? <div className="form-group">
//                                                 <label className="form-control-label">To</label>
//                                                 <input type="email"
//                                                     placeholder="email"
//                                                     formcontrolname="email"
//                                                     name="email"
//                                                     className="form-control border"
//                                                     defaultValue={mail.to}
//                                                     required
//                                                     {...register(`to2`)}
//                                                 />
//                                             </div> : ''
//                                         )
//                                     }
//                                     )}
//                                     {mailReceiverData?.map((mail, i) => {
//                                         return (
//                                             mail.name === "bookappointment" ?
//                                                 <div className="form-group" key={i}>
//                                                     <div className="form-group d-flex justify-content-between">
//                                                         <label className="form-control-label">CC</label>
//                                                         <span type="button" onClick={() => addNewCcField('bookappointment')} className="">
//                                                             +Add
//                                                         </span>
//                                                     </div>
//                                                     {mail?.cc?.map((bccData, index) => (
//                                                         <div key={index} className="input-group mb-2">
//                                                             <input
//                                                                 type="text"
//                                                                 placeholder="email"
//                                                                 formcontrolname="email"
//                                                                 name="email"
//                                                                 className="form-control border"
//                                                                 defaultValue={bccData}
//                                                                 onChange={(e) => handleBccChange(index, e.target.value)}
//                                                                 {...register(`cc2[${index}]`)}
//                                                             />
//                                                         </div>
//                                                     ))}
//                                                     {ccFields2.map((field, index) => (
//                                                         <div key={field.id} className="input-group mb-2">
//                                                             <input
//                                                                 type="text"
//                                                                 placeholder="email"
//                                                                 formcontrolname="email"
//                                                                 name="email"
//                                                                 className="form-control border"
//                                                                 defaultValue={field.value}
//                                                                 onChange={(e) => handleBccChange(index + mail?.bcc?.length, e.target.value)}
//                                                                 {...register(`cc2[${mail?.cc?.length + index}]`)}
//                                                             />
//                                                         </div>
//                                                     ))}
//                                                 </div> : ''
//                                         );
//                                     })}
//                                     {mailReceiverData?.map((mail, i) => {
//                                         return (
//                                             mail.name === "bookappointment" ?
//                                                 <div className="form-group" key={i}>
//                                                     <div className="form-group d-flex justify-content-between">
//                                                         <label className="form-control-label">BCC</label>
//                                                         <span type="button" onClick={() => addNewBccField('bookappointment')} className="">
//                                                             +Add
//                                                         </span>
//                                                     </div>
//                                                     {mail?.bcc?.map((bccData, index) => (
//                                                         <div key={index} className="input-group mb-2">
//                                                             <input
//                                                                 type="text"
//                                                                 placeholder="email"
//                                                                 formcontrolname="email"
//                                                                 name="email"
//                                                                 className="form-control border"
//                                                                 defaultValue={bccData}
//                                                                 onChange={(e) => handleBccChange(index, e.target.value)}
//                                                                 {...register(`bcc2[${index}]`)}
//                                                             />
//                                                         </div>
//                                                     ))}
//                                                     {bccFields2.map((field, index) => (
//                                                         <div key={field.id} className="input-group mb-2">
//                                                             <input
//                                                                 type="text"
//                                                                 placeholder="email"
//                                                                 formcontrolname="email"
//                                                                 name="email"
//                                                                 className="form-control border"
//                                                                 defaultValue={field.value}
//                                                                 onChange={(e) => handleBccChange(index + mail?.bcc?.length, e.target.value)}
//                                                                 {...register(`bcc2[${mail?.bcc?.length + index}]`)}
//                                                             />
//                                                         </div>
//                                                     ))}
//                                                 </div> : ''
//                                         );
//                                     })}
//                                     <div className="form-group d-flex justify-content-end">
//                                         <button type="submit" value="Save" className="btn-sm custom-btn" onClick={() => handleSectionChange('bookappointment')} >Save
//                                         </button>

//                                     </div>
//                                 </div>
//                             </div>
//                         </form>
//                     </div>
//                 </div>
//             </div>
//         </div>
//     )
// }

// export default EmailSettings
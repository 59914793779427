import "./App.css";
import "datatables.net-dt/css/jquery.dataTables.css";
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import Dashboard from "./component/Dashboard";
import Menu from "./Masters/Menu";
import Testimonial from "./Masters/Testimonial";
import TestiminialForm from "./Masters/TestiminialForm";
import Category from "./Product/Category";
import AddnewCategory from "./Product/AddNewCategory";
import ProductList from "./Product/ProductList";
import ProductForm from "./Product/ProductForm";
import Searchlist from "./Product/Searchlist";
import Addnewsevent from "./Masters/AddNewsEvent";
import HomeSlider from "./Banners/HomeSlider";
import AddBannerSlider from "./Banners/AddBannerSlider";
import MiddleImage from "./Banners/BoxImage";
import HomeVideo from "./Banners/HomeVideo";
import HomeVideoForm from "./Banners/HomeVideoForm";
import Managebrands from "./Brands/Brand";
import Addnewbrand from "./Brands/AddBrand";
import Login from "./Pages/Login";
import ChangePassword from "./Pages/ChangePassword";
import ForgotPassword from "./Pages/ForgotPassword";
import SubCategory from "./Product/SubCategory";
import Socialmedia from "./Setting/Socialmedia";
import EmailTemplate from "./Setting/EmailTemplate";
import Backofficesetting from "./Setting/BackOfficeSetting";
import Brandproductlist from "./Brands/BrandProduct";
import { ToastContainer } from "react-toastify";
import Addbranch from "./Masters/AddBranch";
import AddSubcategory from "./Product/AddSubCategory";
import Sidebar from "./Layout/Sidebar";
import Addweblogo from "./Banners/Addweblogo";
import Inquirylist from "./Reports/InquiryList";
import Inquirylistdetails from "./Reports/InquiryDetails";
import Productinquarylist from "./Reports/ProductInquary";
import Productinquarylistdetails from "./Reports/ProductInquaryDetails";
import SecoSetting from "./Pages/SeoSetting";
import BookAppointment from "./Reports/BookAppointment";
import BookappointDetails from "./Reports/BookAppointDetails";
import Instagram from "./Pages/Instagram";
import GoogleReview from "./Pages/GoogleReview";
import Sitemap from "./Pages/Sitemap";
import Emailsender from "./Pages/EmailSender";
import Page from "./Masters/Page";
import AddPage from "./Masters/AddPage";
import Content from "./Masters/Content";
import AddContent from "./Masters/AddContent";
import AddMenu from "./Masters/AddMenu";
import Colors from "./Product/Colors";
import AddColor from "./Product/AddColor";
import MiddleImageForm from "./Banners/BoxImageForm";
import Metal from "./Product/Metal";
import AddMetal from "./Product/AddMetal";
import GoldPurity from "./Product/GoldPurity";
import AddGoldPurity from "./Product/AddGoldPurity";
import AddSocialMedia from "./Setting/AddSocialMedia";
import Setting from "./Setting/Setting";
import Branch from "./Masters/Branch";
import NewsEvent from "./Masters/NewsEvent";
import ImportExportProduct from "./Product/ImportExportProduct";
import SubMenu from "./Masters/SubMenu";
import AddSubMenu from "./Masters/AddSubMenu";
import CareerPost from "./Carrer/CareerPost";
import AddCareerPost from "./Carrer/AddCareerPost";
import CareerList from "./Reports/CareerList";
import CareerListDetails from "./Reports/CareerListDetails";

function RequireAuth({ children }) {
  let accessToken = localStorage.getItem("accessToken");
  let token = localStorage.getItem("token");
  if (!accessToken || !token) {
    return <Navigate to="/" />;
  }
  return children;
}

function App() {
  return (
    <div>
      <BrowserRouter>
        <Sidebar />
        <Routes>
          <Route path="/" element={<Login />} />
          <Route
            path="/dashboard"
            element={
              <RequireAuth>
                <Dashboard />
              </RequireAuth>
            }
          />
          <Route
            path="/news-event"
            element={
              <RequireAuth>
                <NewsEvent />
              </RequireAuth>
            }
          />
          <Route
            path="/add-news-event"
            element={
              <RequireAuth>
                <Addnewsevent />
              </RequireAuth>
            }
          />
          <Route
            path="/menu"
            element={
              <RequireAuth>
                <Menu />
              </RequireAuth>
            }
          />
          <Route
            path="/menu/:id"
            element={
              <RequireAuth>
                <SubMenu />
              </RequireAuth>
            }
          />
          <Route
            path="/add-menu"
            element={
              <RequireAuth>
                <AddMenu />
              </RequireAuth>
            }
          />
          <Route
            path="/add-sub-menu/:parentId"
            element={
              <RequireAuth>
                <AddSubMenu />
              </RequireAuth>
            }
          />
          <Route
            path="/testimonials"
            element={
              <RequireAuth>
                <Testimonial />
              </RequireAuth>
            }
          />
          <Route
            path="/add-testimonial"
            element={
              <RequireAuth>
                <TestiminialForm />
              </RequireAuth>
            }
          />
          <Route
            path="/email-template"
            element={
              <RequireAuth>
                <EmailTemplate />
              </RequireAuth>
            }
          />
          <Route
            path="/category"
            element={
              <RequireAuth>
                <Category />
              </RequireAuth>
            }
          />
          <Route
            path="/:id/sub-category"
            element={
              <RequireAuth>
                <SubCategory />
              </RequireAuth>
            }
          />
          <Route
            path="/:id/add-sub-category"
            element={
              <RequireAuth>
                <AddSubcategory />
              </RequireAuth>
            }
          />
          <Route
            path="/search-products"
            element={
              <RequireAuth>
                <Searchlist />
              </RequireAuth>
            }
          />
          <Route
            path="/:id/products"
            element={
              <RequireAuth>
                <ProductList />
              </RequireAuth>
            }
          />
          <Route
            path="/add-category"
            element={
              <RequireAuth>
                <AddnewCategory />
              </RequireAuth>
            }
          />
          <Route
            path="/:id/add-product"
            element={
              <RequireAuth>
                <ProductForm />
              </RequireAuth>
            }
          />
          <Route
            path="/import-export-products"
            element={
              <RequireAuth>
                <ImportExportProduct />
              </RequireAuth>
            }
          />
          <Route
            path="/home-slider"
            element={
              <RequireAuth>
                <HomeSlider />
              </RequireAuth>
            }
          />
          <Route
            path="/add-slider"
            element={
              <RequireAuth>
                <AddBannerSlider />
              </RequireAuth>
            }
          />
          <Route
            path="/:id/brand-product"
            element={
              <RequireAuth>
                <Brandproductlist />
              </RequireAuth>
            }
          />
          <Route
            path="/box-images"
            element={
              <RequireAuth>
                <MiddleImage />
              </RequireAuth>
            }
          />
          <Route
            path="/add-boximg"
            element={
              <RequireAuth>
                <MiddleImageForm />
              </RequireAuth>
            }
          />
          <Route
            path="/videos"
            element={
              <RequireAuth>
                <HomeVideo />
              </RequireAuth>
            }
          />
          <Route
            path="/add-video"
            element={
              <RequireAuth>
                <HomeVideoForm />
              </RequireAuth>
            }
          />
          <Route
            path="/inquiry"
            element={
              <RequireAuth>
                <Inquirylist />
              </RequireAuth>
            }
          />
          <Route
            path="/collections"
            element={
              <RequireAuth>
                <Managebrands />
              </RequireAuth>
            }
          />
          <Route
            path="/add-collection"
            element={
              <RequireAuth>
                <Addnewbrand />
              </RequireAuth>
            }
          />
          <Route
            path="/change-password"
            element={
              <RequireAuth>
                <ChangePassword />
              </RequireAuth>
            }
          />
          <Route path="/forgot-password" element={<ForgotPassword />} />
          <Route
            path="/social-media"
            element={
              <RequireAuth>
                <Socialmedia />
              </RequireAuth>
            }
          />
          <Route
            path="/add-social-media"
            element={
              <RequireAuth>
                <AddSocialMedia />
              </RequireAuth>
            }
          />
          <Route
            path="/backoffice"
            element={
              <RequireAuth>
                <Backofficesetting />
              </RequireAuth>
            }
          />
          <Route
            path="/store-locator"
            element={
              <RequireAuth>
                <Branch />
              </RequireAuth>
            }
          />
          <Route
            path="/add-store-locator"
            element={
              <RequireAuth>
                <Addbranch />
              </RequireAuth>
            }
          />

          <Route
            path="/logo"
            element={
              <RequireAuth>
                <Addweblogo />
              </RequireAuth>
            }
          />
          <Route
            path="/inquiry-details"
            element={
              <RequireAuth>
                <Inquirylistdetails />
              </RequireAuth>
            }
          />
          <Route
            path="/product-inquiry"
            element={
              <RequireAuth>
                <Productinquarylist />
              </RequireAuth>
            }
          />
          <Route
            path="/product-inquiry-detail"
            element={
              <RequireAuth>
                <Productinquarylistdetails />
              </RequireAuth>
            }
          />
          <Route
            path="/appointment-booking"
            element={
              <RequireAuth>
                <BookAppointment />
              </RequireAuth>
            }
          />
          <Route
            path="/appointment-booking-detail"
            element={
              <RequireAuth>
                <BookappointDetails />
              </RequireAuth>
            }
          />
          <Route
            path="/back-office"
            element={
              <RequireAuth>
                <Backofficesetting />
              </RequireAuth>
            }
          />
          <Route
            path="/instagram"
            element={
              <RequireAuth>
                <Instagram />
              </RequireAuth>
            }
          />
          <Route
            path="/page"
            element={
              <RequireAuth>
                <Page />
              </RequireAuth>
            }
          />
          <Route
            path="/add-page"
            element={
              <RequireAuth>
                <AddPage />
              </RequireAuth>
            }
          />
          <Route
            path="/content"
            element={
              <RequireAuth>
                <Content />
              </RequireAuth>
            }
          />
          <Route
            path="/add-content"
            element={
              <RequireAuth>
                <AddContent />
              </RequireAuth>
            }
          />
          <Route
            path="/google-review"
            element={
              <RequireAuth>
                <GoogleReview />
              </RequireAuth>
            }
          />
          <Route
            path="/analytics-seo"
            element={
              <RequireAuth>
                <SecoSetting />
              </RequireAuth>
            }
          />
          <Route
            path="/sitemap"
            element={
              <RequireAuth>
                <Sitemap />
              </RequireAuth>
            }
          />
          <Route
            path="/email-settings"
            element={
              <RequireAuth>
                <Emailsender />
              </RequireAuth>
            }
          />
          <Route
            path="/colors"
            element={
              <RequireAuth>
                <Colors />
              </RequireAuth>
            }
          />
          <Route
            path="/add-color"
            element={
              <RequireAuth>
                <AddColor />
              </RequireAuth>
            }
          />
          <Route
            path="/metals"
            element={
              <RequireAuth>
                <Metal />
              </RequireAuth>
            }
          />
          <Route
            path="/add-metal"
            element={
              <RequireAuth>
                <AddMetal />
              </RequireAuth>
            }
          />
          <Route
            path="/gold-purity"
            element={
              <RequireAuth>
                <GoldPurity />
              </RequireAuth>
            }
          />
          <Route
            path="/add-gold-purity"
            element={
              <RequireAuth>
                <AddGoldPurity />
              </RequireAuth>
            }
          />
          <Route
            path="/home-page"
            element={
              <RequireAuth>
                <Setting />
              </RequireAuth>
            }
          />
          <Route
            path="/career-post"
            element={
              <RequireAuth>
                <CareerPost />
              </RequireAuth>
            }
          />
          <Route
            path="/job-applications"
            element={
              <RequireAuth>
                <CareerList />
              </RequireAuth>
            }
          />
          <Route
            path="/job-application-details"
            element={
              <RequireAuth>
                <CareerListDetails />
              </RequireAuth>
            }
          />
          <Route
            path="/add-career-post"
            element={
              <RequireAuth>
                <AddCareerPost />
              </RequireAuth>
            }
          />
        </Routes>
        <ToastContainer autoClose={1000} theme="light" />
      </BrowserRouter>
    </div>
  );
}

export default App;

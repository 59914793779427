import DataTable from "datatables.net-dt";
import React, { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import { toast } from "react-toastify";
import Loader from "../Layout/Loader";
import { ImageLink } from "../constants";
import { brandProductApi } from "../Redux/brandProductSlice";
import DeleteConfirmationModal from "../component/DeleteConfirmationModal";

const Brandproductlist = () => {
  const url = process.env.REACT_APP_API_BASE_URL;
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const brandProduct = useSelector(
    (state) => state.brandProduct.user?.document
  );
  const loading = useSelector((state) => state.brandProduct.loading);
  const pathname = location.pathname;
  const pathname1 = pathname.split("/")[1];
  const [deleteItemId, setDeleteItemId] = useState(null);
  const [modalOpen, setModalOpen] = useState(false);

  useEffect(() => {
    if (brandProduct && brandProduct?.length > 0) {
      const table = new DataTable("#myTable", {
        pageLength: 10,
        lengthChange: false,
        autoWidth: true,
        paging: true,
        info: true,
        searching: false,
        columnDefs: [
          { targets: 2, orderable: false },
          { targets: 3, orderable: false },
        ],
      });
      return () => {
        table.destroy();
      };
    }
  }, [brandProduct]);

  const id = location.state?.item._id;
  useEffect(() => {
    dispatch(brandProductApi(pathname1));
  }, [dispatch, pathname1]);

  const handleCheckboxChange = async (_id, newIsActive) => {
    try {
      let accessToken = localStorage.getItem("accessToken");

      let headersList = {
        Accept: "application/json",
        Authorization: `Bearer ${accessToken}`,
      };

      let reqOptions = {
        url: `${url}/api/admin/productstatus`,
        method: "POST",
        headers: headersList,
        data: {
          id: _id,
          isActive: newIsActive,
        },
      };

      let response = await axios.request(reqOptions);
      toast.success(response.data?.message);
      dispatch(brandProductApi(id));
    } catch (error) {
      toast.error(error.response?.data?.message || "An error occurred");
    }
  };

  const handleDelete = async (id) => {
    setDeleteItemId(id);
    setModalOpen(true);
};

const confirmDelete = async () => {
  try {
    let accessToken = localStorage.getItem('accessToken');
      let headersList = {
        "Accept": "*/*",
        "Authorization": `Bearer ${accessToken}`
      }
      const response = await axios.delete(`${url}/api/admin/product/${deleteItemId}`, {
              headers: headersList,
            });
      if (response.status === 200) {
          toast.success(response.data.message);
          dispatch(brandProductApi());
      } else {
          toast.error(response.data.message);
      }
      setModalOpen(false);
  } catch (error) {
      toast.error('Something went wrong. Please try again!!');
  } finally {
      setDeleteItemId(null);
      setModalOpen(false);
  }
};

  const toComponentB = (item) => {
    navigate(`/${pathname1}/add-product`, { state: { item, _id: item._id } });
  };
  return (
    <>
      <div className="main-container">
        {loading === true ? (
          <Loader />
        ) : (
          <div className="xs-pd-20-10 ">
            <div className="d-flex justify-content-between">
              <div className="title mb-3">
                <h2 className="h3 mb-0 mt-3">Brand Products</h2>
              </div>
              <Link to={`/${pathname1}/add-product`}>
                <button className="text-white h4 btn-sm btn-primary mt-2">
                  <i className="icon-copy fi-plus mx-2" />
                  Add Product
                </button>
              </Link>
            </div>
            <div className="card-box mb-30">
              <div className="pb-20 pd-20 table-responsive">
                <table
                  className="table-striped hover nowrap table-bordered w-100"
                  id="myTable"
                >
                  <thead>
                    <tr>
                      <th className="p-2 text-center">Name</th>
                      <th className="p-2 text-center">Product</th>
                      <th className="p-2 text-center">Is Active</th>
                      <th className="p-2 text-center">Action</th>
                    </tr>
                  </thead>
                  <tbody className="text-center">
                    {Array.isArray(brandProduct) && brandProduct.length > 0 ? (
                      brandProduct.map((data, id) => {
                        return (
                          <tr key={id}>
                            <td>{data.name}</td>
                            <td>
                              <img
                                src={ImageLink(data.product_image[0])}
                                alt=""
                                width="50"
                                height="50"
                              />
                            </td>
                            <td>
                              <div className="custom-control custom-switch">
                                <input
                                  type="checkbox"
                                  className="custom-control-input"
                                  id={`customSwitch_${id}`}
                                  checked={data.isActive}
                                  onChange={() =>
                                    handleCheckboxChange(
                                      data._id,
                                      !data.isActive
                                    )
                                  }
                                />
                                <label
                                  className="custom-control-label"
                                  htmlFor={`customSwitch_${id}`}
                                ></label>
                              </div>
                            </td>
                            <td>
                              <button
                                type="Submit"
                                className="btn-sm btn-outline-info mx-2"
                                onClick={(e) => {
                                  e.preventDefault();
                                  toComponentB(data, data._id);
                                }}
                              >
                                <i className="dw dw-edit2 mx-2 "></i>Edit
                              </button>
                              <button
                                type="Submit"
                                className="btn-sm btn-outline-danger mx-2"
                                onClick={() => handleDelete(data._id)}
                              >
                                <i className="dw dw-delete-3 mx-e"></i> Delete
                              </button>
                            </td>
                          </tr>
                        );
                      })
                    ) : (
                      <tr>
                        <td colSpan="4" className="text-center py-2">
                          No data found
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        )}
      </div>
      <DeleteConfirmationModal
        isOpen={modalOpen}
        toggle={() => setModalOpen(!modalOpen)}
        onConfirm={confirmDelete}
        itemName="product"
      />
    </>
  );
};

export default Brandproductlist;

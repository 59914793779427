import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { createSlice } from "@reduxjs/toolkit";
import { handleTokenErrors } from "./handleTokenErrors";

const baseurl = process.env.REACT_APP_API_BASE_URL;

const initialState = {
    data: [],
    isLoading: false,
    isSuccess: false,
    errorMessage: "",
};

export const getBrandApi = createAsyncThunk(
    "getBrands",
    async (page, { rejectWithValue }) => {
        const token = localStorage.getItem("accessToken");
        try {
            const response = await axios.get(
                `${baseurl}/api/brands`,  {
                    headers: {
                      Authorization: `Bearer ${token}`,
                      "Accept": "*/*",
                    },
                  }
            );
            return response.data;
        } catch (error) {
            handleTokenErrors(error);
            return rejectWithValue(error.message);
        }
    }
);

export const getBrandSlice = createSlice({
    name: "getBrand",
    initialState,
    extraReducers: {
        [getBrandApi.pending]: (state) => {
            state.isLoading = true;
        },
        [getBrandApi.fulfilled]: (state, { payload }) => {
            state.isLoading = false;
            state.isSuccess = true;
            state.data = payload;
        },
        [getBrandApi.rejected]: (state, { payload }) => {
            state.isLoading = false;
            state.isSuccess = false;
            state.errorMessage = payload;
        },
    },
});

export default getBrandSlice.reducer;

import { combineReducers } from "@reduxjs/toolkit";
import authReducer from "../Redux/loginSlice";
import changepasswordReducer from "../Redux/changePasswordSlice";
import getHomeSliderReducer from "../Redux/getHomeSliderSlice";
import getBoxImageReducer from "../Redux/getBoxImageSlice";
import getHomeVideoReducer from "../Redux/getHomeVideoSlice";
import getBranchReducer from "../Redux/getBranchSlice";
import getCategoryReducer from "../Redux/getCategorySlice";
import getAboutReducer from "../Redux/getAboutSlice.js";
import logoutReducer from "../Redux/logoutSlice";
import getCategoriesReducer from "../Redux/getCategories";
import webLogoImageReducer from "../Redux/webLogoImageSlice";
import productReducer from "../Redux/productSlice";
import getBrandReducer from "../Redux/getBrandSlice";
import getInquirylistReducer from "../Redux/getInquirylistSlice";
import getSearchlistReducer from "../Redux/getSearchlistSlice";
import getProductInquarylistReducer from "../Redux/getProductInquarylistSlice";
import brandProductReducer from "../Redux/brandProductSlice";
import newsReducer from "../Redux/newsSlice.js";
import subCategoriesReducer from "../Redux/subCategoriesSlice";
import appointmentReducer from "../Redux/appointmentSlice";
import goldrateReducer from "../Redux/goldrateSlice";
import emailTemplateReducer from "../Redux/emailTemplateSlice";
import contactReducer from "../Redux/contactSlice";
import dashboardReducer from "../Redux/dashboardSlice";
import subcateProductReducer from "../Redux/subcateProductSlice";
import refreshTokenReducer from "../Redux/refreshTokenSlice";
import allProductReducer from "../Redux/getAllProductSlice";
import pageReducer from "../Redux/pageSlice";
import contentReducer from "../Redux/contentSlice";
import menuReducer from "../Redux/menuSlice";
import getCategoryProductReducer from "../Redux/getCategoryProductSlice";

const reducer = combineReducers({
  auth: authReducer,
  changepassword: changepasswordReducer,
  homeSlider: getHomeSliderReducer,
  getboximage: getBoxImageReducer,
  homevideo: getHomeVideoReducer,
  category: getCategoryReducer,
  branchmaster: getBranchReducer,
  getproduct: productReducer,
  Getabout: getAboutReducer,
  getCategories: getCategoriesReducer,
  weblogoimage: webLogoImageReducer,
  inquirylist: getInquirylistReducer,
  Getsearchlist: getSearchlistReducer,
  logout: logoutReducer,
  getBrand: getBrandReducer,
  Getproductinquary: getProductInquarylistReducer,
  brandProduct: brandProductReducer,
  newsevent: newsReducer,
  getsubcategory: subCategoriesReducer,
  appointment: appointmentReducer,
  goldrate: goldrateReducer,
  emailtemplate: emailTemplateReducer,
  contact: contactReducer,
  dashboard: dashboardReducer,
  subcatProduct: subcateProductReducer,
  refreshToken: refreshTokenReducer,
  allProductL: allProductReducer,
  page: pageReducer,
  content: contentReducer,
  menu:menuReducer,
  categoryProduct: getCategoryProductReducer
});

export default reducer;

import DataTable from "datatables.net-dt";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import { toast } from "react-toastify";
import { branchmaster } from "../Redux/getBranchSlice";
import Loader from "../Layout/Loader";
import { ImageLink } from "../constants";
import DeleteConfirmationModal from "../component/DeleteConfirmationModal";

const Branch = () => {
  const baseurl = process.env.REACT_APP_API_BASE_URL;
  const branchmasters = useSelector((state) => state.branchmaster.data?.document || []);
  const Loading = useSelector((state) => state.branchmaster.isLoading);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [deleteItemId, setDeleteItemId] = useState(null);
  const [modalOpen, setModalOpen] = useState(false);

  useEffect(() => {
    if (branchmasters && branchmasters.length > 0) {
      const table = new DataTable("#myTable", {
        pageLength: branchmasters.length,
        lengthChange: false,
        autoWidth: true,
        paging: false,
        info: false,
        searching: false,
        columnDefs: [
          { targets: 2, orderable: false },
          { targets: 5, orderable: false },
          { targets: 6, orderable: false }
      ]
      });
      return () => {
        table.destroy();
      };
    }
  }, [branchmasters]);

  useEffect(() => {
    dispatch(branchmaster());
  }, [dispatch]);

  const handleDelete = async (id) => {
    setDeleteItemId(id);
    setModalOpen(true);
};

const confirmDelete = async () => {
  try {
    let accessToken = localStorage.getItem('accessToken');
      let headersList = {
        "Accept": "*/*",
        "Authorization": `Bearer ${accessToken}`
      }
      const response = await axios.delete(`${baseurl}/api/admin/branch/${deleteItemId}`, {
              headers: headersList,
            });
      if (response.status === 200) {
          toast.success(response.data.message);
          dispatch(branchmaster());
      } else {
          toast.error(response.data.message);
      }
      setModalOpen(false);
  } catch (error) {
      toast.error('Something went wrong. Please try again!!');
  } finally {
      setDeleteItemId(null);
      setModalOpen(false);
  }
};

  const handleCheckboxChange = async (_id, newIsActive) => {
    try {
      let accessToken = localStorage.getItem('accessToken');

      let headersList = {
        "Accept": "application/json",
        "Authorization": `Bearer ${accessToken}`
      }

      let reqOptions = {
        url: `${baseurl}/api/admin/branchstatus`,
        method: "POST",
        headers: headersList,
        data: {
          id: _id,
          isActive: newIsActive,
        }
      }

      let response = await axios.request(reqOptions);

      toast.success(response.data?.message);
      dispatch(branchmaster());
    } catch (error) {
      toast.error(error.response?.data?.message || "An error occurred");
    }
  };

  const toComponentB = (data, event) => {
    event.preventDefault();
    navigate("/add-store-locator", { state: { data } });
  };

  return (
    <>
      <div className="main-container">
        <div className="xs-pd-20-10 ">
          <div className='d-flex justify-content-between'>
            <div className="title mb-3">
              <h2 className="h3 mb-0 mt-3">Store Locator</h2>
            </div>
            <Link to="/add-store-locator">
              <button className="text-white h5 btn-sm btn-primary mt-2">
                <i className="icon-copy fi-plus mx-2" />
                Add Store Locator
              </button>
            </Link>
          </div>
          <div className="card-box mb-30">
            <div className="pb-20 pd-20 table-responsive"></div>
            {Loading ? (
              <Loader />
            ) : (
              <div className="pb-20 pd-20 table-responsive">
                <table className="table-striped hover nowrap table-bordered w-100" id="myTable">
                  <thead>
                    <tr>
                      <th className="p-2 text-center">Position</th>
                      <th className="p-2 text-center">Name</th>
                      <th className="p-2 text-center">Image</th>
                      <th className="p-2 text-center">City</th>
                      <th className="p-2 text-center">Contact No</th>
                      <th className="p-2 text-center">Is Active</th>
                      <th className="p-2 text-center">Action</th>
                    </tr>
                  </thead>
                  <tbody className="text-center">
                    {branchmasters && branchmasters.length > 0 ? (
                      branchmasters.map((data, index) => (
                        <tr key={data.id}>
                          <td>{data.sortOrder}</td>
                          <td>{data.name}</td>
                          <td>
                            <img
                              src={ImageLink(data.branch_image)}
                              alt=""
                              width="50"
                              height="50"
                            />
                          </td>
                          <td>{data.city}</td>
                          <td>{data.mobile_no}</td>
                          <td>
                            <div className="custom-control custom-switch">
                              <input
                                type="checkbox"
                                className="custom-control-input"
                                id={`customSwitch_${index}`}
                                checked={data.isActive}
                                onChange={() =>
                                  handleCheckboxChange(data._id, !data.isActive)
                                }
                              />
                              <label
                                className="custom-control-label"
                                htmlFor={`customSwitch_${index}`}></label>
                            </div>
                          </td>
                          <td>
                            <button type="Submit" className="btn-sm btn-outline-info mx-2"
                              onClick={(event) => { toComponentB(data, event) }}
                            >
                              <i className="dw dw-edit2 mx-2 "></i>Edit
                            </button>
                            <button type="Submit" className="btn-sm btn-outline-danger mx-2" onClick={() => handleDelete(data._id)}>
                              <i className="dw dw-delete-3 mx-e"></i> Delete
                            </button>
                          </td>
                        </tr>
                      ))
                    ) : (
                      <tr>
                        <td colSpan="8" className="text-center py-2">No data found</td>
                      </tr>
                    )}
                  </tbody>
                </table>

              </div>
            )}
          </div>
        </div>
      </div>
      <DeleteConfirmationModal
        isOpen={modalOpen}
        toggle={() => setModalOpen(!modalOpen)}
        onConfirm={confirmDelete}
        itemName="branch"
      />
    </>
  );
};

export default Branch;

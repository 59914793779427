import axios from "axios";
import { createSlice } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import { Navigate } from "react-router-dom";
const baseurl = process.env.REACT_APP_API_BASE_URL;

const refreshTokenSlice = createSlice({
  name: "refreshToken",
  initialState: {
    isAuthenticated: false,
    user: null,
    error: null,
    loading: false,
    status: true,
  },
  reducers: {
    refreshTokenPending: (state) => {
      state.loading = true;
    },
    refreshTokenSuccess: (state, { payload }) => {
      state.loading = false;
      state.isAuthenticated = true;
      state.user = payload;
      state.status = 1;
      state.error = null;
    },
    refreshTokenFailure: (state, { payload }) => {
      state.loading = false;
      state.isAuthenticated = false;
      state.user = null;
      state.status = 0;
      state.error = payload;
    },
  },
});

export const { refreshTokenPending, refreshTokenSuccess, refreshTokenFailure } =
  refreshTokenSlice.actions;


export const refreshTokenApi = () => async (dispatch) => {
  dispatch(refreshTokenPending());
  try {
    const refreshToken = localStorage.getItem("token");
    const response = await axios.post(`${baseurl}/api/admin/refreshtoken`, {
      refreshToken: refreshToken,
    });

    const data = response.data;

    if (data.status === 1) {
      toast.success(data.message);
      localStorage.setItem("accessToken", data.accessToken);
      localStorage.setItem("token", data.refreshToken);

      dispatch(refreshTokenSuccess(data));
    } else {
      dispatch(refreshTokenFailure(data));
      toast.error(data.message);
    }
  } catch (error) {
    if (error.response.data.error === "error Invalid refresh token!") {
      Navigate("/")
    }
  } finally {
    dispatch(refreshTokenPending());
  }
};


export default refreshTokenSlice.reducer;

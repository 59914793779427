import axios from "axios";
import React from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

const ForgotPassword = () => {
  const url = process.env.REACT_APP_API_BASE_URL;
  const navigate = useNavigate();
  const { register, handleSubmit, reset, watch, formState: { errors }, } = useForm({});
  const user = watch("username");

  const onSubmit = async (data) => {
    try {
      const response = await axios.post(
        `${url}/api/admin/forgotpassword`,
        data
      );
      if (response.data.status === 1) {
        toast.success(response.data.message);
        reset();
        navigate("/");
      } else {
        toast.error(data.error);
      }
    } catch (error) {
      toast.error(error.response.data.error);
    }
  };
  
  return (
    <>
      <div className="main-container login ">
        <div className="xs-pd-20-10 pd-ltr-20 col-md-12">
          <div className="row d-block h-100">
            <div className="col-md-8 col-sm-12 xs-pd-20-10">
              <div className="pd-20 card-box height-100-p">
                <div className="login-title">
                  <div className="text-center text-primary">
                    <img src="/logo.png" alt="Logo" />
                    <br />
                    <h4 className="my-3">Forgot Password</h4>{" "}
                  </div>
                  <h6 className="mb-20 text-center">
                    Enter your username to reset your password
                  </h6>
                </div>

                <form onSubmit={handleSubmit(onSubmit)}>
                  <div className="input-group custom d-flex flex-column">
                    <input
                      type="text"
                      className={`form-control ${errors.username ? "is-invalid" : ""
                        }`}
                      name="username"
                      {...register("username", {
                        required: "Please enter a username",
                      })}
                      placeholder="Username"
                    />
                    {errors.username && (
                      <small className="text-danger">
                        Please enter username
                      </small>
                    )}
                  </div>

                  <div className="row">
                    <div className="col-sm-12">
                      <div className="input-group mb-0">
                        <button
                          className="btn-primary btn-sm btn-block"
                          type="submit"
                          disabled={!user}
                        >
                          Send
                        </button>
                      </div>
                    </div>
                  </div>

                </form>
              </div>
            </div>
          </div>
        </div>
      </div>    
    </>
  );
};

export default ForgotPassword;

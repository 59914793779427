

import axios from "axios";
import { toast } from "react-toastify";
import { handleTokenErrors } from "./handleTokenErrors";
const { createSlice } = require("@reduxjs/toolkit");

const baseurl = process.env.REACT_APP_API_BASE_URL;
const contactSlice = createSlice({
    name: "contact",
    initialState: {
        isAuthenticated: false,
        user: null,
        error: null,
        loading: false,
        token: null,
        accessToken: null,
        isSuccess: false,
    },
    reducers: {
        contactPending: (state) => {
            state.loading = true;
        },
        contactSuccess: (state, { payload }) => {
            state.loading = false;
            state.isAuthenticated = true;
            state.isSuccess = true;
            state.user = payload;
            state.token = payload.token;
            state.accessToken = payload.accessToken;
            state.error = null;
        },
        contactFailure: (state, { payload }) => {
            state.loading = false;
            state.isAuthenticated = false;
            state.user = null;
            state.error = payload;
        },

    },
});

export const { contactPending, contactSuccess, contactFailure } =
    contactSlice.actions;

export const contactApi = (formData) => async (dispatch) => {
    dispatch(contactPending());
    const token = localStorage.getItem("accessToken");
    try {
        const response = await axios.post(
            `${baseurl}/api/admin/contact`,
            formData, {
            headers: {
                Authorization: `Bearer ${token}`,
                "Accept": "*/*",
            },
        }
        );
        const data = response.data;

        if (data.status === 1) {
            dispatch(contactSuccess(data));
            toast.success(data.message);
        } else {
            toast.error(data.error);
            dispatch(contactFailure(data));
        }
    } catch (error) {
        dispatch(contactFailure(error.response.data));
        handleTokenErrors(error);
    }
};

export default contactSlice.reducer;

import React, { useCallback, useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import axios from "axios";
import { ImageLink } from "../constants";

const Addnewbrand = () => {
  const baseurl = process.env.REACT_APP_API_BASE_URL;

  const [previewImage, setPreviewImage] = useState("");
  const [previewImage2, setPreviewImage2] = useState("");
  const [previewImage3, setPreviewImage3] = useState("");
  const [previewImage4, setPreviewImage4] = useState("");

  const handleFileChange = (event, setImagePreview) => {
    const file = event.target.files[0];
    const reader = new FileReader();

    reader.onloadend = () => {
      setImagePreview(reader.result);
    };

    if (file) {
      reader.readAsDataURL(file);
    }
  };

  const location = useLocation();
  const id = location?.state?._id;
  const isEditing = !!id;
  const [loading, setLoading] = useState(false);
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm({});
  const navigate = useNavigate();
  const [data, setData] = useState([]);

  useEffect(() => {
    reset(location?.state?.item);
    setPreviewImage(ImageLink(location?.state?.item?.collection_image));
    setPreviewImage2(ImageLink(location?.state?.item?.logo_image));
    setPreviewImage3(ImageLink(location?.state?.item?.main_banner_image));
    setPreviewImage4(ImageLink(location?.state?.item?.background_image));
  }, [location?.state?.item, reset]);

  const onSubmit = async (data) => {
    setLoading(true);

    const formData = new FormData();
    formData.append("name", data.name);

    if (data.banner_image instanceof File) {
      formData.append("collection_image", data.collection_image);
    } else {
      const blob = await fetch(previewImage).then((res) => res.blob());
      formData.append("collection_image", blob, "previewImage.jpg");
    }

    if (data.logo_image instanceof File) {
      formData.append("logo_image", data.logo_image);
    } else {
      const blob = await fetch(previewImage2).then((res) => res.blob());
      formData.append("logo_image", blob, "previewImage2.jpg");
    }

    if (data.main_banner_image instanceof File) {
      formData.append("main_banner_image", data.main_banner_image);
    } else {
      const blob = await fetch(previewImage3).then((res) => res.blob());
      formData.append("main_banner_image", blob, "previewImage3.jpg");
    }

    if (data.background_image instanceof File) {
      formData.append("background_image", data.background_image);
    } else {
      const blob = await fetch(previewImage4).then((res) => res.blob());
      formData.append("background_image", blob, "previewImage4.jpg");
    }

    formData.append("sortOrder", data.sortOrder);
    formData.append("slugname", data.slugname);
    formData.append("subTitle", data.subTitle);
    formData.append("isActive", data.isActive);
    formData.append("showInHome", data.showInHome);
    formData.append("description", data.description);
    formData.append("metaTitle", data.metaTitle);
    formData.append("metaKeyword", data.metaKeyword);
    formData.append("metaDescription", data.metaDescription);
    if (isEditing) {
      formData.append("id", id);
    }
    try {
      let accessToken = localStorage.getItem("accessToken");
      let headersList = {
        Accept: "*/*",
        Authorization: `Bearer ${accessToken}`,
      };
      const response = await fetch(`${baseurl}/api/admin/brand`, {
        method: "POST",
        headers: headersList,
        body: formData,
      });
      const responseData = await response.json();
      if (responseData.status === 1) {
        toast.success(responseData.message);
        reset();
        navigate("/collections");
      } else {
        toast.error(responseData.originalError);
      }
    } catch (error) {
      toast.error("Error submitting form. Please try again.");
    } finally {
      setLoading(false);
    }
  };

  const fetchData = useCallback(async () => {
    try {
      setLoading(true);
      let accessToken = localStorage.getItem("accessToken");
      let headersList = {
        Accept: "*/*",
        Authorization: `Bearer ${accessToken}`,
      };
      const response = await axios.get(`${baseurl}/api/brandall`, {
        headers: headersList,
      });
      setData(response.data.document);
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  }, [baseurl]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  return (
    <>
      <div className="main-container">
        <div className="xs-pd-20-10 pd-ltr-20 col-md-12">
          <div className="title pb-20">
            <h2 className="h3 mb-0">Add Collection</h2>
          </div>

          <div className="row">
            <div className="col-md-12 col-sm-12 mb-30">
              <div className="pd-20 card-box height-100-p">
                <div className="pd-20  ">
                  <form onSubmit={handleSubmit(onSubmit)}>
                    <div className="form-group row">
                      <label className="col-sm-12 col-md-2 mb-4 col-form-label">
                        Brand Name<span className="text-danger">*</span>
                      </label>
                      <div className="col-md-10 mb-4">
                        <input
                          className={`form-control ${errors.name ? "is-invalid" : ""
                            }`}
                          placeholder="Enter name"
                          {...register("name", { required: true })}
                          type="text"
                        />
                        {errors.name && (
                          <small className="text-danger">
                            {" "}
                            Name is required
                          </small>
                        )}
                      </div>

                      <label className="col-sm-12 col-md-2 mb-4 col-form-label">
                        Sort Order <span className="text-danger">*</span>
                      </label>
                      <div className="col-md-10 mb-4">
                        <input
                          className={`form-control ${errors.sortOrder ? "is-invalid" : ""
                            }`}
                          placeholder="Enter sort order"
                          {...register("sortOrder", { required: true })}
                          type="text"
                          defaultValue={
                            location.state?.item?.sortOrder
                              ? location.state?.item?.sortOrder
                              : data.length + 1
                          }
                        />
                        {errors.sortOrder && (
                          <small className="text-danger">
                            Sort order is required
                          </small>
                        )}
                      </div>
                      <label className="col-sm-12 col-md-2 col-form-label mb-4">
                        URL/Slug<span className="text-danger">*</span>
                      </label>
                      <div className="col-md-10 mb-4">
                        <input
                          className={`form-control ${errors.slugname ? "is-invalid" : ""
                            }`}
                          type="text"
                          placeholder="Enter url"
                          {...register("slugname", { required: true })}
                          defaultValue={location.state?.item.slug}
                        />
                        {errors.slugname && (
                          <small className="text-danger">Url is required</small>
                        )}
                      </div>
                      <label className="col-sm-12 col-md-2 mb-4 col-form-label">
                        Collection Image
                      </label>
                      <div className="col-md-10 mb-4">
                        <input
                          className="form-control"
                          type="file"
                          {...register("collection_image")}
                          onChange={(event) =>
                            handleFileChange(event, setPreviewImage)
                          }
                        />
                        {previewImage && (
                          <div className="mb-3 mt-3">
                            <img
                              src={previewImage}
                              alt="Preview"
                              className="preview-image"
                              style={{ maxWidth: "25%" }}
                            />
                            <button type="button" className="close-btn" onClick={() => setPreviewImage('')}>
                              X
                            </button>
                          </div>
                        )}
                        <small className="form-text text-muted">
                          Required image size: 760 X 383 (.png, .jpg, .jpeg,.webp)
                        </small>
                      </div>

                      <label className="col-sm-12 col-md-2 mb-4 col-form-label">
                        Brand Logo
                      </label>
                      <div className="col-md-10 mb-4">
                        <input
                          className="form-control"
                          type="file"
                          {...register("logo_image")}
                          onChange={(event) =>
                            handleFileChange(event, setPreviewImage2)
                          }
                        />
                        {previewImage2 && (
                          <div className="mb-3 mt-3">
                            <img
                              src={previewImage2}
                              alt="Preview"
                              className="preview-image"
                              style={{ maxWidth: "100%" }}
                            />
                            <button type="button" className="close-btn" onClick={() => setPreviewImage2('')}>
                              X
                            </button>
                          </div>
                        )}
                        <small className="form-text text-muted">
                          Required image size: 289 X 138px (.png, .jpg, .jpeg, .webp)
                        </small>
                      </div>

                      <label className="col-sm-12 col-md-2 col-form-label">
                        Banner Image
                      </label>
                      <div className="col-md-9 mb-4">
                        <input
                          className="form-control"
                          {...register("main_banner_image")}
                          type="file"
                          onChange={(event) =>
                            handleFileChange(event, setPreviewImage3)
                          }
                        />
                        {previewImage3 && (
                          <div className="mb-3 mt-3">
                            <img
                              src={previewImage3}
                              alt="Preview"
                              className="preview-image w-50"
                            />
                            <button type="button" className="close-btn" onClick={() => setPreviewImage3('')}>
                              X
                            </button>
                          </div>
                        )}
                        <small className="form-text text-muted">
                          Required image size: 1444 X 373px (.png, .jpg, .jpeg, .webp)
                        </small>
                      </div>

                      <label className="col-sm-12 col-md-2 col-form-label">
                        Background Image
                      </label>
                      <div className="col-md-9 mb-4">
                        <input
                          className="form-control"
                          {...register("background_image")}
                          type="file"
                          onChange={(event) => handleFileChange(event, setPreviewImage4)}
                        />
                        {previewImage4 && (
                          <div className="mb-3 mt-3">
                            <img
                              src={previewImage4}
                              alt="Preview"
                              className="preview-image w-50"
                            />
                            <button type="button" className="close-btn" onClick={() => setPreviewImage4('')}>
                              X
                            </button>
                          </div>
                        )}
                        <small className="form-text text-muted">
                          Required image size: 1920 X 516 (.png, .jpg, .jpeg, .webp)
                        </small>
                      </div>

                      <label className="col-sm-12 col-md-2 mb-4 col-form-label">
                        Sub Title
                      </label>
                      <div className="col-md-10 mb-4">
                        <input
                          className="form-control"
                          placeholder="Enter sub title"
                          type="text"
                          {...register("subTitle")}
                        />
                      </div>
                      <label className="col-sm-12 col-md-2 mb-4 col-form-label">
                        Description
                      </label>
                      <div className="col-md-10 mb-4">
                        <textarea
                          className="form-control"
                          type="text"
                          rows={5}
                          placeholder="Enter description"
                          {...register("description")}
                        />
                      </div>

                      <label className="col-sm-12 col-md-2 col-form-label mb-4">
                        Meta Title<span className="text-danger">*</span>
                      </label>
                      <div className="col-md-10 mb-4">
                        <input
                          className={`form-control ${errors.metaTitle ? "is-invalid" : ""
                            }`}
                          placeholder="Enter meta title"
                          {...register("metaTitle", { required: true })}
                          type="text"
                        />
                        {errors.metaTitle && (
                          <small className="text-danger">
                            Meta title is required
                          </small>
                        )}
                      </div>

                      <label className="col-sm-12 col-md-2 col-form-label mb-4">
                        Meta Keyword<span className="text-danger">*</span>
                      </label>
                      <div className="col-md-10 mb-4">
                        <input
                          className={`form-control ${errors.metaKeyword ? "is-invalid" : ""
                            }`}
                          placeholder="Enter meta keyword"
                          {...register("metaKeyword", { required: true })}
                          type="text"
                        />
                        {errors.metaKeyword && (
                          <small className="text-danger">
                            Meta keyword is required
                          </small>
                        )}
                      </div>

                      <label className="col-sm-12 col-md-2 col-form-label mb-4">
                        Meta Description<span className="text-danger">*</span>
                      </label>
                      <div className="col-md-10 mb-4">
                        <textarea
                          className={`form-control ${errors.metaDescription ? "is-invalid" : ""
                            }`}
                          placeholder="Enter meta description"
                          {...register("metaDescription", { required: true })}
                          type="text"
                        />
                        {errors.metaDescription && (
                          <small className="text-danger">
                            Meta description is required
                          </small>
                        )}
                      </div>

                      <label className="col-sm-12 col-md-2 col-form-label">
                        Is Active
                      </label>
                      <div className="col-md-10">
                        <div className="custom-control custom-switch">
                          <input
                            type="checkbox"
                            className="custom-control-input"
                            id="customSwitch3"
                            {...register("isActive")}
                          />
                          <label
                            className="custom-control-label"
                            htmlFor="customSwitch3"
                          ></label>
                        </div>
                      </div>

                      <label className="col-sm-12 col-md-2 col-form-label">
                        Show in home
                      </label>
                      <div className="col-md-10">
                        <div className="custom-control custom-switch">
                          <input
                            type="checkbox"
                            className="custom-control-input"
                            id="customSwitch2"
                            {...register("showInHome")}
                          />
                          <label
                            className="custom-control-label"
                            htmlFor="customSwitch2"
                          ></label>
                        </div>
                      </div>
                    </div>
                    <div className="modal-footer justify-content-center">
                      <Link to="/collections">
                        <button
                          type="button"
                          className="btn-sm btn-secondary"
                          data-dismiss="modal"
                        >
                          Back
                        </button>
                      </Link>
                      <button type="submit" className="btn-sm custom-btn">
                        {loading ? "Loading..." : "Save"}
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Addnewbrand;

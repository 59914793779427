import { Link, useNavigate } from "react-router-dom";
import React, { useCallback, useEffect, useState } from "react";
import DataTable from "datatables.net-dt";
import { toast } from "react-toastify";
import axios from "axios";
import Loader from "../Layout/Loader";
import DeleteConfirmationModal from "../component/DeleteConfirmationModal"

const Colors = () => {
    const baseurl = process.env.REACT_APP_API_BASE_URL;
    const navigate = useNavigate();
    const [loading, setLoading] = useState(true)
    const [colorsData, setColorsData] = useState([])
    const [deleteItemId, setDeleteItemId] = useState(null);
    const [modalOpen, setModalOpen] = useState(false);

    const fetchData = useCallback(async () => {
        try {
            setLoading(true);
            let accessToken = localStorage.getItem('accessToken');
            let headersList = {
                "Accept": "*/*",
                "Authorization": `Bearer ${accessToken}`
            }
            const response = await axios.get(`${baseurl}/api/admin/getcolour`, {
                headers: headersList,
            });
            setColorsData(response.data.data);
            setLoading(false);
        } catch (error) {
            setLoading(false);
        }
    }, [baseurl]);

    useEffect(() => {
        fetchData();
    }, [fetchData]);

    useEffect(() => {
        if (colorsData && colorsData.length > 0) {
            const table = new DataTable("#myTable", {
                pageLength: colorsData.length,
                lengthChange: false,
                autoWidth: true,
                paging: false,
                info: false,
                searching: false,
                columnDefs: [
                    { targets: 2, orderable: false },
                    { targets: 3, orderable: false },
                ]
              });
            return () => {
                table.destroy();
            };
        }
    }, [colorsData]);

    const handleDelete = async (id) => {
        setDeleteItemId(id);
        setModalOpen(true);
    };
    
    const confirmDelete = async () => {
      try {
        let accessToken = localStorage.getItem('accessToken');
          let headersList = {
            "Accept": "*/*",
            "Authorization": `Bearer ${accessToken}`
          }
          const response = await axios.delete(`${baseurl}/api/admin/colour/${deleteItemId}`, {
                  headers: headersList,
                });
          if (response.status === 200) {
              toast.success(response.data.message);
              fetchData();
          } else {
              toast.error(response.data.message);
          }
          setModalOpen(false);
      } catch (error) {
          toast.error('Something went wrong. Please try again!!');
      } finally {
          setDeleteItemId(null);
          setModalOpen(false);
      }
    };

    const handleCheckboxChange = async (_id, newIsActive) => {
        try {
            let accessToken = localStorage.getItem('accessToken');

            let headersList = {
                "Accept": "*/*",
                "Authorization": `Bearer ${accessToken}`
            }

            let reqOptions = {
                url: `${baseurl}/api/admin/colourstatus`,
                method: "POST",
                headers: headersList,
                data: {
                    id: _id,
                    isActive: newIsActive,
                }
            }
            let response = await axios.request(reqOptions);
            toast.success(response.data?.message);
            fetchData();
        } catch (error) {
            toast.error(error.response?.data?.message || "An error occurred");
        }
    };

    const toComponentB = (data, event) => {
        event.preventDefault();
        navigate("/add-color", { state: { data } });
    };

    return (
        <>
            <div className="main-container">
                <div className="xs-pd-20-10 ">
                    <div className='d-flex justify-content-between'>
                        <div className="title mb-3">
                            <h2 className="h3 mb-0 mt-3">Colors</h2>
                        </div>
                        <Link to="/add-color">
                            <button className="text-white h4 btn-sm btn-primary mt-2">
                                <i className="icon-copy fi-plus mx-2" />
                                Add Color
                            </button>
                        </Link>
                    </div>
                    <div className="card-box mb-30">
                        {
                            loading === true ? (
                                <Loader />
                            ) :
                                (
                                    <div className="pb-20 pd-20 table-responsive">
                                        <table className="table-striped hover nowrap table-bordered w-100" id="myTable">
                                            <thead>
                                                <tr>
                                                    <th className="p-2 text-center">Name</th>
                                                    <th className="p-2 text-center">Code</th>
                                                    <th className="p-2 text-center">Is Active</th>
                                                    <th className="p-2 text-center">Action</th>
                                                </tr>
                                            </thead>
                                            <tbody className="text-center">
                                                {colorsData && colorsData.length > 0 ? (
                                                    colorsData.map((data, id) => {
                                                        return (
                                                            <tr key={id}>
                                                                <td>{data.name}</td>
                                                                <td>
                                                                    <div className="d-flex justify-content-center">
                                                                        <span
                                                                            style={{
                                                                                backgroundColor: data.code,
                                                                                width: "20px",
                                                                                height: "20px",
                                                                                borderRadius: "50%",
                                                                                border: "1px solid #ccc",
                                                                            }}
                                                                        />
                                                                        <span className="mx-2">{data.code}</span>
                                                                    </div>
                                                                </td>
                                                                <td>
                                                                    <div className="custom-control custom-switch">
                                                                        <input
                                                                            type="checkbox"
                                                                            className="custom-control-input"
                                                                            id={`customSwitch_${data._id}`}
                                                                            checked={data.isActive}
                                                                            onChange={() => handleCheckboxChange(data._id, !data.isActive)}
                                                                        />
                                                                        <label
                                                                            className="custom-control-label"
                                                                            htmlFor={`customSwitch_${data._id}`}
                                                                        ></label>
                                                                    </div>
                                                                </td>
                                                                <td>
                                                                    <button type="Submit" className="btn-sm btn-outline-info mx-2" onClick={(event) => {
                                                                        toComponentB(data, event);
                                                                    }}
                                                                    >
                                                                        <i className="dw dw-edit2 mx-2 "></i>Edit
                                                                    </button>
                                                                    <button type="Submit" className="btn-sm btn-outline-danger mx-2" onClick={() => handleDelete(data._id)}>
                                                                        <i className="dw dw-delete-3 mx-e"></i> Delete
                                                                    </button>
                                                                </td>
                                                            </tr>
                                                        )
                                                    })
                                                ) : (
                                                    <tr>
                                                        <td colSpan="4" className="text-center py-2">
                                                            No data found
                                                        </td>
                                                    </tr>
                                                )}
                                            </tbody>
                                        </table>

                                    </div>
                                )}
                    </div>
                </div>
            </div>
            <DeleteConfirmationModal
                isOpen={modalOpen}
                toggle={() => setModalOpen(!modalOpen)}
                onConfirm={confirmDelete}
                itemName="color"
            />
        </>
    );
};

export default Colors
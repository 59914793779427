import axios from "axios";
import { createSlice } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import { handleTokenErrors } from "./handleTokenErrors";
const baseurl = process.env.REACT_APP_API_BASE_URL;

const pageSlice = createSlice({
  name: "page",
  initialState: {
    isAuthenticated: false,
    user: null,
    error: null,
    loading: false,
    status: true,
  },
  reducers: {
    pagePending: (state) => {
      state.loading = true;
    },
    pageSuccess: (state, { payload }) => {
      state.loading = false;
      state.isAuthenticated = true;
      state.user = payload;
      state.status = 1;
      state.error = null;
    },
    pageFailure: (state, { payload }) => {
      state.loading = false;
      state.isAuthenticated = false;
      state.user = null;
      state.status = 0;
      state.error = payload;
    },
  },
});

export const { pagePending, pageSuccess, pageFailure } =
  pageSlice.actions;

export const pageApi = (id) => async (dispatch) => {
  dispatch(pagePending());
  const token = localStorage.getItem("accessToken");
    try {
      const response = await axios.get(`${baseurl}/api/admin/getpage`,  {
        headers: {
          Authorization: `Bearer ${token}`,
          "Accept": "*/*",
        },
      });
      const data = response.data;
      if (data.status === 1) {
        dispatch(pageSuccess(data));
      } else {
        dispatch(pageFailure(data));
        toast.error(data.message);
      }
    } catch (error) {
      handleTokenErrors(error);
      toast.error(error.response.data.originalError);
    }
};

export default pageSlice.reducer;

import axios from "axios";
import { createSlice } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import { handleTokenErrors } from "./handleTokenErrors";
const baseurl = process.env.REACT_APP_API_BASE_URL;

const contentSlice = createSlice({
  name: "content",
  initialState: {
    isAuthenticated: false,
    user: null,
    error: null,
    loading: false,
    status: true,
  },
  reducers: {
    contentPending: (state) => {
      state.loading = true;
    },
    contentSuccess: (state, { payload }) => {
      state.loading = false;
      state.isAuthenticated = true;
      state.user = payload;
      state.status = 1;
      state.error = null;

    },
    contentFailure: (state, { payload }) => {
      state.loading = false;
      state.isAuthenticated = false;
      state.user = null;
      state.status = 0;
      state.error = payload;
    },
  },
});

export const { contentPending, contentSuccess, contentFailure } =
  contentSlice.actions;

export const contentApi = (id) => async (dispatch) => {
  dispatch(contentPending());
  const token = localStorage.getItem("accessToken");
    try {
      const response = await axios.get(`${baseurl}/api/admin/getcontent`,  {
        headers: {
          Authorization: `Bearer ${token}`,
          "Accept": "*/*",
        },
      });
      const data = response.data;
      if (data.status === 1) {
        dispatch(contentSuccess(data));
      } else {
        dispatch(contentFailure(data));
        toast.error(data.message);
      }
    } catch (error) {
      handleTokenErrors(error);
      toast.error(error.response.data.originalError);
    }
};

export default contentSlice.reducer;

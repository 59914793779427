import React from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { signIn } from "../Redux/loginSlice";

const Login = () => {
  const auth = useSelector((state) => state.auth);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({});

  if (auth.user && auth.user.message) {
    navigate("/dashboard");
  }

  const onSubmit = (data) => {
    dispatch(signIn(data));
  };
  return (
    <>
      <div className="main-container login ">
        <div className="xs-pd-20-10 pd-ltr-20 col-md-12">
          <div className="row d-block h-100">
            <div className="col-md-8 col-sm-12 xs-pd-20-10">
              <div className="pd-20 card-box height-100-p">
                <div className="login-title">
                  <div className="text-center text-primary">
                    <img src="/logo.png" alt="Logo" />
                    <br />
                    <p className="my-3 text-dark">Admin Panel</p>
                  </div>
                </div>

                <form onSubmit={handleSubmit(onSubmit)}>
                  <div className="input-group custom d-flex flex-column">
                    <input
                      type="text"
                      className={`form-control ${errors.username ? "is-invalid" : ""
                        }`}
                      name="username"
                      {...register("username", {
                        required: "Please enter a username",
                      })}
                      placeholder="Username"
                    />
                    <div>
                      {errors.username && (
                        <small className="text-danger">
                          Please enter username
                        </small>
                      )} </div>
                  </div>

                  <div className="input-group custom d-flex flex-column">
                    <input
                      type="password"
                      placeholder="**********"
                      className={`form-control ${errors.password ? "is-invalid" : ""
                        }`}
                      name="password"
                      {...register("password", {
                        required: "Please enter password",
                      })}
                    />
                    {errors.password && (
                      <small className="text-danger">
                        {errors.password.message}
                      </small>
                    )}

                  </div>

                  <div className="row">
                    <div className="col-sm-12">
                      <div className="input-group mb-0">
                        <button
                          className="btn-primary btn-sm btn-block"
                          type="submit"
                        >
                          {auth?.loading ? "Loading..." : "Login"}
                        </button>
                      </div>
                    </div>
                  </div>
                  <div className="row pt-2">
                    <div className="col-12">
                      <div className="forgot-password">
                        <Link to="/forgot-password">Forgot Password?</Link>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Login;

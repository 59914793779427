import DataTable from "datatables.net-dt";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { inquirylist } from "../Redux/getInquirylistSlice";
import Loader from "../Layout/Loader";

const Inquirylist = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const Inquirylists = useSelector(
    (state) => state.inquirylist.data?.document || []
  );
  const Loading = useSelector((state) => state.inquirylist.isLoading);

  useEffect(() => {
    if (Inquirylists && Inquirylists?.length > 0) {
      const table = new DataTable("#myTable", {
        pageLength: 10,
        lengthChange: false,
        autoWidth: true,
        paging: true,
        info: true,
        searching: false,
        columnDefs: [
          { targets: 4, orderable: false },
        ],
      });
      return () => {
        table.destroy();
      };
    }
  }, [Inquirylists]);

  // function handleDelet(_id) {
  //   let accessToken = localStorage.getItem("accessToken");
  //   let headersList = {
  //     Accept: "*/*",
  //     Authorization: `Bearer ${accessToken}`,
  //   };
  //   axios
  //     .delete(`${baseurl}/api/admin/contactinquary/${_id}`, {
  //       headers: headersList,
  //     })
  //     .then((response) => {
  //       dispatch(inquirylist());
  //       toast.success(response.data.message);
  //     })
  //     .catch((error) => {
  //       toast.error(error.data.message);
  //     })
  //     .finally(() => { });
  // }

  useEffect(() => {
    dispatch(inquirylist());
  }, [dispatch]);

  const toComponentB = (data, event) => {
    event.preventDefault();
    navigate("/inquiry-details", { state: { data } });
  };

  return (
    <>
      <div className="main-container">
        <div className="xs-pd-20-10 pd-ltr-20">
          <div className="title pb-20">
            <h2 className="h3 mb-0">Contact Inquiry</h2>
          </div>

          <div className="card-box mb-30">
            {Loading ? (
              <Loader />
            ) : (
              <div className="pb-20 pd-20 table-responsive">
                <table
                  className="table-striped hover nowrap table-bordered w-100"
                  id="myTable"
                >
                  <thead>
                    <tr>
                      <th className="p-2 text-center">Name</th>
                      <th className="p-2 text-center">Email</th>
                      <th className="p-2 text-center">Mobile No</th>
                      <th className="p-2 text-center">Message</th>
                      <th className="p-2 text-center">Action</th>
                    </tr>
                  </thead>
                  <tbody className="text-center">
                    {Inquirylists && Inquirylists.length > 0 ? (
                      Inquirylists.map((data, index) => (
                        <tr key={index}>
                          <td>{data.name}</td>
                          <td>{data.email}</td>
                          <td>{data.mobile_no}</td>
                          <td>{data.message.substring(0, 20)}</td>
                          <td>
                            <button
                              type="Submit"
                              className="btn-sm btn-outline-info mx-2"
                              onClick={(event) => {
                                toComponentB(data, event);
                              }}
                            >
                              <i className="dw dw-eye mx-2" />
                              View
                            </button>
                            {/* <button
                              type="Submit"
                              className="btn-sm btn-outline-danger mx-2"
                              onClick={() => handleDelet(data._id)}
                            >
                              <i className="dw dw-delete-3 mx-e"></i> Delete
                            </button> */}
                          </td>
                        </tr>
                      ))
                    ) : (
                      <tr>
                        <td colSpan="5" className="text-center py-2">
                          No data found
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default Inquirylist;

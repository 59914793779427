import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import Select from "react-select";
import axios from "axios";
import { CommonEditor } from "../component/CommonEditor";
import { getBrandApi } from "../Redux/getBrandSlice";

const baseurl = process.env.REACT_APP_API_BASE_URL;

const ProductForm = () => {
  const [loading, setLoading] = useState(false);
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const rootname = location.state?.name;
  const [tags, setTags] = useState([]);
  const [inputTag, setInputTag] = useState("");
  const [data, setData] = useState([]);
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    setValue,
  } = useForm({});

  const [color, setColor] = useState(
    location.state?.item?.color?.map((item) => ({
      value: item._id,
      label: item.name,
    })) || []
  );

  const [metal, setMetal] = useState(
    location.state?.item?.metal?.map((item) => ({
      value: item._id,
      label: item.name,
    })) || []
  );
  
  const [gold_purity, setPurity] = useState(
    location?.state?.item?.gold_purity?.map((item) => ({
      value: item._id,
      label: item.name,
    })) || []
  );

  const [weight, setWeight] = useState([{ value: "" }]);
  const pathname = location.pathname;
  const pathname1 = pathname.split("/")[1];
  const handleAddWeight = () => {
    setWeight([...weight, { value: "" }]);
  };

  const handleRemoveWeight = (index) => {
    const valuesCopy = [...weight];
    valuesCopy.splice(index, 1);
    setWeight(valuesCopy);
  };

  const getBrand = useSelector((state) => state.getBrand?.data);
  const [selectedImages, setSelectedImages] = useState([]);
  const [selectedImageIndex, setSelectedImageIndex] = useState(0);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(`${baseurl}/api/categories`);
        setData(response?.data?.document);
      } catch (error) {
        toast.error(error);
      }
    };
    fetchData();
  }, []);

  useEffect(() => {
    dispatch(getBrandApi());
  }, [dispatch]);

  const [selectedImage] = useState(null);
  const [colorsData, setColorsData] = useState([]);
  const [metalData, setMetalData] = useState([]);
  const [purityData, setPurityData] = useState([]);

  const handleImageChange = (e) => {
    const files = Array.from(e.target.files);
    setSelectedImages([...selectedImages, ...files]);
  };

  function handleDeleteImage(index) {
    const updatedImages = [...selectedImages];
    updatedImages.splice(index, 1);
    setSelectedImages(updatedImages);
  }

  const [selectedCategories, setSelectedCategories] = useState(
    location.state?.item?.category?.map((category) => category._id) || []
  );
  const [selectedBrand, setSelectedBrand] = useState(
    location.state?.item?.brand?.map((brand) => brand._id) || []
  );

  useEffect(() => {
    if (location.state?.item?.category) {
      setSelectedCategories(
        location.state?.item?.category?.map((category) => category?._id)
      );
    }

    if (location.state?.item?.brand?.length > 0) {
      setSelectedBrand(location.state?.item?.brand?.map((brand) => brand?._id));
    }
  }, [location.state]);

  const handleCategoryChange = (categoryIndex) => {
    const updatedCategories = [...selectedCategories];

    if (updatedCategories?.includes(categoryIndex)) {
      updatedCategories.splice(updatedCategories.indexOf(categoryIndex), 1);
    } else {
      updatedCategories.push(categoryIndex);
    }

    setSelectedCategories(updatedCategories);
  };

  const handleBrandSelect = (brandIndex) => {
    const updatedBrands = [...selectedBrand];
    if (updatedBrands?.includes(brandIndex)) {
      updatedBrands.splice(updatedBrands.indexOf(brandIndex), 1);
    } else {
      updatedBrands.push(brandIndex);
    }
    setSelectedBrand(updatedBrands);
  };

  useEffect(() => {
    reset(location?.state?.item);
  }, [location?.state?.item, reset]);

  useEffect(() => {
    const weightData = location.state?.item?.gross_weight;
    if (weightData && weightData?.length > 0) {
      setWeight(weightData?.map((value) => ({ value })));
    }

    const tagsData = location.state?.item?.tags;
    if (tagsData && tagsData?.length > 0) {
      setInputTag(tagsData?.map((value) => value));
      setTags(tagsData?.map((value) => value));
    }
  }, [location.state?.item]);

  const defaultImageFilename = location.state?.item?.product_image?.map(
    (img) => img
  );


  useEffect(() => {
    if (location.state?.item?.tags) {
      setTags(location.state.item.tags);
    }
  }, [location.state]);

  const handleAddTag = () => {
    if (inputTag.trim() !== "") {
      setTags([...tags, inputTag.trim()]);
      setInputTag("");
    }
  };

  const handleRemoveTag = (indexToRemove) => {
    const updatedTags = tags.filter((tag, index) => index !== indexToRemove);
    setTags(updatedTags);
  };

  useEffect(() => {
    register('metal', { required: 'Metal is required' });
    register('color', { required: 'Color is required' });
    register('gold_purity', { required: 'Gold purity is required' });
  }, [register]);

  const onSubmit = async (data) => {
    setLoading(true);
    const colors = color?.map((option) => option.value);
    const metals = metal?.map((option) => option.value);
    const purity = gold_purity?.map((option) => option.value);
    const formData = new FormData();

    if (selectedImages?.length === 0) {
      for (let i = 0; i < location.state?.item?.product_image?.length; i++) {
        const imagePath = `${baseurl}/${location.state?.item.product_image[i]}`;
        const imageBlob = await fetch(imagePath).then((res) => res.blob());
        formData.append("product_image", imageBlob, defaultImageFilename);
      }
    } else {
      for (let i = 0; i < selectedImages?.length; i++) {
        formData.append("product_image", selectedImages[i]);
      }
    }
    if (tags.length === 0) {
      for (let i = 0; i < location.state?.item?.tags?.length; i++) {
        formData.append(`tags[${i}]`, location.state?.item.tags[i].value);
      }
    } else {
      for (let i = 0; i < tags?.length; i++) {
        formData.append(`tags[${i}]`, tags[i]);
      }
    }
    formData.append("name", data.name);
    formData.append("defaultimage", data.defaultimage);
    if (data.slugname) {
      formData.append("slugname", data.slugname);
    }
    formData.append("sku", data.sku);
    for (let i = 0; i < metals?.length; i++) {
      formData.append(`metal[${i}]`, metals[i]);
    }
    for (let i = 0; i < colors?.length; i++) {
      formData.append(`color[${i}]`, colors[i]);
    }
    for (let i = 0; i < weight?.length; i++) {
      formData.append(`gross_weight[${i}]`, weight[i].value);
    }
    for (let i = 0; i < purity?.length; i++) {
      formData.append(`gold_purity[${i}]`, purity[i]);
    }
    formData.append("gender", data.gender);
    formData.append("short_description", data.short_description);
    formData.append("long_description", data.long_description);
    formData.append("metaTitle", data.metaTitle);
    formData.append("metaDescription", data.metaDescription);
    formData.append("freezed", data.freezed);
    formData.append("isActive", data.isActive);
    for (let i = 0; i < selectedCategories?.length; i++) {
      formData.append(`category[${i}]`, selectedCategories[i]);
    }
    for (let i = 0; i < selectedBrand?.length; i++) {
      formData.append(`brand[${i}]`, selectedBrand[i]);
    }
    if (data._id) {
      formData.append("id", data._id);
    }
    try {
      let accessToken = localStorage.getItem("accessToken");
      let headersList = {
        Accept: "*/*",
        Authorization: `Bearer ${accessToken}`,
      };
      const response = await fetch(`${baseurl}/api/admin/product`, {
        method: "POST",
        body: formData,
        headers: headersList,
      });
      const responseData = await response.json();
      if (responseData.status === 1) {
        toast.success(responseData.message);
        reset();
        if (selectedBrand && selectedBrand.length > 0) {
          navigate(`/${pathname1}/brand-product`);
        } else {
          navigate(`/${pathname1}/products`);
        }
      } else if (responseData.error) {
        toast.error(responseData.error)
      }
      else {
        toast.error(responseData.originalError);
      }
    } catch (error) {
      toast.error("Error submitting form:", error);
    } finally {
      setLoading(false);
    }
  };

  const fetchColorData = async () => {
    try {
      setLoading(true);
      let accessToken = localStorage.getItem("accessToken");
      let headersList = {
        Accept: "*/*",
        Authorization: `Bearer ${accessToken}`,
      };
      const response = await axios.get(`${baseurl}/api/admin/getcolour`, {
        headers: headersList,
      });
      setColorsData(response.data.data);
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };

  const fetchMetalData = async () => {
    try {
      setLoading(true);
      let accessToken = localStorage.getItem("accessToken");
      let headersList = {
        Accept: "*/*",
        Authorization: `Bearer ${accessToken}`,
      };
      const response = await axios.get(`${baseurl}/api/admin/getmetal`, {
        headers: headersList,
      });
      setMetalData(response.data.data);
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };
  const onBackBtn = () => {
    if (selectedBrand && selectedBrand.length > 0) {
      navigate(`/${pathname1}/brand-product`);
    } else {
      navigate(`/${pathname1}/products`);
    }

  }
  const fetchPurityData = async () => {
    try {
      setLoading(true);
      let accessToken = localStorage.getItem("accessToken");
      let headersList = {
        Accept: "*/*",
        Authorization: `Bearer ${accessToken}`,
      };
      const response = await axios.get(`${baseurl}/api/admin/getpurity`, {
        headers: headersList,
      });
      setPurityData(response.data.data);
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchColorData();
    fetchMetalData();
    fetchPurityData();
  }, []);

  return (
    <>
      <div className="main-container">
        <div className="xs-pd-20-10 pd-ltr-20 col-md-12">
          <div className="d-flex justify-content-between">
            <div className="title mb-3">
              <h2 className="h3 mb-0">Add Product</h2>
            </div>
            <Link to={`/${pathname1}/products`}>
              <button className="btn-sm btn-secondary btn-light h4 mx-2">
                <i
                  className="icon-copy fa fa-arrow-left mx-2"
                  aria-hidden="true"
                />
                Back to Product list
              </button>
            </Link>
          </div>
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="row">
              <div className="col-md-8 col-sm-12">
                <div className="pd-20 card-box height-100-p">
                  <div className="">
                    <div className="d-flex">
                      <label className="col-sm-12 col-md-3 mb-4 col-form-label" />
                      <div className="custom-control custom-radio mb-5 ml-3">
                        <input
                          type="radio"
                          id="customRadio2"
                          name="customRadio"
                          className="custom-control-input"
                          {...register("freezed")}
                          value="false"
                          defaultChecked={location.state?.item?.freezed === false}
                        />
                        <label className="custom-control-label" htmlFor="customRadio2">
                          Active product
                        </label>
                      </div>

                      <div className="custom-control custom-radio mb-5 ml-3">
                        <input
                          type="radio"
                          id="customRadio3"
                          name="customRadio"
                          className="custom-control-input"
                          {...register("freezed")}
                          value="true"
                          defaultChecked={location.state?.item?.freezed === true}
                        />
                        <label className="custom-control-label" htmlFor="customRadio3">
                          Out of stock product
                        </label>
                      </div>
                    </div>

                    <div className="form-group row">
                      <label className="col-sm-12 col-md-3 col-form-label mb-4">
                        Product Name<span className="text-danger">*</span>
                      </label>
                      <div className="col-md-9 mb-4">
                        <input
                          className={`form-control ${errors.productname ? "is-invalid" : ""
                            }`}
                          value={rootname}
                          {...register("name", { required: true })}
                          placeholder="Enter name"
                          type="text"
                        />
                        {errors.name && (
                          <small className="text-danger">
                            Product name is required
                          </small>
                        )}
                      </div>

                      <label className="col-sm-12 col-md-3 col-form-label mb-4">
                        Product SKU<span className="text-danger">*</span>
                      </label>
                      <div className="col-md-9 mb-4">
                        <input
                          className={`form-control ${errors.sku ? "is-invalid" : ""
                            }`}
                          {...register("sku", { required: true })}
                          placeholder="Enter SKU"
                          type="text"
                        />
                        {errors.sku && (
                          <small className="text-danger">
                            Product SKU is required
                          </small>
                        )}
                      </div>

                      <label className="col-sm-12 col-md-3 mb-4 col-form-label">
                        URL/Slug
                      </label>
                      <div className="col-md-9 mb-4">
                        <input
                          className={`form-control ${errors.slug ? "is-invalid" : ""
                            }`}
                          placeholder="Enter slug"
                          {...register("slugname")}
                          type="text"
                          defaultValue={location.state?.item?.slug}
                        />
                      </div>

                      <label className="col-sm-12 col-md-3 col-form-label mb-4">
                        Metal<span className="text-danger">*</span>
                      </label>
                      <div className="col-md-9">
                        <div className="d-flex flex-column">
                          <Select
                            name="metal"
                            placeholder="Select metal"
                            value={metal}
                            className={errors.metal ? 'is-invalid' : ''}
                            onChange={(selected) => {
                              setMetal(selected);
                              setValue("metal", selected, {
                                shouldValidate: true,
                              });
                            }}
                            options={metalData.map((item) => ({
                              value: item._id,
                              label: item.name,
                            }))}
                            isMulti
                          />
                          {errors.metal && (
                            <div className="invalid-feedback">
                              {errors.metal.message}
                            </div>
                          )}
                        </div>
                      </div>

                      <label className="col-sm-12 col-md-3 col-form-label mb-4">
                        Color<span className="text-danger">*</span>
                      </label>
                      <div className="col-md-9">
                        <div className="d-flex flex-column">
                          <Select
                            name="color"
                            placeholder="Select color"
                            value={color}
                            className={errors.color ? 'is-invalid' : ''}
                            onChange={(selected) => {
                              setColor(selected);
                              setValue("color", selected, {
                                shouldValidate: true,
                              });
                            }}
                            options={colorsData.map((item) => ({
                              value: item._id,
                              label: item.name,
                            }))}
                            isMulti
                          />
                        </div>
                        {errors.color && (
                          <small className="text-danger">
                            {errors.color.message}
                          </small>
                        )}
                      </div>

                      <label className="col-sm-12 col-md-3 mb-4 col-form-label">
                        Gold Purity<span className="text-danger">*</span>
                      </label>
                      <div className="col-md-9">
                        <div className="d-flex flex-column">
                          <Select
                            name="gold_purity"
                            placeholder="Select gold purity"
                            value={gold_purity}
                            className={errors.gold_purity ? 'is-invalid' : ''}
                            onChange={(selected) => {
                              setPurity(selected);
                              setValue("gold_purity", selected, {
                                shouldValidate: true,
                              });
                            }}
                            options={purityData.map((item) => ({
                              value: item._id,
                              label: item.name,
                            }))}
                            isMulti
                          />
                        </div>
                        {errors.gold_purity && (
                          <small className="text-danger">
                            {errors.gold_purity.message}
                          </small>
                        )}
                      </div>

                      <label className="col-sm-12 col-md-3 col-form-label mb-4">
                        Gross Weight<span className="text-danger">*</span>
                      </label>
                      <div className="col-md-9 ">
                        <div className="d-flex flex-column">
                          {weight?.map((field, index) => (
                            <div
                              key={index}
                              className="d-flex align-items-center mb-4"
                            >
                              <input
                                className={`form-control ${errors.weight ? "is-invalid" : ""
                                  }`}
                                type="text"
                                placeholder="Enter weigth"
                                value={field.value}
                                onChange={(e) => {
                                  const valuesCopy = [...weight];
                                  valuesCopy[index].value = e.target.value;
                                  setWeight(valuesCopy);
                                }}
                              />
                              {index !== 0 && (
                                <button
                                  className="btn-sm btn-danger mx-2"
                                  type="button"
                                  onClick={() => handleRemoveWeight(index)}
                                >
                                  <i className="icon-copy dw dw-delete-3"></i>
                                </button>
                              )}
                              {index === 0 && (
                                <button
                                  type="button"
                                  className="rbt-btn btn-sm add-field mx-2"
                                  onClick={handleAddWeight}
                                >
                                  Add
                                </button>
                              )}
                            </div>
                          ))}
                        </div>
                        {errors.weight && (
                          <small className="text-danger">
                            Gross weight is required
                          </small>
                        )}
                      </div>

                      <label className="col-sm-12 col-md-3 col-form-label mb-4">
                        Gender<span className="text-danger">*</span>
                      </label>
                      <div className="col-md-9 mb-4">
                        <select
                          className={`form-control ${errors.gender ? "is-invalid" : ""
                            }`}
                          {...register("gender", { required: true })}
                        >
                          <option value="">-- Select gender --</option>
                          <option value="Unisex">Unisex </option>
                          <option value="Male">Male </option>
                          <option value="Female">Female </option>
                          <option value="Child">Child </option>
                        </select>
                        {errors.gender && (
                          <small className="text-danger">
                            Gender is required
                          </small>
                        )}
                      </div>

                      <label className="col-sm-12 col-md-3 mb-4 col-form-label">
                        Short description
                      </label>
                      <div className="col-md-9 mb-4">
                        <textarea
                          className={`form-control`}
                          {...register("short_description")}
                          placeholder="Enter short description"
                          type="text"
                          rows={5}
                        />
                      </div>

                      <label className="col-sm-12 col-md-3 mb-4 col-form-label">
                        Long description
                      </label>
                      <div className="col-md-9 mb-4">
                        <CommonEditor
                          className={`form-control`}
                          {...register("long_description")}
                          placeholder="Enter description ..."
                          onChange={(value) =>
                            setValue("long_description", value, {
                              shouldValidate: true,
                            })
                          }
                          value={location.state?.item?.long_description}
                        />
                      </div>

                      <label className="col-sm-12 col-md-3 mb-3 col-form-label">
                        Product Image<span className="text-danger">*</span>
                      </label>
                      <div className="col-md-9 ">
                        <input
                          className={`form-control ${errors.product_image && selectedImages?.length === 0
                            ? "is-invalid"
                            : ""
                            }`}
                          {...register("product_image", {
                            required: !location.state?.item?.product_image,
                          })}
                          type="file"
                          multiple
                          onChange={(event) => handleImageChange(event)}
                        />
                        {errors.product_image && (
                          <small className="text-danger">
                            Product image is required
                          </small>
                        )}
                        <div className="d-flex mt-2">
                          {selectedImages?.length === 0
                            ? location.state?.item?.product_image?.map(
                              (data, i) => {
                                return (
                                  <img
                                    key={i}
                                    src={`${baseurl}/${data}`}
                                    alt="Preview"
                                    className="preview-image mb-3 mx-2"
                                    width={100}
                                  />
                                );
                              }
                            )
                            : selectedImages?.map((image, index) => {
                              return (
                                <div key={index}>
                                  <img
                                    className="mb-3 mx-2"
                                    src={URL.createObjectURL(image)}
                                    alt={`Uploaded ${index}`}
                                    width={100}
                                  />
                                </div>
                              );
                            })}
                        </div>
                        <div>
                          <div className="d-flex mt-4">
                            {selectedImages?.map((image, index) => {
                              return (
                                <div key={index}>
                                  <img
                                    className=""
                                    src={URL.createObjectURL(image)}
                                    alt={`Uploaded ${index}`}
                                    width={50}
                                  />
                                  <span
                                    className="delete-button"
                                    onClick={() => handleDeleteImage(index)}
                                  >
                                    <i className="icon-copy dw dw-delete-3 text-danger"></i>
                                  </span>
                                  <p className="text-center">
                                    <input
                                      type="radio"
                                      name="selectedImage"
                                      value={index}
                                      {...register("defaultimage")}
                                      checked={selectedImageIndex === index}
                                      onChange={() =>
                                        setSelectedImageIndex(index)
                                      }
                                    />
                                  </p>
                                </div>
                              );
                            })}
                          </div>
                          {selectedImage && (
                            <div>
                              <h2>Selected Image</h2>
                              <img
                                src={URL.createObjectURL(selectedImage)}
                                alt="Selected_Image"
                              />
                            </div>
                          )}
                        </div>
                      </div>

                      <label className="col-sm-12 col-md-3 mb-4 col-form-label">
                        Meta Title
                      </label>
                      <div className="col-md-9 mb-4">
                        <input
                          className={`form-control`}
                          placeholder="Enter meta title"
                          {...register("metaTitle")}
                          type="text"
                        />
                      </div>

                      <label className="col-sm-12 col-md-3 mb-4 col-form-label">
                        Meta Description
                      </label>
                      <div className="col-md-9 mb-4">
                        <textarea
                          className={`form-control`}
                          placeholder="Enter meta description"
                          {...register("metaDescription")}
                          type="text"
                        />
                      </div>
                    </div>

                    <div className="form-group row mb-4">
                      <label className="col-sm-12 col-md-3 col-form-label">
                        Is Active
                      </label>
                      <div className="col-md-9">
                        <div className="custom-control custom-switch">
                          <input
                            type="checkbox"
                            className="custom-control-input"
                            id="customSwitch3"
                            {...register("isActive")}
                          />
                          <label
                            className="custom-control-label"
                            htmlFor="customSwitch3"
                          ></label>
                        </div>
                      </div>
                    </div>

                    <div className="modal-footer justify-content-center">
                      <button
                        type="button"
                        className="btn-sm btn-secondary"
                        data-dismiss="modal"
                        onClick={onBackBtn}
                      >
                        Back
                      </button>
                      <button
                        type="submit"
                        className="btn-sm custom-btn"
                        disabled={loading}
                      >
                        {loading ? "Loading..." : "Save"}
                      </button>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-md-4 col-sm-12">
                <div className="pd-5 card-box">
                  <div className="modal-header">
                    <h5 className="text-dark">Product Categories</h5>
                  </div>
                  <div className="">
                    <div className="pd-20 col-xs-12">
                      {data?.map((e, i) => {
                        return (
                          <ul className="dd-list" key={i}>
                            <li>
                              <div className="custom-control custom-checkbox">
                                <input
                                  className="custom-control-input"
                                  type="checkbox"
                                  id={e._id}
                                  checked={
                                    data._id
                                      ? selectedCategories[0]?._id?.includes(
                                        e._id
                                      )
                                      : selectedCategories?.includes(e._id)
                                  }
                                  onChange={() => handleCategoryChange(e._id)}
                                />
                                <label
                                  className="custom-control-label mx-2"
                                  htmlFor={e._id}
                                >
                                  {e.name}
                                </label>
                              </div>
                              <ul className="sub-menu mx-4">
                                {e?.nestedSubcategories?.map(
                                  (el, j) =>
                                    el.level === 0 && (
                                      <ul className="dd-list" key={j}>
                                        <li>
                                          <div className="custom-control custom-checkbox">
                                            <input
                                              className="custom-control-input"
                                              type="checkbox"
                                              id={el._id}
                                              checked={selectedCategories[0]?._id?.includes(
                                                el._id
                                              )}
                                              onChange={() =>
                                                handleCategoryChange(el._id)
                                              }
                                            />
                                            <label
                                              className="custom-control-label mx-2"
                                              htmlFor={el._id}
                                            >
                                              {el.name}
                                            </label>
                                          </div>
                                          <ul className="sub-menu mx-4">
                                            {el?.nestedSubcategories?.map(
                                              (el2, k) =>
                                                el2.parentCategory ===
                                                el._id && (
                                                  <ul
                                                    className="dd-list"
                                                    key={k}
                                                  >
                                                    <li>
                                                      <div className="custom-control custom-checkbox">
                                                        <input
                                                          className="custom-control-input"
                                                          type="checkbox"
                                                          id={el2._id}
                                                          checked={selectedCategories[0]?._id?.includes(
                                                            el2._id
                                                          )}
                                                          onChange={() =>
                                                            handleCategoryChange(
                                                              el2._id
                                                            )
                                                          }
                                                        />
                                                        <label
                                                          className="custom-control-label mx-2"
                                                          htmlFor={el2._id}
                                                        >
                                                          {el2.name}
                                                        </label>
                                                      </div>
                                                    </li>
                                                  </ul>
                                                )
                                            )}
                                          </ul>
                                        </li>
                                      </ul>
                                    )
                                )}
                              </ul>
                            </li>
                          </ul>
                        );
                      })}
                    </div>
                  </div>
                </div>
                <div className="pd-5 card-box mt-4">
                  <div className="modal-header">
                    <h5 className="text-dark">Product Brands</h5>
                  </div>
                  <div className="mt-2">
                    <div className="pd-20 col-xs-12">
                      {getBrand?.document?.map((e, i) => (
                        <ul className="dd-list" key={i}>
                          <li>
                            <div className="custom-control custom-checkbox">
                              <input
                                className="custom-control-input"
                                type="checkbox"
                                id={e._id}
                                checked={
                                  data._id
                                    ? selectedBrand[0]?._id?.includes(e._id)
                                    : selectedBrand?.includes(e._id)
                                }
                                onChange={() => handleBrandSelect(e._id)}
                              />
                              <label
                                className="custom-control-label mx-2"
                                htmlFor={e._id}
                              >
                                {e.name}
                              </label>
                            </div>
                          </li>
                        </ul>
                      ))}
                    </div>
                  </div>
                </div>

                <div className="pd-5 card-box mt-4">
                  <div className="modal-header">
                    <h5 className="text-dark">Product Tags</h5>
                  </div>
                  <div className="mt-3 mx-2">
                    <div className="row mb-3">
                      <div className="col-md-9">
                        <input
                          className="form-control"
                          type="text"
                          value={inputTag}
                          onChange={(e) => setInputTag(e.target.value)}
                        />
                      </div>
                      <div className="col-md-3">
                        <button
                          type="button"
                          className="custom-btn"
                          onClick={handleAddTag}
                        >
                          Add
                        </button>
                      </div>
                    </div>
                    <div className="mb-5">
                      {tags?.map((tag, index) => (
                        <span key={index} className="custom-btn tag-btn">
                          {tag}
                          <span
                            className="ml-1 cursor-pointer remove"
                            onClick={() => handleRemoveTag(index)}
                          >
                            &times;
                          </span>
                        </span>
                      ))}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </>
  );
};

export default ProductForm;

import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { toast } from "react-toastify";
import { homeslider } from "../Redux/getHomeSliderSlice";
import { useDispatch, useSelector } from "react-redux";
import { ImageLink } from "../constants";


const getFileExtension = (url) => {
  const path = new URL(url).pathname;
  const extension = path.split(".").pop();
  return extension;
};

const AddBannerSlider = () => {
  const location = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const url = process.env.REACT_APP_API_BASE_URL;
  const data = location.state?.data;
  const isEditing = data?._id;
  const [loading, setLoading] = useState(false);
  const [previewImage, setPreviewImage] = useState(data?.slider_image && data?.slider_image ? ImageLink(data?.slider_image) : "");
  const [previewImage2, setPreviewImage2] = useState(data?.slider_mobile_image && data?.slider_mobile_image ? ImageLink(data?.slider_mobile_image) : "");
  const [fileExtension, setFileExtension] = useState("");

  const handleFileChange = (event, setImagePreview) => {
    const file = event.target.files[0];
    if (file) {
      const extension = file.name.split(".").pop();
      setFileExtension(extension);
      const reader = new FileReader();
      reader.onloadend = () => {
        setImagePreview(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({});

  useEffect(() => {
    reset(data);

  }, [data, reset]);

  const onSubmit = async (data) => {
    setLoading(true);
    const formData = new FormData();
    formData.append("sortOrder", data.sortOrder);
    formData.append("title", data.title);
    formData.append("isActive", data.isActive);
    if (data.click_url) {
      formData.append("click_url", data.click_url);
    }

    if (data.slider_image instanceof File) {
      formData.append("slider_image", data?.slider_image);
    } else if (previewImage) {
      const extension = fileExtension || getFileExtension(previewImage);
      const blob = await fetch(previewImage).then((res) => res.blob());
      formData.append("slider_image", blob, `previewImage.${extension}`);
    }

    if (data.slider_mobile_image instanceof File) {
      formData.append("slider_mobile_image", data?.slider_mobile_image);
    } else if (previewImage2) {
      const extension = fileExtension || getFileExtension(previewImage);
      const blob = await fetch(previewImage2).then((res) => res.blob());
      formData.append(
        "slider_mobile_image",
        blob,
        `previewMobileImage.${extension}`
      );
    }

    if (isEditing) {
      formData.append("id", isEditing);
    }
    try {
      let accessToken = localStorage.getItem("accessToken");
      let headersList = {
        Accept: "*/*",
        Authorization: `Bearer ${accessToken}`,
      };
      const response = await fetch(`${url}/api/admin/homeslider`, {
        method: "POST",
        body: formData,
        headers: headersList,
      });
      const responseData = await response.json();
      if (responseData.status === 1) {
        toast.success(responseData.message);
        reset();
        navigate("/home-slider");
      } else {
        toast.error(responseData.error);
      }
    } catch (error) {
      toast.error(error.message);
    } finally {
      setLoading(false);
    }
  };

  const homesliders = useSelector(
    (state) => state.homeSlider.data?.document || []
  );
  useEffect(() => {
    dispatch(homeslider());
  }, [dispatch]);

  return (
    <>
      <div className="main-container">
        <div className="xs-pd-20-10 pd-ltr-20 col-md-12">
          <div className="title pb-20">
            <h2 className="h3 mb-0">Add Home Slider</h2>
          </div>
          <div className="row">
            <div className="col-md-12 col-sm-12 mb-30">
              <div className="pd-20 card-box height-100-p">
                <div className="pd-20  ">
                  <form onSubmit={handleSubmit(onSubmit)}>
                    <div className="form-group row">
                      <label className="col-sm-12 col-md-2 col-form-label mb-4">
                        Title<span className="text-danger">*</span>
                      </label>
                      <div className="col-md-10 mb-4">
                        <input
                          {...register("title", { required: true })}
                          className={`form-control ${errors.title ? "is-invalid" : ""
                            }`}
                          type="text"
                        />
                        {errors.title && (
                          <small className="text-danger">
                            Title is required
                          </small>
                        )}
                      </div>

                      <label className="col-sm-12 col-md-2 col-form-label mb-4">
                        Link
                      </label>
                      <div className="col-md-10 mb-4">
                        <input
                          {...register("click_url")}
                          className={`form-control ${errors.click_url ? "is-invalid" : ""
                            }`}
                          type="text"
                        />
                      </div>

                      <label className="col-sm-12 col-md-2 col-form-label mb-4">
                        Sort Order<span className="text-danger">*</span>
                      </label>
                      <div className="col-md-10 mb-4">
                        <input
                          className={`form-control ${errors.sortorder ? "is-invalid" : ""
                            }`}
                          {...register("sortOrder", { required: true })}
                          placeholder="Enter sort order"
                          defaultValue={
                            data?.sortOrder
                              ? data?.sortOrder
                              : homesliders.length + 1
                          }
                          type="text"
                        />
                        {errors.sortorder && (
                          <small className="text-danger">
                            Sort order is required
                          </small>
                        )}
                      </div>
                      <label className="col-sm-12 col-md-2 mb-4 col-form-label">
                        Desktop Image<span className="text-danger">*</span>
                      </label>
                      <div className="col-md-10 mb-4">
                        <input
                          className={`form-control ${!isEditing && errors?.slider_image
                            ? "is-invalid"
                            : ""
                            }
                          `}
                          {...register("slider_image", {
                            required: !isEditing ? true : false,
                          })}
                          type="file"
                          onChange={(event) =>
                            handleFileChange(event, setPreviewImage)
                          }
                        />
                        {/* {previewImage && (
                          <div className="mb-3 mt-3">
                            <img
                              src={ImageLink(previewImage)}
                              alt="Preview"
                              className="preview-image"
                              style={{ maxWidth: "25%" }}
                            />
                          </div>
                        )} */}
                        {previewImage && (
                          <div className="mb-3 mt-3">
                            <img
                              src={previewImage}
                              alt=""
                              className="preview-image"
                              style={{ maxWidth: "50%" }}
                            />
                            {/* <button type="button" className="close-btn" onClick={() => setPreviewImage('')}>
                              X
                            </button> */}
                          </div>
                        )}
                        {!isEditing && errors?.slider_image && (
                          <small className="text-danger">
                            Banner desktop image is required
                          </small>
                        )}
                        <small className="form-text text-muted">
                          Required image size:1920 X 779 (.png, .jpg, .jpeg,
                          .webp)
                        </small>
                      </div>
                      <label className="col-sm-12 col-md-2 mb-4 col-form-label">
                        Mobile Image<span className="text-danger">*</span>
                      </label>
                      <div className="col-md-10 mb-4">
                        <input
                          className="form-control"
                          {...register("slider_mobile_image")}
                          type="file"
                          onChange={(event) =>
                            handleFileChange(event, setPreviewImage2)
                          }
                        />
                        {previewImage2 && (
                          <div className="mb-3 mt-3">
                            <img
                              src={previewImage2}
                              alt=""
                              className="preview-image"
                              style={{ maxWidth: "50%" }}
                            />
                            {/* <button type="button" className="close-btn" onClick={() => setPreviewImage2('')}>
                              X
                            </button> */}
                          </div>
                        )}
                        {!isEditing && errors?.slider_image && (
                          <small className="text-danger">
                            Banner mobile image is required
                          </small>
                        )}
                        <small className="form-text text-muted">
                          Required image size: 600 X 779 (.png, .jpg, .jpeg,
                          .webp)
                        </small>
                      </div>
                    </div>
                    <div className="form-group row mb-4">
                      <label className="col-sm-12 col-md-2 col-form-label">
                        Is Active
                      </label>
                      <div className="col-md-10">
                        <div className="custom-control custom-switch">
                          <input
                            type="checkbox"
                            className="custom-control-input"
                            id="customSwitch3"
                            {...register("isActive")}
                          />
                          <label
                            className="custom-control-label"
                            htmlFor="customSwitch3"
                          ></label>
                        </div>
                      </div>
                    </div>

                    <div className="modal-footer justify-content-center">
                      <Link to="/home-slider">
                        <button
                          type="button"
                          className="btn-sm btn-secondary"
                          data-dismiss="modal"
                        >
                          Back
                        </button>
                      </Link>
                      <button
                        type="submit"
                        className="btn-sm custom-btn"
                        disabled={loading}
                      >
                        {loading ? "Loading..." : "Save"}
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AddBannerSlider;
import { Link, useNavigate } from "react-router-dom";
import React, { useEffect, useState } from "react";
import DataTable from "datatables.net-dt";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import Loader from "../Layout/Loader";
import { ImageLink } from "../constants";
import { newseventApi } from "../Redux/newsSlice";
import DeleteConfirmationModal from "../component/DeleteConfirmationModal";

const NewsEvent = () => {
    const baseurl = process.env.REACT_APP_API_BASE_URL;
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [deleteItemId, setDeleteItemId] = useState(null);
    const [modalOpen, setModalOpen] = useState(false);
    const newsevent = useSelector(
        (state) => state.newsevent.user?.document || []
    );
    const Loading = useSelector((state) => state.newsevent.loading);

    useEffect(() => {
        if (newsevent && newsevent.length > 0) {
            const table = new DataTable("#myTable", {
                pageLength: newsevent.length,
                lengthChange: false,
                autoWidth: true,
                paging: false,
                info: false,
                searching: false,
                columnDefs: [
                    { targets: 3, orderable: false },
                    { targets: 4, orderable: false },
                    { targets: 5, orderable: false }
                ]
            });
            return () => {
                table.destroy();
            };
        }
    }, [newsevent]);

    useEffect(() => {
        dispatch(newseventApi());
    }, [dispatch]);

    const handleDelete = async (id) => {
        setDeleteItemId(id);
        setModalOpen(true);
    };

    const confirmDelete = async () => {
        try {
          let accessToken = localStorage.getItem('accessToken');
            let headersList = {
              "Accept": "*/*",
              "Authorization": `Bearer ${accessToken}`
            }
            const response = await axios.delete(`${baseurl}/api/admin/event/${deleteItemId}`, {
                    headers: headersList,
                  });
            if (response.status === 200) {
                toast.success(response.data.message);
                dispatch(newseventApi());
            } else {
                toast.error(response.data.message);
            }
            setModalOpen(false);
        } catch (error) {
            toast.error('Something went wrong. Please try again!!');
        } finally {
            setDeleteItemId(null);
            setModalOpen(false);
        }
      };

    const handleCheckboxChange = async (_id, newIsActive) => {
        try {
            let accessToken = localStorage.getItem('accessToken');

            let headersList = {
                "Accept": "*/*",
                "Authorization": `Bearer ${accessToken}`
            }

            let reqOptions = {
                url: `${baseurl}/api/admin/eventstatus`,
                method: "POST",
                headers: headersList,
                data: {
                    id: _id,
                    isActive: newIsActive,
                }
            }

            let response = await axios.request(reqOptions);
            toast.success(response.data?.message);
            dispatch(newseventApi());
        } catch (error) {
            toast.error(error.response?.data?.message || "An error occurred");
        }
    };

    const toComponentB = (data, event) => {
        event.preventDefault();
        navigate("/add-news-event", { state: { data } });
    };

    return (
        <>
            <div className="main-container">
                <div className="xs-pd-20-10 ">
                    <div className='d-flex justify-content-between'>
                        <div className="title mb-3">
                            <h2 className="h3 mb-0 mt-3">News Event</h2>
                        </div>
                        <Link to="/add-news-event">
                            <button className="text-white h5 btn-sm btn-primary mt-2">
                                <i className="icon-copy fi-plus mx-2" />
                                Add News Event
                            </button>
                        </Link>
                    </div>
                    <div className="card-box mb-30">
                        {Loading ? (
                            <Loader />
                        ) : (
                            <div className="pb-20 pd-20 table-responsive">
                                <table className="table-striped hover nowrap table-bordered w-100" id="myTable">
                                    <thead>
                                        <tr>
                                            <th className="p-2 text-center">Position</th>
                                            <th className="p-2 text-center">Title</th>
                                            <th className="p-2 text-center">Date</th>
                                            <th className="p-2 text-center">Image</th>
                                            <th className="p-2 text-center">Is Active</th>
                                            <th className="p-2 text-center">Action</th>
                                        </tr>
                                    </thead>
                                    <tbody className="text-center">
                                        {newsevent && newsevent.length > 0 ? (
                                            newsevent.map((data, id) => (
                                                <tr key={id}>
                                                    <td>{data.sortOrder}</td>
                                                    <td>{data.title}</td>
                                                    <td>{new Date(data.eventDate).toLocaleDateString('en-US', {
                                                        year: 'numeric',
                                                        month: '2-digit',
                                                        day: '2-digit',
                                                    })}</td>
                                                    <td>
                                                        <img
                                                            src={ImageLink(data.event_image)}
                                                            alt=""
                                                            width="50"
                                                            height="50"
                                                        />
                                                    </td>
                                                    <td>
                                                        <div className="custom-control custom-switch">
                                                            <input
                                                                type="checkbox"
                                                                className="custom-control-input"
                                                                id={`customSwitch_${id}`}
                                                                checked={data.isActive}
                                                                onChange={() =>
                                                                    handleCheckboxChange(data?._id, !data.isActive)
                                                                }
                                                            />
                                                            <label
                                                                className="custom-control-label"
                                                                htmlFor={`customSwitch_${id}`}></label>
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <button type="Submit" className="btn-sm btn-outline-info mx-2"
                                                            onClick={(event) => {
                                                                toComponentB(data, event);
                                                            }}
                                                        >
                                                            <i className="dw dw-edit2 mx-2 "></i>Edit
                                                        </button>
                                                        <button type="Submit" className="btn-sm btn-outline-danger mx-2" onClick={() => handleDelete(data._id)}>
                                                            <i className="dw dw-delete-3 mx-e"></i> Delete
                                                        </button>
                                                    </td>
                                                </tr>
                                            ))
                                        ) : (
                                            <tr>
                                                <td colSpan="8" className="text-center py-2">No data found</td>
                                            </tr>
                                        )}
                                    </tbody>
                                </table>

                            </div>
                        )}
                    </div>
                </div>
            </div>
            <DeleteConfirmationModal
        isOpen={modalOpen}
        toggle={() => setModalOpen(!modalOpen)}
        onConfirm={confirmDelete}
        itemName="event"
      />
        </>
    );
};



export default NewsEvent

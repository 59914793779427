import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { homevideo } from "../Redux/getHomeVideoSlice";
import { useDispatch, useSelector } from "react-redux";
import ReactPlayer from "react-player";
import { ImageLink } from "../constants";

const HomeVideoForm = () => {
  const baseurl = process.env.REACT_APP_API_BASE_URL;
  const location = useLocation();
  const dispatch = useDispatch();
  const homevideos = useSelector(
    (state) => state.homevideo.data?.document || []
  );
  const navigate = useNavigate();
  const data = location.state?.data;
  const isEditing = data && data._id;
  const [loading, setLoading] = useState(false);
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
    watch,
    setError,
    clearErrors,
  } = useForm({});
  const [previewImage, setPreviewImage] = useState(
    ImageLink(location.state?.data?.video)
  );

  const handleFileChange = (event, setImagePreview) => {
    const file = event.target.files[0];
    const reader = new FileReader();
    reader.onloadend = () => {
      setImagePreview(reader.result);
    };
    if (file) {
      reader.readAsDataURL(file);
    }
  };

  useEffect(() => {
    reset(data);
    setPreviewImage(ImageLink(data?.video));
  }, [location.state?.data, data, reset]);

  const videoCode = watch("videoCode");
  const video = watch("video");

  const validateVideoFields = () => {
    if (!videoCode && (!video || video.length === 0)) {
      setError("videoCode", {
        type: "manual",
        message: "Please provide either a video link or a video file.",
      });
      setError("video", {
        type: "manual",
        message: "Please provide either a video link or a video file.",
      });
      return false;
    }
    clearErrors("videoCode");
    clearErrors("video");
    return true;
  };

  const onSubmit = async (formData) => {
    if (!validateVideoFields()) {
      return;
    }

    try {
      setLoading(true);
      let accessToken = localStorage.getItem("accessToken");
      const headersList = {
        Accept: "application/json",
        Authorization: `Bearer ${accessToken}`,
      };

      const data = new FormData();
      data.append("sortOrder", formData.sortOrder);
      data.append("isActive", formData.isActive);
      if (formData.videoCode) {
        data.append("videoCode", formData.videoCode);
      }

      if (formData.video[0] instanceof File) {
        data.append("video", formData.video[0]);
      } else {
        const blob = await fetch(previewImage).then((res) => res.blob());
        data.append("video", blob, "previewVideo.mp4");
      }

      if (isEditing) {
        data.append("id", isEditing);
      }

      const response = await fetch(`${baseurl}/api/admin/video`, {
        method: "POST",
        headers: headersList,
        body: data,
      });
      const responseData = await response.json();
      if (responseData.status === 1) {
        toast.success(responseData.message);
        reset();
        navigate("/videos");
      } else {
        toast.error(responseData.originalError);
      }
    } catch (error) {
      toast.error("Error submitting form. Please try again.");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    dispatch(homevideo());
  }, [dispatch]);

  return (
    <>
      <div className="main-container">
        <div className="xs-pd-20-10 pd-ltr-20 col-md-12">
          <div className="title pb-20">
            <h2 className="h3 mb-0">Add Video </h2>
          </div>
          <div className="row">
            <div className="col-md-12 col-sm-12 mb-30">
              <div className="pd-20 card-box height-100-p">
                <div className="">
                  <form onSubmit={handleSubmit(onSubmit)}>
                    <div className="form-group row">
                      <label className="col-sm-12 col-md-2 col-form-label">
                        Video Link<span className="text-danger">*</span>
                      </label>
                      <div className="col-md-10 mb-4">
                        <input
                          className={`form-control ${errors.videoCode ? "is-invalid" : ""
                            }`}
                          {...register("videoCode")}
                          placeholder="Video link"
                          type="text"
                        />
                        {errors.videoCode && (
                          <small className="text-danger">
                            {errors.videoCode.message}
                          </small>
                        )}
                        {data?.videoCode && (
                          <div className="mt-2 mb-2">
                            <ReactPlayer
                              url={data?.videoCode}
                              width="25%"
                              height={300}
                            />
                          </div>
                        )}
                      </div>

                      <label className="col-sm-12 col-md-2 mb-4">
                        Video File<span className="text-danger">*</span>
                      </label>
                      <div className="col-md-10 mb-4">
                        <input
                          className={`form-control ${errors.video ? "is-invalid" : ""
                            }`}
                          {...register("video")}
                          type="file"
                          onChange={(event) =>
                            handleFileChange(event, setPreviewImage)
                          }
                        />
                        {errors.video && (
                          <small className="text-danger">
                            {errors.video.message}
                          </small>
                        )}
                        {previewImage && (
                          <video
                            controls
                            width="25%"
                            loop
                            autoPlay
                            muted
                            className="mt-5"
                          >
                            <source
                              src={previewImage}
                              type="video/mp4"
                              style={{ maxWidth: "100%" }}
                            />
                          </video>
                        )}
                      </div>

                      <label className="col-sm-12 col-md-2 col-form-label mb-4">
                        Sort Order<span className="text-danger">*</span>
                      </label>
                      <div className="col-md-10 mb-4">
                        <input
                          className={`form-control ${errors.sortOrder ? "is-invalid" : ""
                            }`}
                          {...register("sortOrder", { required: true })}
                          type="text"
                          defaultValue={
                            data && data.sortOrder
                              ? data.sortOrder
                              : homevideos.length + 1
                          }
                        />
                        {errors.sortOrder && (
                          <small className="text-danger">
                            Sort order is required
                          </small>
                        )}
                      </div>
                    </div>
                    <div className="form-group row mb-4">
                      <label className="col-sm-12 col-md-2 col-form-label">
                        Is Active
                      </label>
                      <div className="col-md-10">
                        <div className="custom-control custom-switch">
                          <input
                            type="checkbox"
                            className="custom-control-input"
                            id="customSwitch3"
                            {...register("isActive")}
                          />
                          <label
                            className="custom-control-label"
                            htmlFor="customSwitch3"
                          ></label>
                        </div>
                      </div>
                    </div>

                    <div className="modal-footer justify-content-center">
                      <Link to="/videos">
                        <button
                          type="button"
                          className="btn-sm btn-secondary"
                          data-dismiss="modal"
                        >
                          Back
                        </button>
                      </Link>
                      <button
                        type="submit"
                        className="btn-sm custom-btn"
                        disabled={loading}
                      >
                        Save
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default HomeVideoForm;
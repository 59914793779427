import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import axios from "axios";
import { CommonEditor } from "../component/CommonEditor";

const AddContent = () => {
  const baseurl = process.env.REACT_APP_API_BASE_URL;
  const location = useLocation();
  const navigate = useNavigate();
  const data = location.state?.data || {};
  const isEditing = data._id || "";
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    setValue,
  } = useForm({});

  useEffect(() => {
    reset(location.state?.data);
  }, [location.state?.data, reset]);

  const [loading, setLoading] = useState(false);

  const onSubmit = async (data) => {
    let accessToken = localStorage.getItem("accessToken");
    let headersList = {
      Accept: "*/*",
      Authorization: `Bearer ${accessToken}`,
    };

    let bodyContent = {
      title: data.title,
      subTitle: data.subTitle,
      description: data.description,
      sortOrder: data.sortOrder,
      isActive: data.isActive,
      showInHome: data.showInHome,
    };
    if (isEditing) {
      bodyContent.id = data._id;
    }
    setLoading(true);
    let reqOptions = {
      url: `${baseurl}/api/admin/content`,
      method: "POST",
      headers: headersList,
      data: bodyContent,
    };

    let response = await axios.request(reqOptions);
    if (response.data.status === 1) {
      toast.success(response.data.message);
      reset();
      navigate("/content");
    } else {
      toast.error(response.originalError);
    }
    setLoading(false);
  };

  return (
    <>
      <div className="main-container">
        <div className="xs-pd-20-10 pd-ltr-20 col-md-12">
          <div className="title pb-20">
            <h2 className="h3 mb-0">Add Content</h2>
          </div>
          <div className="row">
            <div className="col-md-12 col-sm-12 mb-30">
              <div className="pd-20 card-box height-100-p">
                <div className="pd-20  ">
                  <form onSubmit={handleSubmit(onSubmit)}>
                    <div>
                      <div className=" form-group row">
                        <label className="col-sm-12 col-md-2 col-form-label ">
                          Sort Order<span className="text-danger">*</span>
                        </label>
                        <div className="col-md-10 ">
                          <input
                            className={`form-control ${errors.sortOrder ? "is-invalid" : ""
                              }`}
                            {...register("sortOrder", { required: true })}
                            placeholder="Enter sort order"
                            type="number"
                          />
                          {errors.sortOrder && (
                            <small className="text-danger">
                              Sort order is required
                            </small>
                          )}
                        </div>
                      </div>
                      <div className=" form-group row">
                        <label className="col-sm-12 col-md-2 col-form-label ">
                          Title
                        </label>
                        <div className="col-md-10">
                          <input
                            className={`form-control `}
                            {...register("title")}
                            placeholder="Enter title"
                            type="text"
                          />
                        </div>
                      </div>
                      <div className=" form-group row">
                        <label className="col-sm-12 col-md-2 col-form-label ">
                          Sub Title
                        </label>
                        <div className="col-md-10 ">
                          <input
                            className={`form-control`}
                            {...register("subTitle")}
                            placeholder="Enter sub title"
                            type="text"
                          />
                        </div>
                      </div>
                      <div className=" form-group row">
                        <label className="col-sm-12 col-md-2 col-form-label ">
                          Description<span className="text-danger">*</span>
                        </label>
                        <div className="col-md-10 ">
                          <CommonEditor
                            value={data.description}
                            className={`form-control ${errors.description ? 'is-invalid' : ''}`}
                            {...register("description", { required: 'Description is required' })}
                            placeholder="Enter description ..."
                            onChange={(value) => setValue("description", value, { shouldValidate: true })}
                          />
                          {errors.description && (
                            <small className="text-danger">
                              {errors.description.message}
                            </small>
                          )}
                        </div>
                      </div>
                    </div>

                    <div className="form-group row ">
                      <label className="col-sm-12 col-md-2 col-form-label">
                        Is Active
                      </label>
                      <div className="col-md-10">
                        <div className="custom-control custom-switch">
                          <input
                            type="checkbox"
                            className="custom-control-input"
                            id="customSwitch3"
                            {...register("isActive")}
                          />
                          <label
                            className="custom-control-label"
                            htmlFor="customSwitch3"
                          ></label>
                        </div>
                      </div>
                    </div>

                    <div className="form-group row">
                      <label className="col-sm-12 col-md-2 col-form-label">
                        Show In Home
                      </label>
                      <div className="col-md-10">
                        <div className="custom-control custom-switch">
                          <input
                            type="checkbox"
                            className="custom-control-input"
                            id="customSwitch2"
                            {...register("showInHome")}
                          />
                          <label
                            className="custom-control-label"
                            htmlFor="customSwitch2"
                          ></label>
                        </div>
                      </div>
                    </div>

                    <div className="modal-footer justify-content-center">
                      <Link to="/content">
                        <button
                          type="button"
                          className="btn-sm btn-secondary"
                          data-dismiss="modal"
                        >
                          Back
                        </button>
                      </Link>
                      <button
                        type="submit"
                        className="btn-sm custom-btn"
                        disabled={loading}
                      >
                        {loading ? "Loading..." : "Save"}
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AddContent;

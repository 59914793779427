import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import { boximage } from "../Redux/getBoxImageSlice";
import { ImageLink } from "../constants";

const getFileExtension = (url) => {
  const path = new URL(url).pathname;
  const extension = path.split(".").pop();
  return extension;
};

const MiddleImageForm = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const url = process.env.REACT_APP_API_BASE_URL;
  const boximages = useSelector(
    (state) => state.getboximage.data?.document || []
  );
  const data = location.state?.data;
  const isEditing = data && data._id;

  const [loading, setLoading] = useState(false);
  const [previewImage, setImagePreview] = useState("");
  const [fileExtension, setFileExtension] = useState("");

  const handleFileChange = (event, setPreviewImage) => {
    const file = event.target.files[0];
    if (file) {
      const extension = file.name.split(".").pop();
      setFileExtension(extension);
      const reader = new FileReader();
      reader.onloadend = () => {
        setPreviewImage(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm({});

  useEffect(() => {
    reset(data);
    setImagePreview(ImageLink(data?.box_image));
  }, [data, reset]);

  const onSubmit = async (data) => {
    setLoading(true);
    const formData = new FormData();
    formData.append("sortOrder", data.sortOrder);
    formData.append("title", data.title);
    formData.append("isActive", data.isActive);
    formData.append("url", data.url);

    if (data.box_image instanceof File) {
      formData.append("box_image", data.box_image);
    } else if (previewImage) {
      const extension = fileExtension || getFileExtension(previewImage);
      const blob = await fetch(previewImage).then((res) => res.blob());
      formData.append("box_image", blob, `previewImage.${extension}`);
    }
    
    if (isEditing) {
      formData.append("id", isEditing);
    }
    try {
      let accessToken = localStorage.getItem("accessToken");
      let headersList = {
        Accept: "*/*",
        Authorization: `Bearer ${accessToken}`,
      };
      const response = await fetch(`${url}/api/admin/boximage`, {
        method: "POST",
        body: formData,
        headers: headersList,
      });
      const responseData = await response.json();
      if (responseData.status === 1) {
        toast.success(responseData.message);
        reset();
        navigate("/box-images");
      } else {
        toast.error(responseData.error);
      }
    } catch (error) {
      toast.error("Error submitting form. Please try again.");
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    dispatch(boximage());
  }, [dispatch]);

  return (
    <>
      <div className="main-container">
        <div className="xs-pd-20-10 pd-ltr-20 col-md-12">
          <div className="title pb-20">
            <h2 className="h3 mb-0">Add Box Image </h2>
          </div>
          <div className="row">
            <div className="col-md-12 col-sm-12 mb-30">
              <div className="pd-20 card-box height-100-p">
                <div className="pd-20  ">
                  <form onSubmit={handleSubmit(onSubmit)}>
                    <div className="form-group row">
                      <label className="col-sm-12 col-md-2 col-form-label mb-4">
                        Title<span className="text-danger">*</span>
                      </label>
                      <div className="col-md-10 mb-4">
                        <input
                          className={`form-control ${errors.title ? "is-invalid" : ""
                            }`}
                          {...register("title", { required: true })}
                          placeholder="Enter image title"
                          type="text"
                        />
                        {errors.title && (
                          <small className="text-danger">
                            Title is required
                          </small>
                        )}
                      </div>

                      <label className="col-sm-12 col-md-2 col-form-label mb-4">
                        Sort Order<span className="text-danger">*</span>
                      </label>
                      <div className="col-md-10 mb-4">
                        <input
                          className={`form-control ${errors.sortorder ? "is-invalid" : ""
                            }`}
                          {...register("sortOrder", { required: true })}
                          type="text"
                          defaultValue={
                            data && data.sortOrder
                              ? data && data.sortOrder
                              : boximages.length + 1
                          }
                        />
                        {errors.sortOrder && (
                          <small className="text-danger">
                            Sort Order is required
                          </small>
                        )}
                      </div>

                      <label className="col-sm-12 col-md-2 col-form-label mb-4">
                      Link<span className="text-danger">*</span>
                      </label>
                      <div className="col-md-10 mb-4">
                        <input
                          className={`form-control ${errors.url ? "is-invalid" : ""
                            }`}
                            placeholder="Enter link"
                          {...register("url", { required: true })}
                          type="text"
                        />
                        {errors.url && (
                          <small className="text-danger">
                           Link is required
                          </small>
                        )}
                      </div>

                      <label className="col-sm-12 col-md-2 mb-4 col-form-label">
                        Box Images<span className="text-danger">*</span>
                      </label>
                      <div className="col-md-10 mb-4">
                        <input
                          className={`form-control ${!isEditing && errors.box_image ? "is-invalid" : ""
                            }
                          `}
                          {...register("box_image", {
                            required: !isEditing ? true : false,
                          })}
                          type="file"
                          onChange={(event) =>
                            handleFileChange(event, setImagePreview)
                          }
                        />
                        {previewImage && (
                          <div className="mb-3 mt-3">
                            <img
                              src={previewImage}
                              alt="Preview"
                              className="preview-image"
                              style={{ maxWidth: "25%" }}
                            />
                          </div>
                        )}
                        {!isEditing && errors.box_image && (
                          <small className="text-danger">
                            Box image is required
                          </small>
                        )}
                        <small className="form-text text-muted">
                          Required image size: 506 x  532 || 378 X 398 || 760 X
                          383px (.png, .jpg, .jpeg, .webp)
                        </small>
                      </div>
                    </div>
                    <div className="form-group row mb-4">
                      <label className="col-sm-12 col-md-2 col-form-label">
                        Is Active
                      </label>
                      <div className="col-md-10">
                        <div className="custom-control custom-switch">
                          <input
                            type="checkbox"
                            className="custom-control-input"
                            id="customSwitch3"
                            {...register("isActive")}
                          />
                          <label
                            className="custom-control-label"
                            htmlFor="customSwitch3"
                          ></label>
                        </div>
                      </div>
                    </div>

                    <div className="modal-footer justify-content-center">
                      <Link to="/box-images">
                        <button
                          className="btn-sm btn-secondary"
                          data-dismiss="modal"
                        >
                          Back
                        </button>
                      </Link>

                      <button
                        type="submit"
                        className="btn-sm custom-btn"
                        disabled={loading}
                      >
                        {loading ? "Loading..." : "Save"}
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default MiddleImageForm;

import DataTable from "datatables.net-dt";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import Loader from "../Layout/Loader";
import { appointmentApi } from "../Redux/appointmentSlice";

const BookAppointment = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const appointment = useSelector((state) => state.appointment.user?.document);
    const [loading, setLoading] = useState(false)
    const Loading = useSelector((state) => state.appointment.loading);

    useEffect(() => {
        if (appointment && appointment.length > 0) {
            const table = new DataTable("#myTable", {
                pageLength: appointment?.length,
                lengthChange: false,
                autoWidth: true,
                paging: false,
                info: false,
                searching: false,
                columnDefs: [
                    { targets: 4, orderable: false },
                    { targets: 5, orderable: false }
                ]
            });
            return () => {
                table.destroy();
            };
        }
    }, [appointment]);

    useEffect(() => {
        setLoading(true)
        dispatch(appointmentApi());
        setLoading(false)
    }, [dispatch]);

    const toComponentB = (data, event) => {
        event.preventDefault();
        navigate("/appointment-booking-detail", { state: { data } });
    };

    return (
        <>
            <div className="main-container">
                <div className="xs-pd-20-10 pd-ltr-20">
                    <div className="title pb-20">
                        <h2 className="h3 mb-0"> Appointment Booking</h2>
                    </div>

                    <div className="card-box mb-30">
                        {Loading || loading === true ? (
                            <Loader />
                        ) : (
                            <div className="pb-20 pd-20 table-responsive">
                                <table className="table-striped hover nowrap table-bordered w-100" id="myTable">
                                    <thead>
                                        <tr>
                                            <th className="p-2 text-center">Name</th>
                                            <th className="p-2 text-center">Mobile No</th>
                                            <th className="p-2 text-center">Date</th>
                                            <th className="p-2 text-center">Time</th>
                                            <th className="p-2 text-center">Type</th>
                                            <th className="p-2 text-center">Action</th>
                                        </tr>
                                    </thead>
                                    <tbody className="text-center">
                                        {appointment && appointment.length > 0 ? (
                                            appointment.map((data, index) => (
                                                <tr key={index}>
                                                    <td>{data.name}</td>
                                                    <td>{data.mobile_no}</td>
                                                    <td>{new Date(data.date).toLocaleDateString('en-US', {
                                                        year: 'numeric',
                                                        month: '2-digit',
                                                        day: '2-digit',
                                                    })}</td>
                                                    <td>{data.time}</td>
                                                    <td>{data.type === 1 ? "Store visit" : data.type === 2 ? "Video call" : ""}</td>
                                                    <td>
                                                        <button type="Submit" className="btn-sm btn-outline-info mx-2" onClick={(event) => {
                                                            toComponentB(data, event);
                                                        }}
                                                        >
                                                            <i className="dw dw-eye mx-2" />View
                                                        </button>                                                     
                                                    </td>
                                                </tr>
                                            ))
                                        ) : (
                                            <tr>
                                                <td colSpan="6" className="text-center py-2">No data found</td>
                                            </tr>
                                        )}
                                    </tbody>
                                </table>
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </>
    );
};



export default BookAppointment

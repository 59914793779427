import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { categoryData } from "../Redux/getCategorySlice";
import { toast } from "react-toastify";
import axios from "axios";
import Loader from "../Layout/Loader";
import { ImageLink } from "../constants";
import DataTable from "datatables.net-dt";
import DeleteConfirmationModal from "../component/DeleteConfirmationModal";

const Category = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const baseurl = process.env.REACT_APP_API_BASE_URL;
  const data = useSelector((state) => state.category?.data?.data);
  const Loading = useSelector((state) => state.category?.isLoading);
  const [deleteItemId, setDeleteItemId] = useState(null);
  const [modalOpen, setModalOpen] = useState(false);
  useEffect(() => {
    if (data && data.length > 0) {
      const table = new DataTable("#myTable", {
        pageLength: data.length,
        lengthChange: false,
        autoWidth: true,
        paging: false,
        info: false,
        searching: false,
        columnDefs: [
          { targets: 2, orderable: false },
          { targets: 3, orderable: false },
          { targets: 4, orderable: false },
          { targets: 5, orderable: false },
          { targets: 6, orderable: false },
        ],
      });
      return () => {
        table.destroy();
      };
    }
  }, [data]);

  useEffect(() => {
    dispatch(categoryData());
  }, [dispatch]);
  
  const handleDelete = async (id) => {
    setDeleteItemId(id);
    setModalOpen(true);
};
const confirmDelete = async () => {
  try {
    let accessToken = localStorage.getItem('accessToken');
      let headersList = {
        "Accept": "*/*",
        "Authorization": `Bearer ${accessToken}`
      }
      const response = await axios.delete(`${baseurl}/api/admin/category/${deleteItemId}`, {
              headers: headersList,
            });
      if (response.status === 200) {
          toast.success(response.data.message);
          dispatch(categoryData());
      } else {
          toast.error(response.data.message);
      }
      setModalOpen(false);
  } catch (error) {
      toast.error('Something went wrong. Please try again!!');
  } finally {
      setDeleteItemId(null);
      setModalOpen(false);
  }
};

  const handleCheckboxChange = async (_id, newIsActive) => {
    try {
      let accessToken = localStorage.getItem("accessToken");
      let headersList = {
        "Accept": "*/*",
        Authorization: `Bearer ${accessToken}`,
      };

      let reqOptions = {
        url: `${baseurl}/api/admin/categorystatus`,
        method: "POST",
        headers: headersList,
        data: {
          id: _id,
          isActive: newIsActive,
        },
      };
      let response = await axios.request(reqOptions);
      toast.success(response.data?.message);
      dispatch(categoryData());
    } catch (error) {
      toast.error(error.response?.data?.message || "An error occurred");
    }
  };

  const toComponentB = (item) => {
    navigate("/add-category", { state: { item, _id: item._id } });
  };

  return (
    <>
      <div className="main-container">
        <div className="xs-pd-20-10 pd-ltr-20">
          <div className="d-flex justify-content-between">
            <div className="title mb-3">
              <h2 className="h3 mb-0 mt-3">Category</h2>
            </div>
            <Link to="/add-category">
              <button className="text-white h5 btn-sm btn-primary mt-2">
                <i className="icon-copy fi-plus mx-2" />
                Add Category
              </button>
            </Link>
          </div>
          <div className="card-box mb-30">
            {Loading ? (
              <Loader />
            ) : (
              <div className="pb-20 pd-20 table-responsive">
                <table
                  className="table-striped hover nowrap table-bordered w-100"
                  id="myTable"
                >
                  <thead>
                    <tr>
                      <th className="p-2 text-center">Position</th>
                      <th className="p-2 text-center">Name</th>
                      <th className="p-2 text-center">Image</th>
                      <th className="p-2 text-center">Sub Category</th>
                      <th className="p-2 text-center">Products</th>
                      <th className="p-2 text-center">Is Active</th>
                      <th className="p-2 text-center">Action</th>
                    </tr>
                  </thead>
                  <tbody className="text-center">
                    {data && data?.length > 0 ? (
                      data?.map((item, id) => (
                        <tr key={id}>
                          <td className="p-3">{item.sortOrder}</td>
                          <td>{item.name}</td>
                          <td>
                            <img
                              src={ImageLink(item.menu_image)}
                              alt=""
                              width={50}
                            />
                          </td>
                          <td>
                            <button
                              className="btn-success btn-sm text-white"
                              onClick={() => {
                                navigate(`/${item._id}/sub-category`, {
                                  state: item,
                                  subcategoryCount: item?.subcategoryCount,
                                });
                              }}
                            >
                              {item.subcategoryCount}
                            </button>
                          </td>
                          <td>
                            <button
                              className="btn-sm btn-primary text-white"
                              onClick={() => {
                                navigate(`/${item._id}/products`, {
                                  state: item,
                                });
                              }}
                            >
                              {item.productCount}
                            </button>
                          </td>
                          <td>
                            <div className="custom-control custom-switch">
                              <input
                                type="checkbox"
                                className="custom-control-input"
                                id={`customSwitch_${id}`}
                                checked={item.isActive}
                                onChange={() =>
                                  handleCheckboxChange(item._id, !item.isActive)
                                }
                              />
                              <label
                                className="custom-control-label"
                                htmlFor={`customSwitch_${id}`}
                              ></label>
                            </div>
                          </td>
                          <td>
                            <button
                              type="Submit"
                              className="btn-sm btn-outline-info mx-2"
                              onClick={(e) => {
                                e.preventDefault();
                                toComponentB(item, item._id);
                              }}
                            >
                              <i className="dw dw-edit2 mx-2 "></i>Edit
                            </button>
                            <button
                              type="Submit"
                              className="btn-sm btn-outline-danger mx-2"
                              onClick={() =>
                                handleDelete(
                                  item._id,
                                  item.productCount,
                                  item.subCategoryCount
                                )
                              }
                            >
                              <i className="dw dw-delete-3 mx-e"></i> Delete
                            </button>
                          </td>
                        </tr>
                      ))
                    ) : (
                      <tr>
                        <td colSpan="7" className="text-center py-2">
                          No data found
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
            )}
          </div>
        </div>
      </div>
      <DeleteConfirmationModal
        isOpen={modalOpen}
        toggle={() => setModalOpen(!modalOpen)}
        onConfirm={confirmDelete}
        itemName="testimonial"
      />
    </>
  );
};

export default Category;

import React from "react";
import { Link, useLocation } from "react-router-dom";
import AdminSidebarSetting from "./AdminSidebarSetting";

const Sidebar = () => {
  const location = useLocation().pathname;
  return (
    <>
      <header
        className={
          location === "/" || location === "/forgot-password" ? "d-none" : ""
        }
      >
        <div className="header">
          <AdminSidebarSetting />
        </div>
        <div className="left-side-bar">
          <div className="brand-logo">
            <Link to="https://kmchoksi.in/" target="_blank">
              <img
                src="/logo.png"
                alt=""
                className="dark-logo mx-4"
                width={130}
              />
            </Link>

            <div className="close-sidebar" data-toggle="left-sidebar-close">
              <i className="ion-close-round" />
            </div>
          </div>
          <div className="menu-block customscroll">
            <div className="sidebar-menu">
              <ul id="accordion-menu">
                <li>
                  <Link to="/dashboard" className="dropdown-toggle no-arrow">
                    <span className="micon bi bi-speedometer2" />
                    <span className="mtext">Dashboard</span>
                  </Link>
                </li>
                <li className="dropdown">
                  <Link to="#" className="dropdown-toggle">
                    <span className="micon bi bi-file-earmark-fill" />
                    <span className="mtext">Master</span>
                  </Link>
                  <ul className="submenu">
                    <li>
                      <Link to="/menu">Menu</Link>
                    </li>
                    <li>
                      <Link to="/news-event">News Event</Link>
                    </li>
                    <li>
                      <Link to="/testimonials">Testimonials</Link>
                    </li>
                    <li>
                      <Link to="/store-locator">Store Locator</Link>
                    </li>
                    <li>
                      <Link to="/content">Content</Link>
                    </li>
                    <li>
                      <Link to="/page">Page</Link>
                    </li>
                    <li>
                      <Link to="/career-post">Career Post</Link>
                    </li>
                  </ul>
                </li>

                <li className="dropdown">
                  <Link to="#" className="dropdown-toggle">
                    <span className="micon bi bi-x-diamond-fill" />
                    <span className="mtext">Products</span>
                  </Link>
                  <ul className="submenu">
                    <li>
                      <Link to="/category">Category</Link>
                    </li>
                    <li>
                      <Link to="/search-products">Search Products</Link>
                    </li>
                    <li>
                      <Link to="/import-export-products">
                        Import/Export
                      </Link>
                    </li>
                    <li>
                      <Link to="/collections">Collections</Link>
                    </li>
                    <li>
                      <Link to="/colors">Colors</Link>
                    </li>
                    <li>
                      <Link to="/metals">Metals</Link>
                    </li>
                    <li>
                      <Link to="/gold-purity">Gold Purity</Link>
                    </li>
                  </ul>
                </li>
                <li className="dropdown">
                  <Link to="#" className="dropdown-toggle">
                    <span className="micon bi bi-images" />
                    <span className="mtext"> Banners </span>
                  </Link>
                  <ul className="submenu">
                    <li>
                      <Link to="/home-slider">Home Slider</Link>
                    </li>
                    <li>
                      <Link to="/box-images">Box Images</Link>
                    </li>
                    <li>
                      <Link to="/videos">Videos</Link>
                    </li>
                  </ul>
                </li>

                <li className="dropdown">
                  <Link to="#" className="dropdown-toggle ">
                    <span className="micon bi bi-card-list" />
                    <span className="mtext">Reports</span>
                  </Link>
                  <ul className="submenu">
                    <li>
                      <Link to="/inquiry">Contact Inquiry </Link>
                    </li>
                    <li>
                      <Link to="/product-inquiry">Product Inquiry</Link>
                    </li>
                    <li>
                      <Link to="/appointment-booking">Appointment Booking</Link>
                    </li>
                    <li>
                      <Link to="/job-applications">Job Applications</Link>
                    </li>
                  </ul>
                </li>

                <li className="dropdown">
                  <Link to="#" className="dropdown-toggle">
                    <span className="micon bi bi-gear-fill" />
                    <span className="mtext"> Settings </span>
                  </Link>
                  <ul className="submenu">
                    <li>
                      <Link to="/logo">Logo</Link>
                    </li>
                    <li>
                      <Link to="/home-page">Home Page</Link>
                    </li>
                    <li>
                      <Link to="/social-media">Social Media </Link>
                    </li>
                    <li>
                      <Link to="/email-template">Email Template</Link>
                    </li>
                    <li>
                      <Link to="/back-office">Back Office</Link>
                    </li>
                    <li>
                      <Link to="/instagram">Instagram</Link>
                    </li>
                    <li>
                      <Link to="/google-review">Google Review</Link>
                    </li>
                    <li>
                      <Link to="/analytics-seo">Analytics & SEO</Link>
                    </li>
                    <li>
                      <Link to="/email-settings">Email Settings</Link>
                    </li>
                    <li>
                      <Link to="/sitemap">Sitemap</Link>
                    </li>
                  </ul>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div className="mobile-menu-overlay" />
      </header>
    </>
  );
};

export default Sidebar;

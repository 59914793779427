import React from "react";
import { Link, useLocation } from "react-router-dom";
import { ImageLink } from "../constants";

const CareerListDetails = () => {
    const location = useLocation();
    const data = location.state?.data || {};
    
    return (
        <>
            <div className="main-container">
                <div className="xs-pd-20-10 pd-ltr-20">
                    <div className="title pb-20">
                        <h2 className="h3 mb-0"> Career List Details</h2>
                    </div>
                    <div className="card-box mb-30">
                        <div className="pd-20 ">
                            <form>
                                <div className="row mb-4">
                                    <div className="col-md-2 ">
                                        <label htmlFor="validationCustom01" className="form-label">
                                            Name
                                        </label>
                                    </div>
                                    <div className="col-sm-8">
                                        <input
                                            type="text"
                                            id="validationCustom01"
                                            className="form-control"
                                            value={data.first_name + " " + data.last_name}
                                        />
                                    </div>
                                </div>
                                <div className="row mb-4">
                                    <div className="col-md-2 ">
                                        <label htmlFor="validationCustom01" className="form-label">
                                            Email
                                        </label>
                                    </div>
                                    <div className="col-sm-8">
                                        <input
                                            type="text"
                                            id="validationCustom01"
                                            className="form-control"
                                            value={data.email}
                                        />
                                    </div>
                                </div>
                                <div className="row mb-4">
                                    <div className="col-md-2 ">
                                        <label htmlFor="validationCustom01" className="form-label">
                                            Mobile No
                                        </label>
                                    </div>
                                    <div className="col-sm-8">
                                        <input
                                            type="text"
                                            id="validationCustom01"
                                            className="form-control"
                                            value={data.contact_no}
                                        />
                                    </div>
                                </div>
                                <div className="row mb-4">
                                    <div className="col-md-2 ">
                                        <label htmlFor="validationCustom01" className="form-label">
                                           Post
                                        </label>
                                    </div>
                                    <div className="col-sm-8">
                                        <input
                                            type="text"
                                            id="validationCustom01"
                                            className="form-control"
                                            value={data.post}
                                        />
                                    </div>
                                </div>
                                <div className="row mb-4">
                                    <div className="col-md-2 ">
                                        <label htmlFor="validationCustom01" className="form-label">
                                           Experiance
                                        </label>
                                    </div>
                                    <div className="col-sm-8">
                                        <input
                                            type="name"
                                            id="validationCustom01"
                                            className="form-control"
                                            value={data.experiance}
                                        />
                                    </div>
                                </div>
                                <div className="row mb-4">
                                    <div className="col-md-2 ">
                                        <label htmlFor="validationCustom01" className="form-label">
                                            Date
                                        </label>
                                    </div>
                                    <div className="col-sm-8">
                                        <input
                                            type="text"
                                            id="validationCustom01"
                                            className="form-control"
                                            value={new Date(data.createdAt).toLocaleDateString('en-US', {
                                                year: 'numeric',
                                                month: '2-digit',
                                                day: '2-digit',
                                            })}
                                        />
                                    </div>
                                </div>
                            
                                <div className="row mb-4">
                                    <div className="col-md-2">
                                        <label
                                            htmlFor="exampleFormControlInput1"
                                            className="form-label">
                                          Resume pdf
                                        </label>
                                    </div>
                                    <div className="col-sm-8">
                                    <Link
                                                to={data.apply_cv instanceof File ? URL.createObjectURL(data.apply_cv) : ImageLink(data.apply_cv)}
                                                target="_blank"
                                            >
                                                <img
                                                    src="../assets/images/pdf.png"
                                                    alt="pdf_preview"
                                                    className="ms-3 me-3 mb-3"
                                                    width={60}
                                                />
                                                {data.apply_cv}
                                            </Link>
                                    </div>
                                </div>

                                <div className="inquirylist text-left">
                                    <div>
                                        <Link to="/job-applications">
                                            <button
                                                type="button"
                                                className="btn-sm custom-btn"
                                                data-dismiss="modal">
                                                Back
                                            </button>
                                        </Link>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};



export default CareerListDetails

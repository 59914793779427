import React, { useCallback, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import axios from "axios";

const AddMenu = () => {
  const location = useLocation();
  const baseurl = process.env.REACT_APP_API_BASE_URL;
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({});
  const editData = location.state?._id;
  const [menuType, setMenuType] = useState(location?.state?.menuType || "");
  const navigate = useNavigate();
  const [cmsData, setCmsData] = useState([]);
  const [brandData, setBrandData] = useState([]);
  const [categoryData, setCategoryData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [selectCms, setSelectCms] = useState(location?.state?.CMS?._id || "");
  const [selectBrand, setSelectBrand] = useState(
    location?.state?.brand?._id || ""
  );
  const [selectCategory, setSelectCategory] = useState(
    location?.state?.category?._id || ""
  );

  useEffect(() => {
    reset(location.state);
  }, [location?.state, reset]);

  const handleMenuTypeChange = (e) => {
    const selectedMenuType = e.target.value;
    setMenuType(selectedMenuType);
  };

  const fetchCmsData = useCallback(async () => {
    try {
      setLoading(true);
      let accessToken = localStorage.getItem("accessToken");
      let headersList = {
        Accept: "*/*",
        Authorization: `Bearer ${accessToken}`,
      };
      const response = await axios.get(`${baseurl}/api/admin/pages`, {
        headers: headersList,
      });
      setCmsData(response.data.data);
    } catch (error) {
      toast.error("Error fetching CMS data:", error);
    } finally {
      setLoading(false);
    }
  }, [baseurl]);

  const fetchBrandData = useCallback(async () => {
    try {
      setLoading(true);
      let accessToken = localStorage.getItem("accessToken");
      let headersList = {
        Accept: "*/*",
        Authorization: `Bearer ${accessToken}`,
      };
      const response = await axios.get(`${baseurl}/api/brands`, {
        headers: headersList,
      });
      setBrandData(response.data.document);
    } catch (error) {
      toast.error("Error submitting form. Please try again.");
    } finally {
      setLoading(false);
    }
  }, [baseurl]);

  const fetchCategoryData = useCallback(async () => {
    try {
      setLoading(true);
      let accessToken = localStorage.getItem("accessToken");
      let headersList = {
        Accept: "*/*",
        Authorization: `Bearer ${accessToken}`,
      };
      const response = await axios.get(`${baseurl}/api/admin/getcategory`, {
        headers: headersList,
      });
      setCategoryData(response.data.document);
    } catch (error) {
      toast.error("Error submitting form. Please try again.");
    } finally {
      setLoading(false);
    }
  }, [baseurl]);

  useEffect(() => {
    fetchCmsData();
    fetchCategoryData();
    fetchBrandData();
  }, [fetchBrandData, fetchCategoryData, fetchCmsData]);

  const onSubmit = async (data) => {
    setLoading(true);
    try {
      let payload = {
        menuType,
        sortOrder: parseInt(data.sortOrder),
        name: data.name,
        menuUrl: data.menuUrl,
        isActive: data.isActive,
        showInHeader: data.showInHeader,
        showInFooter: data.showInFooter,
      };
      if (data.category) {
        payload.category = selectCategory;
      }
      if (data.brand) {
        payload.brand = selectBrand;
      }
      if (menuType === "CMS" && selectCms) {
        payload.CMS = selectCms;
      }
      if (editData) {
        payload.id = editData;
      }

      let accessToken = localStorage.getItem("accessToken");
      let headersList = {
        "Content-Type": "application/json",
        Authorization: `Bearer ${accessToken}`,
      };

      const response = await fetch(`${baseurl}/api/admin/menu`, {
        method: "POST",
        body: JSON.stringify(payload),
        headers: headersList,
      });

      const responseData = await response.json();
      if (responseData.status === 1) {
        toast.success(responseData.message);
        reset();
        navigate("/menu");
      } else {
        toast.error(responseData.originalError);
      }
    } catch (error) {
      toast.error("Error submitting form. Please try again.");
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <div className="main-container">
        <div className="xs-pd-20-10 pd-ltr-20 col-md-12">
          <div className="title pb-20">
            <h2 className="h3 mb-0">Menu</h2>
          </div>
          <div className="row">
            <div className="col-md-12 col-sm-12">
              <div className="pd-20 card-box height-100-p">
                {loading ? (
                  <div className="d-flex justify-content-center">
                    <div className="spinner-border" role="status">
                      <span className="sr-only">Loading...</span>
                    </div>
                  </div>
                ) : (
                  <div className="">
                    <form onSubmit={handleSubmit(onSubmit)}>
                      <div className="modal-body">
                        <div className="row">
                          <label className="col-sm-12 col-md-2">
                            Menu Type
                          </label>
                          <div className="col-md-4 col-sm-12">
                            <div className="form-group">
                              <select
                                className="form-control"
                                value={menuType}
                                onChange={handleMenuTypeChange}
                              >
                                <option value="">-- Select Menu Type --</option>
                                <option value="CMS">CMS</option>
                                <option value="Product">Product</option>
                                <option value="Brand">Brand</option>
                                <option value="Other">Other</option>
                              </select>
                            </div>
                          </div>
                          {menuType === "Product" && (
                            <>
                              <label className="col-sm-12 col-md-2 mb-4">
                                Select Category
                              </label>
                              <div className="col-md-4 col-sm-12">
                                <div className="form-group">
                                  <select
                                    name="category"
                                    className="form-control"
                                    value={selectCategory}
                                    onChange={(e) =>
                                      setSelectCategory(e.target.value)
                                    }
                                  >
                                    <option value="">
                                      -- Select Category --
                                    </option>
                                    {categoryData?.map((category) => (
                                      <option
                                        key={category._id}
                                        value={category._id}
                                      >
                                        {category.name}
                                      </option>
                                    ))}
                                  </select>
                                </div>
                              </div>
                            </>
                          )}
                          {menuType === "CMS" && (
                            <>
                              <label className="col-sm-12 col-md-2 mb-4">
                                Select CMS
                              </label>
                              <div className="col-md-4 col-sm-12">
                                <div className="form-group">
                                  <select
                                    name="CMS"
                                    className="form-control"
                                    value={selectCms}
                                    onChange={(e) =>
                                      setSelectCms(e.target.value)
                                    }
                                  >
                                    <option value="">-- Select CMS --</option>
                                    {cmsData?.map((cms) => (
                                      <option key={cms._id} value={cms._id}>
                                        {cms.title}
                                      </option>
                                    ))}
                                  </select>
                                </div>
                              </div>
                            </>
                          )}
                          {menuType === "Brand" && (
                            <>
                              <label className="col-sm-12 col-md-2 mb-4">
                                Select Brand
                              </label>
                              <div className="col-md-4 col-sm-12">
                                <div className="form-group">
                                  <select
                                    name="brand"
                                    className="form-control"
                                    value={selectBrand}
                                    onChange={(e) =>
                                      setSelectBrand(e.target.value)
                                    }
                                  >
                                    <option value="">-- Select Brand --</option>
                                    {brandData?.map((brand) => (
                                      <option key={brand._id} value={brand._id}>
                                        {brand.name}
                                      </option>
                                    ))}
                                  </select>
                                </div>
                              </div>
                            </>
                          )}
                          <label className="col-sm-12 col-md-2 mb-4">
                            Sort Order <span className="text-danger">*</span>
                          </label>
                          <div className="col-md-4 col-sm-12">
                            <div className="form-group">
                              <input
                                placeholder="Enter sort order"
                                className={`form-control ${errors.sortOrder ? "is-invalid" : ""
                                  }`}
                                type="number"
                                {...register("sortOrder", { required: true })}
                              />
                              {errors.sortOrder && (
                                <small className="text-danger">
                                  Sort order is required
                                </small>
                              )}
                            </div>
                          </div>
                          <label className="col-sm-12 col-md-2 mb-4">
                            Menu Name<span className="text-danger">*</span>
                          </label>
                          <div className="col-md-4 col-sm-12">
                            <div className="form-group">
                              <input
                                placeholder="Enter menu name"
                                type="text"
                                className={`form-control ${errors.name ? "is-invalid" : ""
                                  }`}
                                {...register("name", { required: true })}
                              />
                              {errors.name && (
                                <small className="text-danger">
                                  Menu Name is required
                                </small>
                              )}
                            </div>
                          </div>
                          <label className="col-sm-12 col-md-2 mb-4">
                            Menu URL<span className="text-danger">*</span>
                          </label>
                          <div className="col-md-4 col-sm-12">
                            <div className="form-group">
                              <input
                                type="text"
                                placeholder="Enter menu url"
                                className={`form-control ${errors.menuUrl ? "is-invalid" : ""
                                  }`}
                                {...register("menuUrl", { required: true })}
                              />
                              {errors.menuUrl && (
                                <small className="text-danger">
                                  Menu Url is required
                                </small>
                              )}
                            </div>
                          </div>
                          <label className="col-sm-12 col-md-2 col-form-label">
                            Show In Header
                          </label>
                          <div className="col-md-4">
                            <div className="custom-control custom-switch">
                              <input
                                type="checkbox"
                                className="custom-control-input"
                                id="customSwitch1"
                                {...register("showInHeader")}
                              />
                              <label
                                className="custom-control-label"
                                htmlFor="customSwitch1"
                              ></label>
                            </div>
                          </div>
                          <label className="col-sm-12 col-md-2 col-form-label">
                            Show In Footer
                          </label>
                          <div className="col-md-4">
                            <div className="custom-control custom-switch">
                              <input
                                type="checkbox"
                                className="custom-control-input"
                                id="customSwitch2"
                                {...register("showInFooter")}
                              />
                              <label
                                className="custom-control-label"
                                htmlFor="customSwitch2"
                              ></label>
                            </div>
                          </div>
                          <label className="col-sm-12 col-md-2 col-form-label">
                            Is Active
                          </label>
                          <div className="col-md-4">
                            <div className="custom-control custom-switch">
                              <input
                                type="checkbox"
                                className="custom-control-input"
                                id="customSwitch"
                                {...register("isActive")}
                              />
                              <label
                                className="custom-control-label"
                                htmlFor="customSwitch"
                              ></label>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="modal-footer justify-content-center">
                        <Link to="/menu">
                          <button
                            type="button"
                            className="btn-sm btn-secondary"
                            data-dismiss="modal"
                          >
                            Back
                          </button>
                        </Link>
                        <button type="Submit" className="btn-sm custom-btn">
                          Save
                        </button>
                      </div>
                    </form>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AddMenu;

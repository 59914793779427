import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import axios from "axios";
import Loader from "../Layout/Loader";
import DataTable from "datatables.net-dt";
import { useDispatch, useSelector } from "react-redux";
import { menuApi } from "../Redux/menuSlice";
import DeleteConfirmationModal from "../component/DeleteConfirmationModal";

const Menu = () => {
  const baseurl = process.env.REACT_APP_API_BASE_URL;
  const navigate = useNavigate();
  const menu = useSelector((state) => state.menu?.user?.document || []);
  const loading = useSelector((state) => state.menu?.loading);
  const dispatch = useDispatch();

  const [deleteItemId, setDeleteItemId] = useState(null);
  const [modalOpen, setModalOpen] = useState(false);
  useEffect(() => {
    dispatch(menuApi());
  }, [dispatch]);

  useEffect(() => {
    if (menu && menu.length > 0) {
      const table = new DataTable("#myTable", {
        pageLength: menu.length,
        lengthChange: false,
        autoWidth: true,
        paging: false,
        info: false,
        searching: false,
        columnDefs: [
          { targets: 3, orderable: false },
          { targets: 4, orderable: false },
        ],
      });
      return () => {
        table.destroy();
      };
    }
  }, [menu, loading]);

  const handleDelete = async (id) => {
    setDeleteItemId(id);
    setModalOpen(true);
};


const confirmDelete = async () => {
  try {
    let accessToken = localStorage.getItem('accessToken');
      let headersList = {
        "Accept": "*/*",
        "Authorization": `Bearer ${accessToken}`
      }
      const response = await axios.delete(`${baseurl}/api/admin/menu/${deleteItemId}`, {
              headers: headersList,
            });
      if (response.status === 200) {
          toast.success(response.data.message);
          dispatch(menuApi());
      } else {
          toast.error(response.data.message);
      }
      setModalOpen(false);
  } catch (error) {
      toast.error('Something went wrong. Please try again!!');
  } finally {
      setDeleteItemId(null);
      setModalOpen(false);
  }
};

  const handleCheckboxChange = async (_id, newIsActive) => {
    try {
      const accessToken = localStorage.getItem("accessToken");
      const headersList = {
        Accept: "application/json",
        Authorization: `Bearer ${accessToken}`,
      };
      const reqOptions = {
        url: `${baseurl}/api/admin/menustatus`,
        method: "POST",
        headers: headersList,
        data: {
          id: _id,
          isActive: newIsActive,
        },
      };
      const response = await axios.request(reqOptions);
      if (response.status === 200) {
        toast.success(response?.data?.message);
        dispatch(menuApi());
      }
    } catch (error) {
      toast.error(error.response?.data?.message || "An error occurred");
    }
  };

  return (
    <>
    <div className="main-container">
      <div className="xs-pd-20-10">
        <div className="d-flex justify-content-between">
          <div className="title mb-3">
            <h2 className="h3 mb-0 mt-3">Menu</h2>
          </div>
          <Link to="/add-menu">
            <button className="text-white h5 btn-sm btn-primary mt-2">
              <i className="icon-copy fi-plus mx-2" />
              Add Menu
            </button>
          </Link>
        </div>
        <div className="card-box">
          {loading ? (
            <Loader />
          ) : (
            <div className="pb-20 pd-20 table-responsive">
              <table
                className="table-striped hover nowrap table-bordered w-100"
                id="myTable"
              >
                <thead>
                  <tr>
                    <th className="p-2 text-center">Position</th>
                    <th className="p-2 text-center">Type</th>
                    <th className="p-2 text-center">Menu Name</th>
                    <th className="p-2 text-center">Sub Menu</th>
                    <th className="p-2 text-center">Is Active</th>
                    <th className="p-2 text-center">Action</th>
                  </tr>
                </thead>
                <tbody className="text-center">
                  {menu?.length > 0 ? (
                    menu?.map((item, id) => {
                      return (
                        <tr key={item?._id}>
                          <td>{item?.sortOrder}</td>
                          <td>{item?.menuType}</td>
                          <td>{item?.name}</td>
                          <td>
                            <button
                              className="btn-sm btn-primary text-white"
                              onClick={() => {
                                navigate(`/menu/${item._id}`, {
                                  state: item,
                                });
                              }}
                            >
                              {item?.subMenus?.length}
                            </button>
                          </td>
                          <td>
                            <span className="custom-control custom-switch">
                              <input
                                type="checkbox"
                                className="custom-control-input"
                                id={`customSwitch_${id}`}
                                checked={item?.isActive}
                                onChange={() =>
                                  handleCheckboxChange(
                                    item?._id,
                                    !item?.isActive
                                  )
                                }
                              />
                              <label
                                className="custom-control-label"
                                htmlFor={`customSwitch_${id}`}
                              />
                            </span>
                          </td>
                          <td>
                            <button
                              type="Submit"
                              className="btn-sm btn-outline-info mx-2"
                              onClick={() =>
                                navigate("/add-menu", { state: item })
                              }
                            >
                              <i className="dw dw-edit2 mx-2 "></i>Edit
                            </button>
                            <button
                              type="Submit"
                              className="btn-sm btn-outline-danger mx-2"
                              onClick={() => handleDelete(item?._id)}
                            >
                              <i className="dw dw-delete-3 mx-e"></i> Delete
                            </button>
                          </td>
                        </tr>
                      );
                    })
                  ) : (
                    <tr>
                      <td colSpan="6" className="text-center py-2">
                        No data found
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
          )}
        </div>
      </div>
    </div>
    <DeleteConfirmationModal
        isOpen={modalOpen}
        toggle={() => setModalOpen(!modalOpen)}
        onConfirm={confirmDelete}
        itemName="menu"
      />
    </>
  );
};

export default Menu;

import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { homeslider } from "../Redux/getHomeSliderSlice";
import axios from "axios";
import { toast } from "react-toastify";
import Loader from "../Layout/Loader";
import { ImageLink } from "../constants";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import DataTable from "datatables.net-dt";
import DeleteConfirmationModal from "../component/DeleteConfirmationModal";

const HomeSlider = () => {
  const baseurl = process.env.REACT_APP_API_BASE_URL;
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const homesliders = useSelector((state) => state.homeSlider.data?.document || []);
  const Loading = useSelector((state) => state.homeSlider.isLoading);
  const [homeSlider, setHomeSlider] = useState([]);
  const [modalOpen, setModalOpen] = useState(false);
  const [deleteItemId, setDeleteItemId] = useState(null);

  useEffect(() => {
    dispatch(homeslider());
  }, [dispatch]);

  useEffect(() => {
    setHomeSlider(homesliders);
  }, [homesliders]);

  useEffect(() => {
    if (homeSlider.length > 0) {
      const table = new DataTable("#myTable", {
        pageLength: homeSlider.length,
        lengthChange: false,
        autoWidth: true,
        paging: false,
        info: false,
        searching: false,
        columnDefs: [
          { targets: 2, orderable: false },
          { targets: 3, orderable: false },
          { targets: 4, orderable: false },
          { targets: 5, orderable: false },
        ],
      });
      return () => {
        table.destroy();
      };
    }
  }, [homeSlider]);

  const handleOnDragEnd = async (result) => {
    if (!result.destination) return;

    const reorderedItems = Array.from(homeSlider);
    const [reorderedItem] = reorderedItems.splice(result.source.index, 1);
    reorderedItems.splice(result.destination.index, 0, reorderedItem);

    const updatedSliders = reorderedItems.map((item, index) => ({
      ...item,
      sortOrder: index + 1,
    }));

    setHomeSlider(updatedSliders);

    const changedItems = updatedSliders.map((item) => ({
      id: item._id,
      sortOrder: item.sortOrder,
    }));

    try {
      const accessToken = localStorage.getItem('accessToken');
      const headersList = {
        "Accept": "application/json",
        "Authorization": `Bearer ${accessToken}`
      };

      await axios.post(`${baseurl}/api/admin/homeslidersortorder`, { data: changedItems }, { headers: headersList });
      toast.success("Slider order updated successfully!");
      dispatch(homeslider());
    } catch (error) {
      toast.error("Failed to update slider order");
    }
  };

  const handleCheckboxChange = async (_id, newIsActive) => {
    try {
      const accessToken = localStorage.getItem('accessToken');
      const headersList = {
        "Accept": "application/json",
        "Authorization": `Bearer ${accessToken}`
      };

      const response = await axios.post(
        `${baseurl}/api/admin/homesliderstatus`,
        { id: _id, isActive: newIsActive },
        { headers: headersList }
      );

      toast.success(response.data?.message);
      dispatch(homeslider()); // Fetch updated data
    } catch (error) {
      toast.error(error.response?.data?.message || "An error occurred");
    }
  };

  const handleDelete = async (id) => {
    setDeleteItemId(id);
    setModalOpen(true);
  };

  const confirmDelete = async () => {
    try {
      let accessToken = localStorage.getItem('accessToken');
      let headersList = {
        "Accept": "*/*",
        "Authorization": `Bearer ${accessToken}`
      }
      const response = await axios.delete(`${baseurl}/api/admin/homeslider/${deleteItemId}`, {
        headers: headersList,
      });
      if (response.status === 200) {
        toast.success(response.data.message);
        dispatch(homeslider());
      } else {
        toast.error(response.data.message);
      }
      setModalOpen(false);
    } catch (error) {
      toast.error('Something went wrong. Please try again!!');
    } finally {
      setDeleteItemId(null);
      setModalOpen(false);
    }
  };

  const toComponentB = (data, event) => {
    event.preventDefault();
    navigate("/add-slider", { state: { data } });
  };

  return (
    <>
      <div className="main-container">
        <div className="xs-pd-20-10">
          <div className="d-flex justify-content-between">
            <div className="title mb-3">
              <h2 className="h3 mb-0 mt-3">Home Slider</h2>
            </div>
            <Link to="/add-slider">
              <button className="text-white h4 btn-sm btn-primary mt-2">
                <i className="icon-copy fi-plus mx-2" />
                Add Slider
              </button>
            </Link>
          </div>

          <div className="card-box mb-30">
            {Loading ? (
              <Loader />
            ) : (
              <div className="pb-20 pd-20 table-responsive">
                <DragDropContext onDragEnd={handleOnDragEnd}>
                  <Droppable droppableId="homeslider">
                    {(provided) => (
                      <table
                        className="table table-striped hover nowrap table-bordered w-100"
                        id="myTable"
                        {...provided.droppableProps}
                        ref={provided.innerRef}
                      >
                        <thead>
                          <tr>
                            <th className="p-2 text-center">Position</th>
                            <th className="p-2 text-center">Banner Title</th>
                            <th className="p-2 text-center">Desktop</th>
                            <th className="p-2 text-center">Mobile</th>
                            <th className="p-2 text-center">Is Active</th>
                            <th className="p-2 text-center">Action</th>
                          </tr>
                        </thead>
                        <tbody className="text-center">
                          {homeSlider.length > 0 ? (
                            homeSlider.map((data, index) => (
                              <Draggable
                                key={data._id}
                                draggableId={data._id}
                                index={index}
                              >
                                {(provided) => (
                                  <tr
                                    ref={provided.innerRef}
                                    {...provided.draggableProps}
                                    {...provided.dragHandleProps}
                                  >
                                    <td>{data.sortOrder}</td>
                                    <td>{data.title}</td>
                                    <td>
                                      <img
                                        src={ImageLink(data.slider_image)}
                                        alt=""
                                        width="100"
                                        height="50"
                                      />
                                    </td>
                                    <td>
                                      <img
                                        src={ImageLink(data.slider_mobile_image)}
                                        alt=""
                                        width="50"
                                        height="50"
                                      />
                                    </td>
                                    <td>
                                      <div className="custom-control custom-switch">
                                        <input
                                          type="checkbox"
                                          className="custom-control-input"
                                          id={`customSwitch_${index}`}
                                          checked={data.isActive}
                                          onChange={() =>
                                            handleCheckboxChange(
                                              data._id,
                                              !data.isActive
                                            )
                                          }
                                        />
                                        <label
                                          className="custom-control-label"
                                          htmlFor={`customSwitch_${index}`}
                                        ></label>
                                      </div>
                                    </td>
                                    <td>
                                      <button
                                        type="Submit"
                                        className="btn-sm btn-outline-info mx-2"
                                        onClick={(event) => {
                                          toComponentB(data, event);
                                        }}
                                      >
                                        <i className="dw dw-edit2 mx-2 "></i>
                                        Edit
                                      </button>
                                      <button
                                        type="Submit"
                                        className="btn-sm btn-outline-danger mx-2"
                                        onClick={() => handleDelete(data._id)}
                                      >
                                        <i className="dw dw-delete-3 mx-e"></i>{" "}
                                        Delete
                                      </button>
                                    </td>
                                  </tr>
                                )}
                              </Draggable>
                            ))
                          ) : (
                            <tr>
                              <td colSpan="6" className="text-center py-2">
                                No data found
                              </td>
                            </tr>
                          )}
                          {provided.placeholder}
                        </tbody>
                      </table>
                    )}
                  </Droppable>
                </DragDropContext>
              </div>
            )}
          </div>
        </div>
      </div>
      <DeleteConfirmationModal
        isOpen={modalOpen}
        toggle={() => setModalOpen(!modalOpen)}
        onConfirm={confirmDelete}
        itemName="banner"
      />
    </>
  );
};

export default HomeSlider;

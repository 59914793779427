import axios from "axios";
import { createSlice } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
const baseurl = process.env.REACT_APP_API_BASE_URL;

const getAllProductSlice = createSlice({
    name: "allProduct",
    initialState: {
        isAuthenticated: false,
        user: null,
        error: null,
        loading: false,
        status: true,
    },
    reducers: {
        allProductPending: (state) => {
            state.loading = true;
        },
        allProductSuccess: (state, { payload }) => {
            state.loading = false;
            state.isAuthenticated = true;
            state.user = payload;
            state.status = 1;
            state.error = null;
        },
        allProductFailure: (state, { payload }) => {
            state.loading = false;
            state.isAuthenticated = false;
            state.user = null;
            state.status = 0;
            state.error = payload;
        },
    },
});

export const { allProductPending, allProductSuccess, allProductFailure } =
    getAllProductSlice.actions;

export const allProductApi = () => async (dispatch) => {

    dispatch(allProductPending());
    try {
        const token = localStorage.getItem("accessToken");
        const response = await axios.get(`${baseurl}/api/admin/productall`, {
            headers: {
              Authorization: `Bearer ${token}`,
              "Accept": "*/*",
            },
          });
        const data = response.data.document;
        if (data.status === 1) {
            toast.success(data.message);
            dispatch(allProductSuccess(data));
        } else {
            dispatch(allProductFailure(data));
            toast.error(data.message);
        }
    } catch (error) {
        toast.error(error.response.data.originalError);
    }
};

export default getAllProductSlice.reducer;

import axios from "axios";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import { goldrateApi } from "../Redux/goldrateSlice";

const GoldRate = () => {
  const baseurl = process.env.REACT_APP_API_BASE_URL;

  const [data, setData] = useState("");
  const dispatch = useDispatch();
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    setValue,
  } = useForm({});
  const [loading, setLoading] = useState(false);

  const onSubmit = async (formData) => {
    setLoading(true);
    if (formData._id) {
      delete formData._id;
      delete formData.createdAt;
      delete formData.updatedAt;
      delete formData.__v;
      dispatch(goldrateApi(formData));
    } else {
      dispatch(goldrateApi(formData));
    }
    setLoading(false);
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        let accessToken = localStorage.getItem("accessToken");
        let headersList = {
          Accept: "*/*",
          Authorization: `Bearer ${accessToken}`,
        };
        setLoading(true);
        const response = await axios.get(`${baseurl}/api/goldrate`, {
          headers: headersList,
        });
        setData(response.data.document);
        setLoading(false);
      } catch (error) {
        setLoading(false);
      }
    };
    fetchData();
  }, [baseurl]);

  const today = new Date().toISOString().split("T")[0];

  useEffect(() => {
    if (data.length > 0) {
      reset(data[0]);
      setValue("date", today);
      setValue("isActive", data[0]?.isActive || false);
    }
  }, [data, reset, setValue, today]);

  return (
    <>
      <div className="row">
        <div className="col-md-12 col-sm-12">
          <div className="height-50-p">
            <h5>Gold Rate</h5>
            <div className="title">
              <form onSubmit={handleSubmit(onSubmit)}>
                <div className="row mt-4">
                  <div className="col-md-3 col-sm-12">
                    <div className="form-group d-flex">
                      <label className="mx-2 mt-1">Date</label>
                      <div>
                        <input
                          className={`form-control ${
                            errors.date ? "is-invalid" : ""
                          }`}
                          {...register("date")}
                          placeholder="date"
                          type="date"
                          value={today}
                          disabled
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-md-2 col-sm-12">
                    <div className="form-group d-flex">
                      <label className="mx-2 mt-1">
                        18K<span className="text-danger">*</span>
                      </label>
                      <div>
                        <input
                          className={`form-control ${
                            errors.carat18 ? "is-invalid" : ""
                          }`}
                          {...register("carat18", { required: true })}
                          type="text"
                        />
                        {errors.carat18 && (
                          <small className="text-danger">
                            Price is required
                          </small>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="col-md-2 col-sm-12">
                    <div className="form-group d-flex">
                      <label className="mx-2 mt-1">
                        22K<span className="text-danger">*</span>
                      </label>
                      <div>
                        <input
                          className={`form-control ${
                            errors.carat22 ? "is-invalid" : ""
                          }`}
                          {...register("carat22", { required: true })}
                          type="text"
                        />
                        {errors.carat22 && (
                          <small className="text-danger text-nowrap">
                            Price is required
                          </small>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="col-md-2 col-sm-12 mt-2 text-center">
                    <div className="form-group row">
                      <div className="col-sm-9">
                        <div className="custom-control custom-switch">
                          <input
                            type="checkbox"
                            className="custom-control-input"
                            id="isActiveSwitch"
                            {...register("isActive")}
                            defaultChecked={data[0]?.isActive}
                          />
                          <label
                            className="custom-control-label"
                            htmlFor="isActiveSwitch"
                          ></label>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-2 col-sm-12">
                    <button
                      type="submit"
                      className="btn-sm custom-btn"
                      disabled={loading}
                    >
                      Save
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default GoldRate;

import DataTable from "datatables.net-dt";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import Loader from "../Layout/Loader";
import { productinquary } from "../Redux/getProductInquarylistSlice";

const Productinquarylist = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const Getproductinquarys = useSelector((state) => state.Getproductinquary.data?.document || []);

  const Loading = useSelector((state) => state.inquirylist.isLoading);

  useEffect(() => {
    if (Getproductinquarys && Getproductinquarys.length > 0) {
      const table = new DataTable("#myTable", {
        pageLength: Getproductinquarys.length,
        lengthChange: false,
        autoWidth: true,
        paging: false,
        info: false,
        searching: false,
        columnDefs: [
          { targets: 4, orderable: false },
          { targets: 5, orderable: false }
        ]
      });
      return () => {
        table.destroy();
      };
    }
  }, [Getproductinquarys]);

  useEffect(() => {
    dispatch(productinquary());
  }, [dispatch]);

  const toComponentB = (data, event) => {
    event.preventDefault();
    navigate("/product-inquiry-detail", { state: { data } });
  };

  return (
    <>
      <div className="main-container">
        <div className="xs-pd-20-10 pd-ltr-20">
          <div className="title pb-20">
            <h2 className="h3 mb-0"> Product Inquiry </h2>
          </div>

          <div className="card-box mb-30">
            {Loading ? (
              <Loader />
            ) : (
              <div className="pb-20 pd-20 table-responsive">
                <table className="table-striped hover nowrap table-bordered w-100" id="myTable">
                  <thead>
                    <tr>
                      <th className="p-2 text-center">Product Name</th>
                      <th className="p-2 text-center">Name</th>
                      <th className="p-2 text-center">Email</th>
                      <th className="p-2 text-center">Mobile No</th>
                      <th className="p-2 text-center">Message</th>
                      <th className="p-2 text-center">Action</th>
                    </tr>
                  </thead>
                  <tbody className="text-center">
                    {Getproductinquarys && Getproductinquarys.length > 0 ? (
                      Getproductinquarys.map((data, index) => (
                        <tr key={index}>
                          <td>{data.productName}</td>
                          <td>{data.name}</td>
                          <td>{data.email}</td>
                          <td>{data.mobile_no}</td>
                          <td>{data.message.substring(0, 20)}</td>
                          <td>
                            <button type="Submit" className="btn-sm btn-outline-info mx-2" onClick={(event) => {
                              toComponentB(data, event);
                            }}
                            >
                              <i className="dw dw-eye mx-2" />View
                            </button>                           
                          </td>
                        </tr>
                      ))
                    ) : (
                      <tr>
                        <td colSpan="6" className="text-center py-2">No data found</td>
                      </tr>
                    )}
                  </tbody>
                </table>

              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default Productinquarylist;

import axios from "axios";
import DataTable from "datatables.net-dt";
import React, { useCallback, useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import Loader from "../Layout/Loader";
import { ImageLink } from "../constants";
import DeleteConfirmationModal from "../component/DeleteConfirmationModal"

const Managebrands = () => {
  const baseurl = process.env.REACT_APP_API_BASE_URL;
  const navigate = useNavigate();
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [deleteItemId, setDeleteItemId] = useState(null);
  const [modalOpen, setModalOpen] = useState(false);
  useEffect(() => {
    if (data && data.length > 0) {
      const table = new DataTable("#myTable", {
        pageLength: data.length,
        lengthChange: false,
        autoWidth: true,
        paging: false,
        info: false,
        searching: false,
        columnDefs: [
          { targets: 2, orderable: false },
          { targets: 3, orderable: false },
          { targets: 4, orderable: false },
          { targets: 5, orderable: false },
          { targets: 6, orderable: false },
          { targets: 7, orderable: false },
          { targets: 8, orderable: false },
          { targets: 9, orderable: false },
        ],
      });
      return () => {
        table.destroy();
      };
    }
  }, [data]);

  const fetchData = useCallback(async () => {
    try {
      setLoading(true);
      let accessToken = localStorage.getItem("accessToken");
      let headersList = {
        Accept: "*/*",
        Authorization: `Bearer ${accessToken}`,
      };
      const response = await axios.get(`${baseurl}/api/admin/getbranddata`, {
        headers: headersList,
      });
      setData(response.data.categoryData);
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  }, [baseurl]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const handleDelete = async (id) => {
    setDeleteItemId(id);
    setModalOpen(true);
};

const confirmDelete = async () => {
  try {
    let accessToken = localStorage.getItem('accessToken');
      let headersList = {
        "Accept": "*/*",
        "Authorization": `Bearer ${accessToken}`
      }
      const response = await axios.delete(`${baseurl}/api/admin/brand/${deleteItemId}`, {
              headers: headersList,
            });            
      if (response.status === 200) {
          toast.success(response.data.message);
          fetchData();
      } else {
          toast.error(response.data.message);
      }
      setModalOpen(false);
  } catch (error) {    
      toast.warning(error.response.data.error || 'Something went wrong. Please try again!!');
  } finally {
      setDeleteItemId(null);
      setModalOpen(false);
  }
};

  const handleCheckboxChange = async (_id, newIsActive) => {
    try {
      let accessToken = localStorage.getItem("accessToken");

      let headersList = {
        Accept: "application/json",
        Authorization: `Bearer ${accessToken}`,
      };

      let reqOptions = {
        url: `${baseurl}/api/admin/brandstatus`,
        method: "POST",
        headers: headersList,
        data: {
          id: _id,
          isActive: newIsActive,
        },
      };

      let response = await axios.request(reqOptions);

      toast.success(response.data?.message);
      fetchData();
    } catch (error) {
      toast.error(error.response?.data?.message || "An error occurred");
    }
  };
  const toComponentB = (item) => {
    navigate("/add-collection", { state: { item, _id: item._id } });
  };

  const toComponentD = (item) => {
    navigate(`/${item._id}/brand-product`, { state: { item, _id: item._id } });
  };

  return (
    <>
      <div className="main-container">
        <div className="xs-pd-20-10">
          <div className="d-flex justify-content-between">
            <div className="title mb-3">
              <h2 className="h3 mb-0 mt-3 mx-1">Collections</h2>
            </div>
            <Link to="/add-collection">
              <button className="text-white h4 btn-sm btn-primary mt-2">
                <i className="icon-copy fi-plus mx-2" />
                Add Collection
              </button>
            </Link>
          </div>
          <div className="card-box mb-30">
            {loading ? (
              <Loader />
            ) : (
              <div className="pb-20 pd-20 table-responsive">
                <table
                  className="table-striped hover nowrap table-bordered w-100"
                  id="myTable"
                >
                  <thead>
                    <tr>
                      <th className="p-2 text-center">Position</th>
                      <th className="p-2 text-center">Name</th>
                      <th className="p-2 text-center">Collection</th>
                      <th className="p-2 text-center">Logo</th>
                      <th className="p-2 text-center">Banner</th>
                      <th className="p-2 text-center">Background</th>
                      <th className="p-2 text-center">Products</th>
                      <th className="p-2 text-center">Is Active</th>
                      <th className="p-2 text-center">Show On Home</th>
                      <th className="p-2 text-center">Action</th>
                    </tr>
                  </thead>
                  <tbody className="text-center">
                    {data?.length ? (
                      data?.map((item, id) => {
                        return (
                          <tr key={id}>
                            <td>{item.sortOrder}</td>
                            <td>{item.name}</td>
                            <td>
                              <img
                                src={ImageLink(item.collection_image)}
                                alt="slider-img"
                                width={50}
                              />
                            </td>
                            <td>
                              <img
                                src={ImageLink(item.logo_image)}
                                alt="slider-img"
                                width={50}
                              />
                            </td>
                            <td>
                              <img
                                src={ImageLink(item.main_banner_image)}
                                alt="slider-img"
                                width={50}
                              />
                            </td>
                            <td>
                              <img
                                src={ImageLink(item.background_image)}
                                alt="slider-img"
                                width={50}
                              />
                            </td>
                            <td>
                              <button
                                className="btn-sm btn-primary text-white "
                                onClick={() => toComponentD(item, item._id)}
                              >
                                {item.productCount}
                              </button>
                            </td>
                            <td>
                              <div className="custom-control custom-switch">
                                <input
                                  type="checkbox"
                                  className="custom-control-input"
                                  id={`customSwitch_${id}`}
                                  checked={item.isActive}
                                  onChange={() =>
                                    handleCheckboxChange(
                                      item._id,
                                      !item.isActive
                                    )
                                  }
                                />
                                <label
                                  className="custom-control-label"
                                  htmlFor={`customSwitch_${id}`}
                                />
                              </div>
                            </td>
                            <td>
                              <div className="custom-control custom-switch">
                                <input
                                  type="checkbox"
                                  className="custom-control-input"                               
                                  checked={item.showInHome} />
                                <label className="custom-control-label" />
                              </div>
                            </td>
                            <td>
                              <button
                                type="Submit"
                                className="btn-sm btn-outline-info mx-2"
                                onClick={() => toComponentB(item, item._id)}
                              >
                                <i className="dw dw-edit2 mx-2 "></i>Edit
                              </button>
                              <button
                                type="Submit"
                                className="btn-sm btn-outline-danger mx-2"
                                onClick={() =>
                                  handleDelete(item._id, item.productCount)
                                }
                              >
                                <i className="dw dw-delete-3 mx-e"></i> Delete
                              </button>
                            </td>
                          </tr>
                        );
                      })
                    ) : (
                      <tr>
                        <td className="text-center py-2" colSpan="10">
                          No data found
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
            )}
          </div>
        </div>
      </div>
      <DeleteConfirmationModal
        isOpen={modalOpen}
        toggle={() => setModalOpen(!modalOpen)}
        onConfirm={confirmDelete}
        itemName="collection"
      />
    </>
  );
};

export default Managebrands;

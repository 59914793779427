import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import { ImageLink } from "../constants";
import axios from "axios";

const getFileExtension = (url) => {
  const path = new URL(url).pathname;
  const extension = path.split(".").pop();
  return extension;
};

const Setting = () => {
  const baseurl = process.env.REACT_APP_API_BASE_URL;
  const [data, setData] = useState("");

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        let accessToken = localStorage.getItem("accessToken");
        let headersList = {
          Accept: "*/*",
          Authorization: `Bearer ${accessToken}`,
        };
        const response = await axios.get(`${baseurl}/api/settingsall`, {
          headers: headersList,
        });
        setData(response?.data?.document);
        setLoading(false);
      } catch (error) {
        setLoading(false);
      }
    };
    fetchData();
  }, [baseurl]);

  const [loading, setLoading] = useState(false);
  const [notificationBanner, setNotificationBanner] = useState(
    data ? ImageLink(data[0]?.notificationBanner) : ""
  );

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({});

  const [fileExtension, setFileExtension] = useState("");

  const handleFileChange = (event, setPreviewImage) => {
    const file = event.target.files[0];
    if (file) {
      const extension = file.name.split(".").pop();
      setFileExtension(extension);
      const reader = new FileReader();
      reader.onloadend = () => {
        setPreviewImage(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };

  const onSubmit = async (data) => {
    setLoading(true);
    const formData = new FormData();


    if (data.notificationBanner instanceof File) {
      formData.append("notificationBanner", data.notificationBanner);
    } else if (notificationBanner) {
      const extension = fileExtension || getFileExtension(notificationBanner);
      const blob = await fetch(notificationBanner).then((res) => res.blob());
      formData.append("notificationBanner", blob, `notificationBanner.${extension}`);
    }

    formData.append("collection_title", data.collection_title);
    formData.append("box_title", data.box_title);
    formData.append("about_title", data.about_title);
    formData.append("video_title", data.video_title);
    formData.append("testimonial_title", data.testimonial_title);
    formData.append("showNotificationBanner", data.showNotificationBanner);

    try {
      let accessToken = localStorage.getItem("accessToken");
      let headersList = {
        Accept: "*/*",
        Authorization: `Bearer ${accessToken}`,
      };
      const response = await fetch(`${baseurl}/api/admin/settings`, {
        method: "POST",
        body: formData,
        headers: headersList,
      });
      const responseData = await response.json();
      if (responseData.status === 1) {
        toast.success(responseData.message);
      } else {
        toast.error(responseData.error);
      }
    } catch (error) {
      toast.error("Error submitting form:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    reset(data[0]);
    setNotificationBanner(ImageLink(data[0]?.notificationBanner));
  }, [data, reset]);

  return (
    <>
      <div className="main-container">
        <div className="xs-pd-20-10 pd-ltr-20 col-md-12">
          <div className="title pb-20">
            <h2 className="h3 mb-0">Home Page</h2>
          </div>
          <div className="row">
            <div className="col-md-12 col-sm-12 mb-30">
              <div className="pd-20 card-box height-100-p">
                <div className="pd-20  ">
                  <form onSubmit={handleSubmit(onSubmit)}>
                    <div className="form-group row">
                      <label className="col-sm-12 col-md-3 mb-4 col-form-label">
                        Home Image
                      </label>
                      <div className="col-md-9 mb-4">
                        <input
                          className={`form-control`}
                          {...register("notificationBanner")}
                          type="file"
                          onChange={(event) =>
                            handleFileChange(event, setNotificationBanner)
                          }
                        />
                        <small className="form-text text-muted">
                          Required image size: 1556 × 172px (.png, .jpg, .jpeg, .webp)
                        </small>
                        {notificationBanner && (
                          <div className="mb-2 mt-2">
                            <img
                              src={notificationBanner}
                              alt="Preview"
                              className="preview-image"
                              style={{ maxWidth: "100%" }}
                            />
                          </div>
                        )}
                        {errors?.notificationBanner && (
                          <small className="text-danger">Image required</small>
                        )}
                      </div>
                      <label className="col-sm-12 col-md-3 col-form-label mb-4">
                        Collection Title<span className="text-danger">*</span>
                      </label>
                      <div className="col-md-9 mb-4">
                        <input
                          className={`form-control ${errors.collection_title ? "is-invalid" : ""
                            }`}
                          {...register("collection_title", { required: true })}
                          placeholder="Enter collection title"
                          type="text"
                        />
                        {errors.collection_title && (
                          <small className="text-danger">
                            Collection title is required
                          </small>
                        )}
                      </div>

                      <label className="col-sm-12 col-md-3 col-form-label mb-4">
                        Box Title<span className="text-danger">*</span>
                      </label>
                      <div className="col-md-9 mb-4">
                        <input
                          className={`form-control ${errors.box_title ? "is-invalid" : ""
                            }`}
                          {...register("box_title", { required: true })}
                          placeholder="Enter box title"
                          type="text"
                        />
                        {errors.box_title && (
                          <small className="text-danger">
                            Box title is required
                          </small>
                        )}
                      </div>

                      <label className="col-sm-12 col-md-3 col-form-label mb-4">
                        About Title<span className="text-danger">*</span>
                      </label>
                      <div className="col-md-9 mb-4">
                        <input
                          className={`form-control ${errors.about_title ? "is-invalid" : ""
                            }`}
                          {...register("about_title", { required: true })}
                          placeholder="Enter about title"
                          type="text"
                        />
                        {errors.about_title && (
                          <small className="text-danger">
                            About title is required
                          </small>
                        )}
                      </div>

                      <label className="col-sm-12 col-md-3 col-form-label mb-4">
                        Video Title<span className="text-danger">*</span>
                      </label>
                      <div className="col-md-9 mb-4">
                        <input
                          className={`form-control ${errors.video_title ? "is-invalid" : ""
                            }`}
                          {...register("video_title", { required: true })}
                          placeholder="Enter video title"
                          type="text"
                        />
                        {errors.video_title && (
                          <small className="text-danger">
                            Video title is required
                          </small>
                        )}
                      </div>

                      <label className="col-sm-12 col-md-3 col-form-label mb-4">
                        Testimonial Title<span className="text-danger">*</span>
                      </label>
                      <div className="col-md-9 mb-4">
                        <input
                          className={`form-control ${errors.testimonial_title ? "is-invalid" : ""
                            }`}
                          {...register("testimonial_title", { required: true })}
                          placeholder="Enter testimonial title"
                          type="text"
                        />
                        {errors.testimonial_title && (
                          <small className="text-danger">
                            Testimonial title is required
                          </small>
                        )}
                      </div>

                      <label className="col-sm-12 col-md-3 col-form-label">
                        Notification Banner
                      </label>
                      <div className="col-md-9 mb-4">
                        <div className="custom-control custom-switch">
                          <input
                            type="checkbox"
                            className="custom-control-input"
                            id="customSwitch1"
                            {...register("showNotificationBanner")}
                          />
                          <label
                            className="custom-control-label"
                            htmlFor="customSwitch1"
                          ></label>
                        </div>
                      </div>
                    </div>
                    <div className="modal-footer justify-content-center">
                      <button
                        type="submit"
                        className="btn-sm custom-btn"
                        disabled={loading}
                      >
                        {loading ? "Loading..." : "Save"}
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Setting;

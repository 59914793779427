import React, { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import { categoryData } from "../Redux/getCategorySlice";
import { useDispatch, useSelector } from "react-redux";
import { ImageLink } from "../constants";

const getFileExtension = (url) => {
  const path = new URL(url).pathname;
  const extension = path.split(".").pop();
  return extension;
};

const AddnewCategory = () => {
  const baseurl = process.env.REACT_APP_API_BASE_URL;
  const category = useSelector(state => state?.category?.data || []);
  const dispatch = useDispatch()
  const sortOrder = Array.isArray(category) ? category.map((item) => item?.sortOrder) : [];
  const maxSortOrder = sortOrder.length > 0 ? Math.max(...sortOrder) : 0;

  const [previewImage1, setPreviewImage1] = useState("");
  const [previewImage, setPreviewImage] = useState("");
  const [previewImage4, setPreviewImage4] = useState("");
  const [previewImage3, setPreviewImage3] = useState("");
  const [fileExtension, setFileExtension] = useState("");

  const handleFileChange = (event, setPreviewImage) => {
    const file = event.target.files[0];
    if (file) {
      const extension = file.name.split(".").pop();
      setFileExtension(extension);
      const reader = new FileReader();
      reader.onloadend = () => {
        setPreviewImage(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };

  const { register, handleSubmit, formState: { errors }, reset } = useForm({});

  const location = useLocation();
  const id = location?.state?._id;

  useEffect(() => {
    reset(location?.state?.item);
    setPreviewImage1(ImageLink(location?.state?.item?.menu_image));
    setPreviewImage4(ImageLink(location?.state?.item?.category_box_image));
    setPreviewImage(ImageLink(location?.state?.item?.banner_image));
    setPreviewImage3(ImageLink(location?.state?.item?.background_image));
  }, [location?.state?.item, reset]);

  const isEditing = !!id;
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const onSubmit = async (data) => {
    setLoading(true);

    const formData = new FormData();
    formData.append("name", data.name);
    formData.append("subTitle", data.subTitle);

    if (data.background_image instanceof File) {
      formData.append("background_image", data.background_image);
    } else if (previewImage3) {
      const extension = fileExtension || getFileExtension(previewImage3);
      const blob = await fetch(previewImage3).then((res) => res.blob());
      formData.append("background_image", blob, `previewImage3.${extension}`);
    }

    if (data.banner_image instanceof File) {
      formData.append("banner_image", data.banner_image);
    } else if (previewImage) {
      const extension = fileExtension || getFileExtension(previewImage);
      const blob = await fetch(previewImage).then((res) => res.blob());
      formData.append("banner_image", blob, `previewImage.${extension}`);
    }

    if (data.menu_image instanceof File) {
      formData.append("menu_image", data.menu_image);
    } else if (previewImage1) {
      const extension = fileExtension || getFileExtension(previewImage1);
      const blob = await fetch(previewImage1).then((res) => res.blob());
      formData.append("menu_image", blob, `previewImage1.${extension}`);
    }

    if (data.category_box_image instanceof File) {
      formData.append("testimonial_image", data.category_box_image);
    } else if (previewImage4) {
      const extension = fileExtension || getFileExtension(previewImage4);
      const blob = await fetch(previewImage4).then((res) => res.blob());
      formData.append("category_box_image", blob, `previewImage4.${extension}`);
    }

    formData.append("sortOrder", data.sortOrder);
    formData.append("description", data.description);
    formData.append("title", data.title);
    formData.append("isActive", data.isActive);
    formData.append("showInHome", data.showInHome);
    formData.append("metaTitle", data.metaTitle);
    formData.append("metaKeyword", data.metaKeyword);
    formData.append("metaDescription", data.metaDescription);
    if (isEditing) {
      formData.append("id", id);
    }
    try {
      let accessToken = localStorage.getItem('accessToken');
      let headersList = {
        "Accept": "*/*",
        "Authorization": `Bearer ${accessToken}`
      }
      const response = await fetch(
        `${baseurl}/api/admin/category`,
        {
          method: "POST",
          headers: headersList,
          body: formData,
        },
      );

      const responseData = await response.json();
      if (responseData.status === 1) {
        toast.success(responseData.message);
        reset();
        navigate("/category");
      } else {
        toast.error(responseData.originalError);
      }
    } catch (error) {
      toast.error("Error submitting form:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    dispatch(categoryData({ currentPageNo: 1, perPage: 10 }));
  }, [dispatch])

  return (
    <>
      <div className="main-container">

        <div className="xs-pd-20-10 pd-ltr-20 col-md-12">
          <div className="title pb-20">
            <h2 className="h3 mb-0">Add Category</h2>
          </div>

          <div className="row">
            <div className="col-md-12 col-sm-12 mb-30">
              <div className="pd-20 card-box height-100-p">
                <div className="pd-20  ">
                  <form onSubmit={handleSubmit(onSubmit)}>
                    <div className="form-group row">
                      <label className="col-sm-12 col-md-3 mb-4 col-form-label">
                        Category Name<span className="text-danger">*</span>
                      </label>
                      <div className="col-md-9 mb-4">
                        <input
                          className={`form-control ${errors.name ? "is-invalid" : ""
                            }`}
                          placeholder="Enter name"
                          {...register("name", { required: true })}
                          type="text"
                        />
                        {errors.name && (
                          <small className="text-danger">
                            Category Name is required
                          </small>
                        )}
                      </div>

                      <label className="col-sm-12 col-md-3 mb-4 col-form-label">
                        Sub Title
                      </label>
                      <div className="col-md-9 mb-4">
                        <input
                          className={`form-control `}
                          {...register("subTitle")}
                          type="text"
                          placeholder="Enter sub title"
                        />
                        {errors.subTitle && (
                          <small className="text-danger">
                            Sub title is required
                          </small>
                        )}
                      </div>

                      <label className="col-sm-12 col-md-3 mb-4 col-form-label">
                        Sort Order <span className="text-danger">*</span>
                      </label>
                      <div className="col-md-9 mb-4">
                        <input
                          className={`form-control ${errors.sortOrder ? "is-invalid" : ""
                            }`}
                          {...register("sortOrder", { required: true })}
                          type="text"
                          placeholder="Enter sort order"
                          defaultValue={maxSortOrder + 1}
                        />
                        {errors.sortOrder && (
                          <small className="text-danger">
                            Short order is required
                          </small>
                        )}
                      </div>

                      <label className="col-sm-12 col-md-3 col-form-label">
                        Background Image
                      </label>
                      <div className="col-md-9 mb-4">
                        <input
                          className="form-control"
                          {...register("background_image")}
                          type="file"
                          onChange={(event) =>
                            handleFileChange(event, setPreviewImage3)
                          }
                        />
                        {previewImage3 && (
                          <div className="mb-3 mt-3">
                            <img
                              src={previewImage3}
                              alt=""
                              className="preview-image w-50"
                            />
                            <button type="button" className="close-btn" onClick={() => setPreviewImage3('')}>
                              X
                            </button>
                          </div>
                        )}
                        <small className="form-text text-muted">
                          Required image size: 1920 X 516px (.png, .jpg, .jpeg,
                          .webp)
                        </small>
                      </div>

                      <label className="col-sm-12 col-md-3 col-form-label">
                        Banner Image
                      </label>
                      <div className="col-md-9 mb-4">
                        <input
                          className='form-control'
                          {...register("banner_image")}
                          type="file"
                          onChange={(event) =>
                            handleFileChange(event, setPreviewImage)
                          }
                        />
                        {previewImage && (
                          <div className="mb-3 mt-3">
                            <img
                              src={previewImage}
                              alt=""
                              className="preview-image w-50"
                            />
                            <button type="button" className="close-btn" onClick={() => setPreviewImage('')}>
                              X
                            </button>
                          </div>
                        )}
                        <small className="form-text text-muted">
                          Required image size: 1444 X 373px (.png, .jpg, .jpeg, .webp)
                        </small>
                      </div>

                      <label className="col-sm-12 col-md-3 mb-4 col-form-label">
                        Category Menu Image
                      </label>
                      <div className="col-md-9 mb-4">
                        <input
                          className='form-control'
                          {...register("menu_image")}
                          type="file"
                          onChange={(event) =>
                            handleFileChange(event, setPreviewImage1)
                          }
                        />
                        {previewImage1 && (
                          <div className="mb-3 mt-3">
                            <img
                              src={previewImage1}
                              alt=""
                              className="preview-image w-50"
                            />
                            <button type="button" className="close-btn" onClick={() => setPreviewImage1('')}>
                              X
                            </button>
                          </div>)}
                        <small className="form-text text-muted">
                          Required image size: 	842 X 237px  (.png, .jpg, .jpeg, .webp)
                        </small>
                      </div>

                      <label className="col-sm-12 col-md-3 mb-4 col-form-label">
                        Category Box Image
                      </label>
                      <div className="col-md-9 mb-4">
                        <input
                          className='form-control'
                          {...register("category_box_image")}
                          type="file"
                          onChange={(event) =>
                            handleFileChange(event, setPreviewImage4)
                          }
                        />
                        {previewImage4 &&
                          (<div className="mb-3 mt-3">
                            <img
                              src={previewImage4}
                              alt=""
                              className="preview-image w-25"
                            />
                            <button type="button" className="close-btn" onClick={() => setPreviewImage4('')}>
                              X
                            </button>
                          </div>)}
                        <small className="form-text text-muted">
                          Required image size: 225 X 208px (.png, .jpg, .jpeg, .webp)
                        </small>
                      </div>

                      <label className="col-sm-12 col-md-3 mb-4 col-form-label">
                        Description
                      </label>
                      <div className="col-md-9 mb-4">
                        <textarea
                          className={`form-control ${errors.description ? "is-invalid" : ""
                            }`}
                          {...register("description", { required: true })}
                          type="text"
                          placeholder="Enter description"
                        />
                        {errors.description && (
                          <small className="text-danger">
                            Description is required
                          </small>
                        )}
                      </div>

                      <label className="col-sm-12 col-md-3 col-form-label mb-4">
                        Meta Title<span className="text-danger">*</span>
                      </label>
                      <div className="col-md-9 mb-4">
                        <input
                          className={`form-control ${errors.metaTitle ? "is-invalid" : ""
                            }`}
                          {...register("metaTitle", { required: true })}
                          placeholder="Enter meta title"
                          type="text"
                        />
                        {errors.metaTitle && (
                          <small className="text-danger">
                            Meta title is required
                          </small>
                        )}
                      </div>

                      <label className="col-sm-12 col-md-3 col-form-label mb-4">
                        Meta Keyword<span className="text-danger">*</span>
                      </label>
                      <div className="col-md-9 mb-4">
                        <input
                          className={`form-control ${errors.metaKeyword ? "is-invalid" : ""
                            }`}
                          {...register("metaKeyword", { required: true })}
                          type="text"
                          placeholder="Enter meta keyword"
                        />
                        {errors.metaKeyword && (
                          <small className="text-danger">
                            Meta keyword is required
                          </small>
                        )}
                      </div>

                      <label className="col-sm-12 col-md-3 col-form-label mb-4">
                        Meta Description<span className="text-danger">*</span>
                      </label>
                      <div className="col-md-9 mb-4">
                        <textarea
                          className={`form-control ${errors.metaDescription ? "is-invalid" : ""
                            }`}
                          {...register("metaDescription", { required: true })}
                          type="text"
                          placeholder="Enter meta description"
                        />
                        {errors.metaDescription && (
                          <small className="text-danger">
                            Meta description is required
                          </small>
                        )}
                      </div>

                      <label className="col-sm-12 col-md-3 col-form-label">
                        Is Active
                      </label>
                      <div className="col-md-9">
                        <div className="custom-control custom-switch">
                          <input
                            type="checkbox"
                            className="custom-control-input"
                            id="customSwitch3"
                            {...register("isActive")}
                          />
                          <label className="custom-control-label" htmlFor="customSwitch3"></label>
                        </div>
                      </div>
                      <label className="col-sm-12 col-md-3 col-form-label">
                        Show on Homepage
                      </label>
                      <div className="col-md-9">
                        <div className="custom-control custom-switch">
                          <input
                            type="checkbox"
                            className="custom-control-input"
                            id="customSwitch2"
                            {...register("showInHome")}
                          />
                          <label
                            className="custom-control-label"
                            htmlFor="customSwitch2"
                          ></label>
                        </div>
                      </div>
                    </div>

                    <div className="modal-footer justify-content-center">
                      <Link to="/category">
                        <button
                          type="button"
                          className="btn-sm btn-secondary"
                          data-dismiss="modal">
                          Back
                        </button>
                      </Link>
                      <button type="submit" className="btn-sm custom-btn" disabled={loading}>
                       Save
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AddnewCategory;

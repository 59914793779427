import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import { changePassword } from "../Redux/changePasswordSlice";
import "react-toastify/dist/ReactToastify.css";
import { toast } from "react-toastify";

const ChangePassword = () => {
  const [isSaving, setIsSaving] = useState(false);
  const dispatch = useDispatch();

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({});

  const onSubmit = async (data) => {
    setIsSaving(true);

    try {
      let dataobject = {
        password: data.oldpassword,
        new_password: data.newpassword,
      };
      await dispatch(changePassword(dataobject));

      setIsSaving(false);
    } catch (error) {
      setIsSaving(false);
      toast.error("Error submitting form. Please try again.");
    }
  };

  return (
    <>
      <div className="main-container">
        <div className="xs-pd-20-10 pd-ltr-20 col-md-12">
          <div className="title pb-20">
            <h2 className="h3 mb-0">Change Password </h2>
          </div>
          <div className="row">
            <div className="col-md-8 col-sm-12 mb-30">
              <div className="pd-20 card-box ">
                <div className="pd-20  ">
                  <form onSubmit={handleSubmit(onSubmit)}>
                    <div className="row ">
                      <div className="col-md-3 mb-4  ">
                        <label className="form-label">
                          Old Password <span className="text-danger">*</span>
                        </label>
                      </div>
                      <div className="col-md-8 mb-4 ">
                        <input
                          type="text"
                          className={`form-control ${
                            errors.oldpassword ? "is-invalid" : ""
                          }`}
                          name="oldpassword"
                          {...register("oldpassword", {
                            required: true,
                          })}
                        />
                        {errors.oldpassword && (
                          <small className="text-danger">
                            Please Enter a Old Password
                          </small>
                        )}
                      </div>
                    </div>
                    <div className="row ">
                      <div className="col-md-3 mb-4  ">
                        <label className="form-label">
                          New Password <span className="text-danger">*</span>
                        </label>
                      </div>
                      <div className="col-md-8  mb-4 ">
                        <input
                          type="text"
                          className={`form-control ${
                            errors.newpassword ? "is-invalid" : ""
                          }`}
                          name="newpassword"
                          {...register("newpassword", {
                            required: true,
                          })}
                        />
                        {errors.newpassword && (
                          <small className="text-danger">
                            Please Enter a New Password
                          </small>
                        )}
                      </div>
                    </div>
                    <div className="row ">
                      <div className="col-md-3 mb-4  ">
                        <label className="form-label">
                          Retype password
                          <span className="text-danger">*</span>
                        </label>
                      </div>
                      <div className="col-md-8  mb-4 ">
                        <input
                          type="text"
                          className={`form-control ${
                            errors.retypepassword ? "is-invalid" : ""
                          }`}
                          name="retypepassword"
                          {...register("retypepassword", {
                            required: true,
                          })}
                        />
                        {errors.retypepassword && (
                          <small className="text-danger">
                            Please Enter a Retype New Password
                          </small>
                        )}
                      </div>
                    </div>
                    <div className="row ">
                      <div className="col-md-3 mb-4  ">
                        <label className="form-label"></label>
                      </div>
                      <div className="col-md-8 mb-4 ">
                        <button
                          className="btn-sm custom-btn my-2 mx-2"
                          type="submit"
                          disabled={isSaving}
                        >
                          {isSaving ? "Loading..." : "Save"}
                        </button>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ChangePassword;

import axios from "axios";
import { createSlice } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import { handleTokenErrors } from "./handleTokenErrors";
const baseurl = process.env.REACT_APP_API_BASE_URL;

const subcateProductSlice = createSlice({
    name: "subcatProduct",
    initialState: {
        isAuthenticated: false,
        user: null,
        error: null,
        loading: false,
        status: true,
    },
    reducers: {
        subcatProductPending: (state) => {
            state.loading = true;
        },
        subcatProductSuccess: (state, { payload }) => {
            state.loading = false;
            state.isAuthenticated = true;
            state.user = payload;
            state.status = 1;
            state.error = null;
        },
        subcatProductFailure: (state, { payload }) => {
            state.loading = false;
            state.isAuthenticated = false;
            state.user = null;
            state.status = 0;
            state.error = payload;
        },
    },
});

export const { subcatProductPending, subcatProductSuccess, subcatProductFailure } =
    subcateProductSlice.actions;

export const subcatProductApi = (id) => async (dispatch) => {
    const token = localStorage.getItem("accessToken");
    dispatch(subcatProductPending());
    let ids = []
    ids.push(id)
    try {
        const response = await axios.get(`${baseurl}/api/admin/getsubcategoryproduct/${id}`, {
            subcategoryId: ids,
        }, {
            headers: {
                Authorization: `Bearer ${token}`,
                "Accept": "*/*",
            },
        });
        const data = response.data;
        if (data.status === 1) {
            toast.success(data.message);
            dispatch(subcatProductSuccess(data));
        } else {
            dispatch(subcatProductFailure(data));
            toast.error(data.message);
        }
    } catch (error) {
        handleTokenErrors(error);
        toast.error(error.response.data.originalError);
    }
};

export default subcateProductSlice.reducer;

import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { searchlist } from "../Redux/getSearchlistSlice";
import axios from "axios";
import { CSVLink } from "react-csv";
import { toast } from "react-toastify";
import Select from "react-select";

const ImportExportProduct = () => {
  const baseurl = process.env.REACT_APP_API_BASE_URL;
  const dispatch = useDispatch();
  const [importfile, setImportFile] = useState(null);
  const setValue = "";
  const [data, setData] = useState([]);
  const [isFreezed, setIsFreezed] = useState(null);
  const [loading, setLoading] = useState(false);
  const [productSku, setProductSku] = useState("");
  const [categories, setCategories] = useState([]);
  const [selectedCategories, setSelectedCategories] = useState([]);
  const searchlists = useSelector(
    (state) => state.Getsearchlist.data?.document?.data || []
  );

  const handleFileChange = (event) => {
    setImportFile(event.target.files[0]);
  };

  const handleRemove = () => {
    setValue("");
  };

  const handleSelectChange = (selectedOptions) => {
    setSelectedCategories(selectedOptions);
    setCategories(selectedOptions?.map((option) => option.value));
  };

  const handleClearClick = () => {
    setProductSku("");
    setIsFreezed(null);
    setSelectedCategories([]);
  };

  useEffect(() => {
    setLoading(true);
    let params = {
      pageNo: 1,
      perPage: 10,
      category: categories,
      sku: "",
      freeze: isFreezed !== "productSku" ? isFreezed : null,
    };
    if (isFreezed === "productSku") {
      params.sku = productSku;
    }
    dispatch(searchlist(params));
    setLoading(false);
  }, [categories, productSku, isFreezed, dispatch]);

  const mapCategoriesToOptions = (categories, parentLabel = "") => {
    return categories
      ?.map((category) => {
        const currentLabel = parentLabel
          ? `${parentLabel} > ${category.name}`
          : category.name;
        const option = {
          value: category._id,
          label: currentLabel,
          isDisabled: false,
        };

        let subcategories = [];
        if (
          category.nestedSubcategories &&
          category.nestedSubcategories.length > 0
        ) {
          subcategories = mapCategoriesToOptions(
            category.nestedSubcategories,
            currentLabel
          );
        }
        return [option, ...subcategories];
      })
      .flat();
  };

  const categoryOptions = mapCategoriesToOptions(data);

  const handleUploadExcel = async () => {
    let accessToken = localStorage.getItem("accessToken");
    let headersList = {
      Accept: "*/*",
      Authorization: `Bearer ${accessToken}`,
    };
    let formdata = new FormData();
    formdata.append("importfile", importfile);

    let bodyContent = formdata;

    let reqOptions = {
      url: `${baseurl}/api/admin/importsheet`,
      method: "POST",
      headers: headersList,
      data: bodyContent,
    };
    let response = await axios.request(reqOptions);
    if (response.data.status === 1) {
      toast.success(response.data.message);
      setImportFile("");
    }
  };

  const handleFileChange1 = (event) => {
    setImportFile(event.target.files[0]);
  };

  const handleRemove1 = () => {
    setValue("");
  };

  const handleUploadZip = async () => {
    let accessToken = localStorage.getItem("accessToken");
    let headersList = {
      Accept: "*/*",
      Authorization: `Bearer ${accessToken}`,
    };

    let formdata = new FormData();
    formdata.append("zip", importfile);

    let bodyContent = formdata;

    let reqOptions = {
      url: `${baseurl}/api/admin/zip`,
      method: "POST",
      headers: headersList,
      data: bodyContent,
    };
    let response = await axios.request(reqOptions);
    if (response.data.status === 1) {
      toast.success(response.data.message);
      setImportFile("");
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        let accessToken = localStorage.getItem("accessToken");
        let headersList = {
          Accept: "*/*",
          Authorization: `Bearer ${accessToken}`,
        };
        const response = await axios.get(`${baseurl}/api/categories`, {
          headers: headersList,
        });
        setData(response.data.document);
      } catch (error) {
        toast.error(error);
      }
    };
    fetchData();
  }, [baseurl]);

  return (
    <>
      <div className="main-container">
        <div className="xs-pd-20-10 pd-ltr-20">
          <div className="title pb-20">
            <h2 className="h3 mb-0 mx-1">EXPORT PRODUCTS</h2>
          </div>
          <div className="card-box">
            <div className="row pd-20">
              <div className="col-md-4 col-sm-12 mt-4">
                <div className="form-group">
                  <Select
                    isMulti
                    options={categoryOptions}
                    className="basic-multi-select"
                    classNamePrefix="select"
                    value={selectedCategories}
                    onChange={handleSelectChange}
                    isOptionDisabled={(option) => option.isDisabled}
                  />
                </div>
              </div>

              <div className="col-md-3 col-sm-12 mt-4">               
                  <select
                    className="form-control"
                    value={isFreezed}
                    onChange={(e) => setIsFreezed(e.target.value)}
                  >
                    <option value="">-- Select Products --</option>
                    <option value="true">Out of stock products</option>
                    <option value="false">Active products</option>
                    <option value="productSku">Particular product(s)</option>
                  </select>
              </div>

              {isFreezed === "productSku" && (
                <div className="col-md-2 mt-4">
                  <div className="form-group">
                    <input
                      type="text"
                      placeholder="Product Code"
                      className="form-control"
                      value={productSku}
                      onChange={(e) => setProductSku(e.target.value)}
                    />
                  </div>
                </div>
              )}

              <div className="col-md-3 mt-4">
                <button
                  className="btn-sm btn-secondary mx-2"
                  onClick={handleClearClick}
                >
                  Clear
                </button>
                <CSVLink data={searchlists} filename={"exported_data.csv"}>
                  <button className="btn-sm custom-btn">Export CSV</button>
                </CSVLink>
              </div>
            </div>
          </div>
        </div>
        <div className="xs-pd-20-10 pd-ltr-20">
          <div className="title pb-20">
            <h2 className="h3 mb-0 mx-1">IMPORT PRODUCTS</h2>
          </div>
          <div className="card-box">
            <div className="pd-20">
              <div className="col-md-12">
                <div className="form-group">
                  <div className="row g-3 align-items-center">
                    <form className="d-flex">
                      <div className="col-md-4">
                        <label>Import Product CSV File</label>
                      </div>
                      <div className="col-md-4">
                        <input
                          className="form-control"
                          type="file"
                          onChange={handleFileChange}
                          placeholder="Select an Excel file"
                        />
                      </div>
                      {importfile && (
                        <button
                          className="btn-danger btn-sm"
                          onClick={handleRemove}
                        >
                          Remove
                        </button>
                      )}
                      <div className="col-md-4">
                        <button
                          onClick={handleUploadExcel}
                          className="btn-sm custom-btn"
                          disabled={loading || !importfile}
                        >
                          {loading ? "Loading..." : "Upload CSV"}
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
              <div className="col-md-12">
                <div className="form-group">
                  <div className="row g-3 align-items-center">
                    <form className="d-flex">
                      <div className="col-md-4">
                        <label>Import Product Image ZIP</label>
                      </div>
                      <div className="col-md-4">
                        <input
                          className="form-control"
                          type="file"
                          onChange={handleFileChange1}
                          placeholder="Select an Image Zip file"
                        />
                      </div>
                      {importfile && (
                        <button
                          className="btn-sm btn-danger"
                          onClick={handleRemove1}
                        >
                          Remove
                        </button>
                      )}
                      <div className="col-md-4 ">
                        <button
                          onClick={handleUploadZip}
                          disabled={loading || !importfile}
                          className="mx-2 btn-sm custom-btn"
                        >
                          {loading ? "Loading..." : "Upload ZIP"}
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ImportExportProduct;

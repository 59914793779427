

import axios from "axios";
import { toast } from "react-toastify";
import { handleTokenErrors } from "./handleTokenErrors";
const { createSlice } = require("@reduxjs/toolkit");

const baseurl = process.env.REACT_APP_API_BASE_URL;
const emailTemplateSlice = createSlice({
    name: "emailtemplate",
    initialState: {
        isAuthenticated: false,
        user: null,
        error: null,
        loading: false,
        token: null,
        accessToken: null,
        isSuccess: false,
    },
    reducers: {
        emailtemplatePending: (state) => {
            state.loading = true;
        },
        emailtemplateSuccess: (state, { payload }) => {
            state.loading = false;
            state.isAuthenticated = true;
            state.isSuccess = true;
            state.user = payload;
            state.token = payload.token;
            state.accessToken = payload.accessToken;
            state.error = null;
        },
        emailtemplateFailure: (state, { payload }) => {
            state.loading = false;
            state.isAuthenticated = false;
            state.user = null;
            state.error = payload;
        },

    },
});


export const { emailtemplatePending, emailtemplateSuccess, emailtemplateFailure } =
    emailTemplateSlice.actions;

export const emailtemplateApi = (formData) => async (dispatch) => {
    dispatch(emailtemplatePending());
    const token = localStorage.getItem("accessToken");
    try {
        const response = await axios.post(`${baseurl}/api/admin/emailtemplate`,
            formData,  {
                headers: {
                  Authorization: `Bearer ${token}`,
                  "Accept": "*/*",
                },
              }
        );
        const data = response.data;

        if (data.status === 1) {
            dispatch(emailtemplateSuccess(data));
            toast.success(data.message);
        } else {
            toast.error(data.error);
            dispatch(emailtemplateFailure(data));
        }
    } catch (error) {
        dispatch(emailtemplateFailure(error.response.data));
        handleTokenErrors(error);
    }
};

export default emailTemplateSlice.reducer;

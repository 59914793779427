import axios from 'axios';
import DataTable from 'datatables.net-dt';
import React, { useCallback, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import Loader from '../Layout/Loader';
import { ImageLink } from '../constants';
import DeleteConfirmationModal from '../component/DeleteConfirmationModal';

const SubCategory = () => {
    const baseurl = process.env.REACT_APP_API_BASE_URL;
    const navigate = useNavigate();
    const location = useLocation();
    const id = location?.state?._id;
    const name = location?.state?.name;

    const [subCatId, setSubCatId] = useState('');
    const [deleteItemId, setDeleteItemId] = useState(null);
    const [modalOpen, setModalOpen] = useState(false);
    const [loading, setLoading] = useState(false);
    const parentCategory = location.state?._id;
    const [categoryData, setCategoryData] = useState([]);
    const perPage = 10;

    const toComponentB = (id) => {
                navigate(`/${location?.state?._id}/add-sub-category`, { state: { id, name } });
            };

    useEffect(() => {
        if (categoryData && categoryData.length > 0) {
            const table = new DataTable("#myTable", {
                pageLength: categoryData.length,
                lengthChange: false,
                autoWidth: true,
                paging: false,
                info: false,
                searching: false,
                columnDefs: [
                    { targets: 2, orderable: false },
                    { targets: 3, orderable: false },
                    { targets: 4, orderable: false },
                    { targets: 5, orderable: false },
                    { targets: 6, orderable: false },
                ],
            });
            return () => {
                table.destroy();
            };
        }
    }, [categoryData]);

    const fetchData = useCallback(async (page = 1) => {
        try {
            setLoading(true);
            const response = await axios.post(`${baseurl}/api/admin/getAllCategory`, {
                parentCategory: subCatId ? subCatId : parentCategory,
                perPage: perPage,
                pageNo: page,
            });
            setCategoryData(response.data.data);
            setLoading(false)
        } catch (error) {
            toast.error('Something went wrong. Please try again!!');
        } finally {
            setLoading(false);
        }
    }, [baseurl, parentCategory, subCatId]);

    useEffect(() => {
        fetchData();
    }, [fetchData, location.state, subCatId]);

    const handleDelete = async (id) => {
        setDeleteItemId(id);
        setModalOpen(true);
    };

    function confirmDelete() {
        setLoading(true);
        let accessToken = localStorage.getItem('accessToken');
        let headersList = {
            "Accept": "*/*",
            "Authorization": `Bearer ${accessToken}`,
        };
        axios.delete(`${baseurl}/api/admin/category/${deleteItemId}`, {
            headers: headersList,
        })
            .then((response) => {
                toast.success(response?.data?.message);
                if (response?.data?.message) {
                    navigate("/category");
                }
            })
            .catch((error) => {
                toast.error(error?.response?.data?.error);
            });
        setLoading(false);
    }

    const handleCheckboxChange = async (_id, newIsActive) => {
        try {
            let accessToken = localStorage.getItem('accessToken');

            let headersList = {
                "Accept": "*/*",
                "Authorization": `Bearer ${accessToken}`,
            };

            let reqOptions = {
                url: `${baseurl}/api/admin/categorystatus`,
                method: "POST",
                headers: headersList,
                data: {
                    id: _id,
                    isActive: newIsActive,
                },
            };
            let response = await axios.request(reqOptions);
            toast.success(response.data?.message);
            fetchData();
        } catch (error) {
            toast.error(error.response?.data?.message || "An error occurred");
        }
    };

    const toComponentD = (_id) => {
        navigate(`/${location?.state?._id}/add-sub-category`, { state: { _id, name } });
    };

    const subCatCountClick = (id, item) => {
        setSubCatId(id);
        navigate(`/${id}/sub-category`, {
            state: item,
            subcategoryCount: item?.subcategoryCount,
        });
    };

    return (
        <>
            <div className="main-container">
                <div className="xs-pd-20-10">
                    <div className='d-flex justify-content-between'>
                        <div className="title mb-3">
                            <h2 className="h3 mb-0 mt-3">Sub Category</h2>
                        </div>
                        <div>
                            <button className="text-white h5 btn-sm btn-primary mt-2" onClick={() => toComponentD(id, name)}>
                                <i className="icon-copy fi-plus mx-2" />
                                Add Sub Category
                            </button>
                        </div>
                    </div>

                    <div className="card-box mb-30">
                            {loading ? <Loader /> :
                        <div className="pb-20 pd-20 table-responsive">
                                <table className="table-striped hover nowrap table-bordered w-100" id='myTable'>
                                    <thead>
                                        <tr>
                                            <th className="p-2 text-center">Position</th>
                                            <th className="p-2 text-center">Image</th>
                                            <th className="p-2 text-center">Name</th>
                                            <th className="p-2 text-center">Sub Category</th>
                                            <th className="p-2 text-center">Products</th>
                                            <th className="p-2 text-center">Is Active</th>
                                            <th className="p-2 text-center">Action</th>
                                        </tr>
                                    </thead>
                                    <tbody className='text-center'>
                                        {categoryData && categoryData.length > 0 ? (
                                            categoryData.map((item, id) => (
                                                <tr key={id}>
                                                    <td>{item.sortOrder}</td>
                                                    <td>
                                                        <img
                                                            src={ImageLink(item.category_box_image)}
                                                            alt=""
                                                            width={50}
                                                        />
                                                    </td>
                                                    <td>{item.name}</td>
                                                    <td>
                                                        <button className="btn-success btn-sm text-white" onClick={() => subCatCountClick(item._id, item)}>
                                                            {item.subcategoryCount}
                                                        </button>
                                                    </td>
                                                    <td>
                                                        <button className="btn-primary btn-sm text-white " onClick={() => { navigate(`/${item?._id}/products`, { state: item }) }}>
                                                            {item.productCount}
                                                        </button>
                                                    </td>
                                                    <td>
                                                        <div className="custom-control custom-switch">
                                                            <input
                                                                type="checkbox"
                                                                className="custom-control-input"
                                                                id={`customSwitch_${id}`}
                                                                checked={item.isActive}
                                                                onChange={() => handleCheckboxChange(item._id, !item.isActive)}
                                                            />
                                                            <label className="custom-control-label" htmlFor={`customSwitch_${id}`}></label>
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <button type="Submit" className="btn-sm btn-outline-info mx-2" onClick={(e) => { e.preventDefault(); toComponentB(item, item._id); }}>
                                                            <i className="dw dw-edit2 mx-2 "></i>Edit
                                                        </button>
                                                        <button type="Submit" className="btn-sm btn-outline-danger mx-2" onClick={() => handleDelete(item._id, item.productCount, item.subCategoryCount)}>
                                                            <i className="dw dw-delete-3 mx-e"></i> Delete
                                                        </button>
                                                    </td>
                                                </tr>
                                            ))
                                        ) : (
                                            <tr>
                                                <td colSpan="7" className="text-center py-2">
                                                    No data found
                                                </td>
                                            </tr>
                                        )}
                                    </tbody>
                                </table>
                        </div>
                            }
                    </div>
                </div>
            </div>
            <DeleteConfirmationModal
                isOpen={modalOpen}
                toggle={() => setModalOpen(!modalOpen)}
                onConfirm={confirmDelete}
                itemName="product"
            />
        </>
    );
};

export default SubCategory;
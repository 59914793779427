import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { createSlice } from "@reduxjs/toolkit";
import { handleTokenErrors } from "./handleTokenErrors";
const baseurl = process.env.REACT_APP_API_BASE_URL;

const initialState = {
  data: [],
  isLoading: false,
  isSuccess: false,
  errorMessage: "",
};

export const branchmaster = createAsyncThunk(
  "branchmaster/branchmasterList",
  async (page, { rejectWithValue }) => {
    const token = localStorage.getItem("accessToken");
    try {
      const response = await axios.get(
        `${baseurl}/api/branchall`,  {
          headers: {
            Authorization: `Bearer ${token}`,
            "Accept": "*/*",
          },
        }
      );
      return response.data;
    } catch (error) {
      handleTokenErrors(error);
      return rejectWithValue(error.message);
    }
  }
);

export const getBranchSlice = createSlice({
  name: "brandlogo",
  initialState,
  extraReducers: {
    [branchmaster.pending]: (state) => {
      state.isLoading = true;
    },
    [branchmaster.fulfilled]: (state, { payload }) => {
      state.isLoading = false;
      state.isSuccess = true;
      state.data = payload;
    },
    [branchmaster.rejected]: (state, { payload }) => {
      state.isLoading = false;
      state.isSuccess = false;
      state.errorMessage = payload;
    },
  },
});

export default getBranchSlice.reducer;

import DataTable from "datatables.net-dt";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import axios from "axios";
import { toast } from "react-toastify";
import Loader from "../Layout/Loader";
import { categoryProductApi } from "../Redux/getCategoryProductSlice";
import { ImageLink } from "../constants";
import DeleteConfirmationModal from "../component/DeleteConfirmationModal";

const ProductList = () => {
  const baseurl = process.env.REACT_APP_API_BASE_URL;
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const id = location?.state?._id;
  const name = location?.state?.item?.name;
  const pathname = location.pathname;
  const pathname1 = pathname.split("/")[1];
  const categoryProduct = useSelector((state) => state.categoryProduct?.user?.product || []);
  const loading = useSelector((state) => state?.categoryProduct?.loading);
  const [savedPage, setSavedPage] = useState(0);
  const [deleteItemId, setDeleteItemId] = useState(null);
  const [modalOpen, setModalOpen] = useState(false);

  useEffect(() => {
    const savedPageNumber = localStorage.getItem("datatable-page-number");
    if (savedPageNumber) {
      setSavedPage(Number(savedPageNumber));
    }
  }, []);

  useEffect(() => {
    if (categoryProduct && categoryProduct.length > 0) {
      const table = new DataTable("#myTable", {
        pageLength: 10,
        lengthChange: false,
        autoWidth: true,
        paging: true,
        info: true,
        searching: false,
        displayStart: savedPage * 10,
        columnDefs: [
          { targets: 2, orderable: false },
          { targets: 3, orderable: false },
        ],
      });

      table.on('page.dt', function () {
        const info = table.page.info();
        localStorage.setItem('datatable-page-number', info.page);
      });

      return () => {
        table.destroy();
      };
    }
  }, [categoryProduct, pathname1, loading, savedPage]);

  const handleCheckboxChange = async (_id, newIsActive) => {
    try {
      let accessToken = localStorage.getItem("accessToken");

      let headersList = {
        Accept: "*/*",
        Authorization: `Bearer ${accessToken}`,
      };

      let reqOptions = {
        url: `${baseurl}/api/admin/productstatus`,
        method: "POST",
        headers: headersList,
        data: {
          id: _id,
          isActive: newIsActive,
        },
      };
      let response = await axios.request(reqOptions);
      toast.success(response.data?.message);
      dispatch(categoryProductApi(pathname1));
    } catch (error) {
      toast.error(error.response?.data?.message || "An error occurred");
    }
  };

  const handleDelete = async (id) => {
    setDeleteItemId(id);
    setModalOpen(true);
  };

  const confirmDelete = async () => {
    try {
      let accessToken = localStorage.getItem('accessToken');
      let headersList = {
        "Accept": "*/*",
        "Authorization": `Bearer ${accessToken}`
      }
      const response = await axios.delete(`${baseurl}/api/admin/product/${deleteItemId}`, {
        headers: headersList,
      });
      if (response.status === 200) {
        toast.success(response.data.message);
        dispatch(categoryProductApi(pathname1));
      } else {
        toast.error(response.data.message);
      }
      setModalOpen(false);
    } catch (error) {
      toast.error('Something went wrong. Please try again!!');
    } finally {
      setDeleteItemId(null);
      setModalOpen(false);
    }
  };

  const toComponentB = (item) => {
    navigate(`/${pathname1}/add-product`, { state: { item, _id: item._id } });
  };

  const toComponentA = () => {
    navigate(`/${pathname1}/add-product`, { state: { id, name } });
  };

  useEffect(() => {
    if (pathname1) {
      dispatch(categoryProductApi(pathname1));
    }
  }, [dispatch, pathname1]);

  return (
    <>
      <div className="main-container">
        <div className="xs-pd-20-10 pd-ltr-20">
          <div className="d-flex justify-content-between">
            <div className="title mb-3">
              <h2 className="h3 mb-0 mt-3">Products</h2>
            </div>
            <div className="pd-20 d-flex">
              <button
                className="btn-sm text-white btn-secondary h4 mx-2"
                onClick={() => navigate("/category")}
              >
                <i
                  className="icon-copy fa fa-arrow-left mx-2"
                  aria-hidden="true"
                />
                Go To Category List
              </button>
              <button
                className="btn-sm text-white h4 btn-primary"
                onClick={() => toComponentA(name)}
              >
                <i className="icon-copy fi-plus mx-2" />
                Add New Product
              </button>
            </div>
          </div>

          <div className="card-box mb-30">
            {loading ? (
              <Loader />
            ) : (
              <div className="pb-20 pd-20 table-responsive">
                <table
                  className="table-striped hover nowrap table-bordered w-100"
                  id="myTable"
                >
                  <thead>
                    <tr>
                      <th className="p-2 text-center">Name</th>
                      <th className="p-2 text-center">Image</th>
                      <th className="p-2 text-center">Is Active</th>
                      <th className="p-2 text-center">Action</th>
                    </tr>
                  </thead>
                  <tbody className="text-center">
                    {Array.isArray(categoryProduct) &&
                      categoryProduct.length > 0 ? (
                      categoryProduct.map((item, id) => {
                        return (
                          <tr key={id}>
                            <td>{item.name}</td>
                            <td>
                              <img
                                src={ImageLink(item.product_image[0])}
                                alt=""
                                width="50"
                                height="50"
                              />
                            </td>
                            <td>
                              <div className="custom-control custom-switch">
                                <input
                                  type="checkbox"
                                  className="custom-control-input"
                                  id={`customSwitch_${id}`}
                                  checked={item.isActive}
                                  onChange={() =>
                                    handleCheckboxChange(item._id, !item.isActive)
                                  }
                                />
                                <label
                                  className="custom-control-label"
                                  htmlFor={`customSwitch_${id}`}
                                ></label>
                              </div>
                            </td>
                            <td>
                              <button
                                type="Submit"
                                className="btn-sm btn-outline-info mx-2"
                                onClick={(e) => {
                                  e.preventDefault();
                                  toComponentB(item, item._id);
                                }}
                              >
                                <i className="dw dw-edit2 mx-2 "></i>Edit
                              </button>
                              <button
                                type="Submit"
                                className="btn-sm btn-outline-danger mx-2"
                                onClick={() => handleDelete(item._id)}
                              >
                                <i className="dw dw-delete-3 mx-e"></i> Delete
                              </button>
                            </td>
                          </tr>
                        );
                      })
                    ) : (
                      <tr>
                        <td colSpan="4" className="text-center py-2">
                          No data found
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
            )}
          </div>
        </div>
      </div>
      <DeleteConfirmationModal
        isOpen={modalOpen}
        toggle={() => setModalOpen(!modalOpen)}
        onConfirm={confirmDelete}
        itemName="product"
      />
    </>
  );
};

export default ProductList;

import axios from "axios";
import DataTable from "datatables.net-dt";
import React, { useCallback, useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import Loader from "../Layout/Loader";
import { ImageLink } from "../constants";
import DeleteConfirmationModal from "../component/DeleteConfirmationModal";

const Testimonial = () => {
  const baseurl = process.env.REACT_APP_API_BASE_URL;
  const navigate = useNavigate();
  const [data, setData] = useState();
  const [loading, setLoading] = useState(false);
  const [deleteItemId, setDeleteItemId] = useState(null);
  const [modalOpen, setModalOpen] = useState(false);

  useEffect(() => {
    if (data && data.length > 0) {
      const table = new DataTable("#myTable", {
        pageLength: data.length,
        lengthChange: false,
        autoWidth: true,
        paging: false,
        info: false,
        searching: false,
        columnDefs: [
          { targets: 2, orderable: false },
          { targets: 3, orderable: false },
          { targets: 4, orderable: false },
          { targets: 5, orderable: false }
      ]
      });
      return () => {
        table.destroy();
      };
    }
  }, [data]);

  const fetchData = useCallback(async () => {
    try {
      setLoading(true);
      let accessToken = localStorage.getItem('accessToken');
      let headersList = {
        "Accept": "*/*",
        "Authorization": `Bearer ${accessToken}`
      }
      const response = await axios.get(`${baseurl}/api/testimonialall`, {
        headers: headersList,
      });
      setData(response.data.document);
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  },[baseurl]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const handleDelete = async (id) => {
    setDeleteItemId(id);
    setModalOpen(true);
  };

  const confirmDelete = async () => {
    try {
      let accessToken = localStorage.getItem('accessToken');
        let headersList = {
          "Accept": "*/*",
          "Authorization": `Bearer ${accessToken}`
        }
        const response = await axios.delete(`${baseurl}/api/admin/testimonial/${deleteItemId}`, {
                headers: headersList,
              });
        if (response.status === 200) {
            toast.success(response.data.message);
            fetchData();
        } else {
            toast.error(response.data.message);
        }
        setModalOpen(false);
    } catch (error) {
        toast.error('Something went wrong. Please try again!!');
    } finally {
        setDeleteItemId(null);
        setModalOpen(false);
    }
  };
  
  const handleCheckboxChange = async (_id, newIsActive) => {
    try {
      let accessToken = localStorage.getItem('accessToken');

      let headersList = {
        "Accept": "*/*",
        "Authorization": `Bearer ${accessToken}`
      }

      let reqOptions = {
        url: `${baseurl}/api/admin/testimonialstatus`,
        method: "POST",
        headers: headersList,
        data: {
          id: _id,
          isActive: newIsActive,
        }
      }

      let response = await axios.request(reqOptions);

      toast.success(response.data?.message);
      fetchData();
    } catch (error) {
      toast.error(error.response?.data?.message || "An error occurred");
    }
  };
  const toComponentB = (data, event) => {
    event.preventDefault();
    navigate("/add-testimonial", { state: { data } });
  };

  return (
    <>
      <div className="main-container">
        <div className="xs-pd-20-10">
          <div className='d-flex justify-content-between'>
            <div className="title mb-3">
              <h2 className="h3 mb-0 mt-3">Testimonials</h2>
            </div>
            <Link to="/add-testimonial">
              <button className="text-white h5 btn-sm btn-primary mt-2">
                <i className="icon-copy fi-plus mx-2" />
                Add Testimonial
              </button>
            </Link>
          </div>
          <div className="card-box mb-30">
            {loading ? (
              <Loader />
            ) : (
              <div className="pb-20 pd-20 table-responsive">
                <table className="table-striped hover nowrap table-bordered w-100" id="myTable">
                  <thead>
                    <tr>
                      <th className="p-2 text-center">Position</th>
                      <th className="p-2 text-center">Name</th>
                      <th className="p-2 text-center">Image</th>
                      <th className="p-2 text-center">Rating</th>
                      <th className="p-2 text-center">Is Active</th>
                      <th className="p-2 text-center">Action</th>
                    </tr>
                  </thead>
                  <tbody className="text-center">
                    {data && data.length > 0 ? (
                      data.map((item, id) => {
                        return (
                          <tr key={id}>
                            <td>{item.sortOrder}</td>
                            <td>{item.name}</td>
                            <td>
                              <img
                                src={ImageLink(item.testimonial_image)}
                                alt=""
                                width="50"
                                height="50"
                              />
                            </td>
                            <td>  {Array.from({ length: item.rating }).map((_, index) => (
                              <li key={index} className="list-inline-item fs-14 text-primary mr-0">
                                <div className="menu-icon bi bi-star-fill text-warning" />
                              </li>
                            ))}
                              {Array.from({ length: 5 - item.rating }).map((_, index) => (
                                <li key={index} className="list-inline-item fs-14 text-primary mr-0">
                                  <div className="menu-icon bi bi-star" />
                                </li>
                              ))}</td>
                            <td>
                              <div className="custom-control custom-switch">
                                <input
                                  type="checkbox"
                                  className="custom-control-input"
                                  id={`customSwitch_${id}`}
                                  checked={item.isActive}
                                  onChange={() => handleCheckboxChange(item._id, !item.isActive)}
                                />
                                <label className="custom-control-label" htmlFor={`customSwitch_${id}`} />
                              </div>
                            </td>
                            <td>
                              <button type="Submit" className="btn-sm btn-outline-info mx-2" onClick={(event) => { toComponentB(item, event); }}
                              >
                                <i className="dw dw-edit2 mx-2 "></i>Edit
                              </button>
                              <button type="Submit" className="btn-sm btn-outline-danger mx-2" onClick={() => handleDelete(item._id)}>
                                <i className="dw dw-delete-3 mx-e"></i> Delete
                              </button>
                            </td>
                          </tr>
                        )
                      })
                    ) : (
                      <tr>
                        <td colSpan="7" className="text-center py-2">No data found</td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
            )}
          </div>
        </div>
      </div>
          <DeleteConfirmationModal
        isOpen={modalOpen}
        toggle={() => setModalOpen(!modalOpen)}
        onConfirm={confirmDelete}
        itemName="testimonial"
   />
    </>
  );
};

export default Testimonial;

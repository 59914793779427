import React, { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import axios from 'axios';

const SecoSetting = () => {
    const baseurl = process.env.REACT_APP_API_BASE_URL;

    const { register, handleSubmit, reset } = useForm({});
    const [data, setData] = useState([])
    const [loading, setLoading] = useState(false)



    const onSubmit = async (data) => {
        setLoading(true);

        let accessToken = localStorage.getItem('accessToken');
        let headersList = {
            "Accept": "*/*",
            "Authorization": `Bearer ${accessToken}`
        }
        let reqOptions = {
            url: `${baseurl}/api/admin/seo`,
            method: "POST", 
            headers: headersList,
            data: data, 
        };
        if (data._id) {
            delete data._id;
            delete data.createdAt;
            delete data.updatedAt;
            delete data.__v;
        }

        try {
            let response = await axios.request(reqOptions);
            if (response.data.status === 1) {
                toast.success(response.data.message)
            }
            setLoading(false);
        } catch (error) {
            toast.error(error.response.data.originalError)
            setLoading(false);
        }
    };

    useEffect(() => {
        const fetchData = async () => {
            try {
                let accessToken = localStorage.getItem('accessToken');
                let headersList = {
                    "Accept": "*/*",
                    "Authorization": `Bearer ${accessToken}`
                }
                const response = await
                    axios.get(
                        `${baseurl}/api/seo`, {
                        headers: headersList,
                    }
                    );
                setData(response.data.document);
            } catch (error) {
                toast.error(error);
            }
        };

        fetchData();
    }, [baseurl]);

    useEffect(() => {
        reset(data[0])
    }, [data, reset])

    return (
        <>
            <div className="main-container">
                <div className="xs-pd-20-10 pd-ltr-20">
                    <div className="title pb-20">
                        <h2 className="h3 mb-0">Analytics & SEO</h2>
                    </div>
                    <div className="row pb-10">
                        <div className=" col-md-12 mb-20">
                            <form onSubmit={handleSubmit(onSubmit)}>
                                <div className="card-box height-100-p widget-style3 p-4">

                                    <div className="form-group">
                                        <label>Google Analytics Code (Note : Please remove script tag and Only under js code putting)</label>
                                        <textarea {...register("google_analytics_code")}
                                            className="form-control" defaultValue={""} />
                                    </div>
                                    <div className="form-group">
                                        <label>Facebook Pixel.code</label>
                                        <textarea {...register("facebook_pixel_code")}
                                            className="form-control" defaultValue={""} />
                                    </div>
                                    <div className="inquirylist text-left">
                                        <button type="submit" className="btn-sm custom-btn" disabled={loading}
                                        >
                                            Save
                                        </button>
                                    </div>
                                </div>

                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default SecoSetting;
import React, { useEffect, useMemo, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import { ImageLink } from "../constants";
import { CommonEditor } from "../component/CommonEditor";
import { newseventApi } from "../Redux/newsSlice";

const url = process.env.REACT_APP_API_BASE_URL;

const getFileExtension = (url) => {
  const path = new URL(url).pathname;
  const extension = path.split(".").pop();
  return extension;
};

const Addnewsevent = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const data = useMemo(() => location.state?.data || {}, [location.state]);
  const sortOrder = data.sortOrder;
  const isEditing = data._id || "";
  const [loading, setLoading] = useState(false);
  const [previewFile, setPreviewFile] = useState(null);
  const [fileExtension, setFileExtension] = useState("");

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      const extension = file.name.split(".").pop();
      setFileExtension(extension);
      const reader = new FileReader();
      reader.onloadend = () => {
        setPreviewFile(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };

  const {
    register,
    handleSubmit,
    reset,
    setValue,
    formState: { errors },
  } = useForm({});

  const onSubmit = async (formData) => {
    setLoading(true);
    const payload = new FormData();

    if (data.event_image instanceof File) {
      payload.append("event_image", data.event_image);
    } else if (previewFile) {
      const extension = fileExtension || getFileExtension(previewFile);
      const blob = await fetch(previewFile).then((res) => res.blob());
      payload.append("event_image", blob, `previewFile.${extension}`);
    }

    payload.append("title", formData.title);
    payload.append("sortOrder", formData.sortOrder);
    payload.append("eventDate", formData.eventDate);
    payload.append("description", formData.description);
    payload.append("isActive", formData.isActive);
    payload.append("subTitle", formData.subTitle);

    if (isEditing) {
      payload.append("id", isEditing);
    }

    try {
      let accessToken = localStorage.getItem("accessToken");
      let headersList = {
        Accept: "*/*",
        Authorization: `Bearer ${accessToken}`,
      };

      const response = await fetch(`${url}/api/admin/event`, {
        method: "POST",
        body: payload,
        headers: headersList,
      });
      const responseData = await response.json();

      if (responseData.status === 1) {
        toast.success(responseData.message);
        reset();
        navigate("/news-event");
      } else {
        toast.error(responseData.originalError);
      }
    } catch (error) {
      toast.error("Error submitting form. Please try again.");
    } finally {
      setLoading(false);
    }
  };

  const dispatch = useDispatch();
  const newsevents = useSelector(
    (state) => state.newsevent.user?.document || []
  );

  useEffect(() => {
    dispatch(newseventApi());
  }, [dispatch]);

  const formattedEventDate = data.eventDate
    ? new Date(data.eventDate).toISOString().split("T")[0]
    : "";

  useEffect(() => {
    reset(data);
    setValue("eventDate", formattedEventDate);
    setPreviewFile(ImageLink(data.event_image));
  }, [data, setValue, formattedEventDate, reset]);

  return (
    <>
      <div className="main-container">
        <div className="xs-pd-20-10 pd-ltr-20">
          <div className="title pb-20">
            <h2 className="h3 mb-0"> Add News Event</h2>
          </div>
          <div className="row">
            <div className="col-md-12 col-sm-12 mb-30">
              <div className="card-box mb-30">
                <div className="pd-20 ">
                  <form onSubmit={handleSubmit(onSubmit)}>
                    <div className="form-group row">
                      <label className="col-sm-12 col-md-2 col-form-label">
                        Title <span className="text-danger">*</span>
                      </label>
                      <div className="col-sm-12 col-md-10">
                        <input
                          type="text"
                          placeholder="Enter title"
                          className={`form-control ${errors.title ? "is-invalid" : ""
                            }`}
                          {...register("title", { required: true })}
                        />
                        {errors.title && (
                          <small className="text-danger">
                            News title is required
                          </small>
                        )}
                      </div>
                    </div>
                    <div className="form-group row">
                      <label className="col-sm-12 col-md-2 col-form-label">
                        Sub Title
                      </label>
                      <div className="col-sm-12 col-md-10">
                        <input
                          type="text"
                          placeholder="Enter sub title"
                          className={`form-control ${errors.subTitle ? "is-invalid" : ""
                            }`}
                          {...register("subTitle")}
                        />
                      </div>
                    </div>
                    <div className="form-group row">
                      <label className="col-sm-12 col-md-2 col-form-label">
                        Description
                      </label>
                      <div className="col-md-10 ">
                        <div className="quill-editor-container">
                          <CommonEditor
                            className={`form-control ${errors.description ? "is-invalid" : ""
                              }`}
                            {...register("description")}
                            placeholder="Enter description ..."
                            onChange={(value) =>
                              setValue("description", value, {
                                shouldValidate: true,
                              })
                            }
                            value={data.description}
                          />
                          {errors.description && (
                            <p className="error text-danger">
                              News description is required
                            </p>
                          )}
                        </div>
                      </div>
                    </div>
                    <div className="form-group row">
                      <label className="col-sm-12 col-md-2 col-form-label">
                        Date <span className="text-danger">*</span>
                      </label>
                      <div className="col-sm-12 col-md-10">
                        <input
                          type="date"
                          className={`form-control ${errors.eventDate ? "is-invalid" : ""
                            }`}
                          {...register("eventDate", { required: true })}
                          defaultValue={formattedEventDate}
                        />
                        {errors.eventDate && (
                          <small className="text-danger">
                            News date is required
                          </small>
                        )}
                      </div>
                    </div>
                    <div className="form-group row">
                      <label className="col-sm-12 col-md-2 col-form-label">
                        Sort Order <span className="text-danger">*</span>
                      </label>
                      <div className="col-sm-12 col-md-10">
                        <input
                          type="text"
                          placeholder="Enter sort order"
                          className={`form-control ${errors.sortOrder ? "is-invalid" : ""
                            }`}
                          {...register("sortOrder", { required: true })}
                          defaultValue={
                            sortOrder ? sortOrder : newsevents.length + 1
                          }
                        />
                        {errors.sortOrder && (
                          <small className="text-danger">
                            Sort order is required
                          </small>
                        )}
                      </div>
                    </div>
                    <div className="form-group row mb-4">
                      <label className="col-sm-12 col-md-2 col-form-label">
                        News Image <span className="text-danger">*</span>
                      </label>
                      <div className="col-md-10 ">
                        <input
                          className={`form-control ${!isEditing && errors.event_image ? "is-invalid" : ""
                            }`}
                          {...register("event_image", { required: !isEditing })}
                          type="file"
                          onChange={(event) => handleFileChange(event)}
                        />
                        {previewFile && (
                          <div className="">
                            {previewFile ? (
                              <img
                                src={previewFile}
                                alt="Preview"
                                className="preview-image"
                                style={{ maxWidth: "50%" }}
                              />
                            ) : (
                              <p className="mx-2">No image selected</p>
                            )}
                          </div>
                        )}
                        {!isEditing && errors.event_image && (
                          <small className="text-danger">
                            News image is required
                          </small>
                        )}
                      </div>
                    </div>

                    <div className="form-group row mb-4">
                      <label className="col-sm-12 col-md-2 col-form-label">
                        Is Active
                      </label>
                      <div className="col-md-10">
                        <div className="custom-control custom-switch">
                          <input
                            type="checkbox"
                            className="custom-control-input"
                            id="customSwitch3"
                            {...register("isActive")}
                          />
                          <label
                            className="custom-control-label"
                            htmlFor="customSwitch3"
                          ></label>
                        </div>
                      </div>
                    </div>
                    <div className="modal-footer justify-content-center">
                      <Link to="/news-event">
                        <button
                          type="button"
                          className="btn-sm btn-secondary"
                          data-dismiss="modal"
                        >
                          Back
                        </button>
                      </Link>
                      <button
                        type="submit"
                        className="btn-sm custom-btn"
                        disabled={loading}
                      >
                        {loading ? "Loading..." : "Save"}
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Addnewsevent;

import axios from "axios";
import { createSlice } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import { handleTokenErrors } from "./handleTokenErrors";

const baseurl = process.env.REACT_APP_API_BASE_URL

const brandProductSlice = createSlice({
    name: "brandProduct",
    initialState: {
        isAuthenticated: false,
        user: null,
        error: null,
        loading: false,
        status: true,
    },
    reducers: {
        brandProductPending: (state) => {
            state.loading = true;
        },
        brandProductSuccess: (state, { payload }) => {
            state.loading = false;
            state.isAuthenticated = true;
            state.user = payload;
            state.status = 1;
            state.error = null;
        },
        brandProductFailure: (state, { payload }) => {
            state.loading = false;
            state.isAuthenticated = false;
            state.user = null;
            state.status = 0;
            state.error = payload;
        },
    },
});

export const { brandProductPending, brandProductSuccess, brandProductFailure } =
    brandProductSlice.actions;

export const brandProductApi = (id) => async (dispatch) => {
    dispatch(brandProductPending());
    try {
        const token = localStorage.getItem("accessToken");
        const response = await axios.get(`${baseurl}/api/getbrandproduct/${id}`,  {
            headers: {
              Authorization: `Bearer ${token}`,
              "Accept": "*/*",
            },
          });
        const data = response.data;
        if (data.status === 1) {
            toast.success(data.message);
            dispatch(brandProductSuccess(data));
        } else {
            dispatch(brandProductFailure(data));
            toast.error(data.message);
        }
    } catch (error) {
        toast.error(error.response.data.error);
        handleTokenErrors(error);
    }
};

export default brandProductSlice.reducer;

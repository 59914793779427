import React, { useCallback, useEffect, useState } from 'react'
import { useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import axios from 'axios';

const GoogleReview = () => {
    const baseurl = process.env.REACT_APP_API_BASE_URL;
    const { register, handleSubmit, reset } = useForm({});
    const [data, setData] = useState([])
    const [loading, setLoading] = useState(false)

    const onSubmit = async (data) => {
        setLoading(true);
        let accessToken = localStorage.getItem('accessToken');
        let headersList = {
            "Accept": "*/*",
            "Authorization": `Bearer ${accessToken}`
        }
        let reqOptions = {
            url: `${baseurl}/api/admin/googlereview`,
            method: "POST", 
            headers: headersList,
            data: data, 
        };
        if (data._id) {
            delete data?._id
            delete data.createdAt;
            delete data.updatedAt;
            delete data.__v;
        }

        try {
            let response = await axios.request(reqOptions);
            if (response.data.status === 1) {
                toast.success(response.data.message)
            }
            setLoading(false);
        } catch (error) {
            toast.error(error.response.data.originalError)
            setLoading(false);
        }
    };

    const fetchData = useCallback(async () => {
        try {
            let accessToken = localStorage.getItem('accessToken');
            let headersList = {
                "Accept": "*/*",
                "Authorization": `Bearer ${accessToken}`
            }
            const response = await
                axios.get(
                    `${baseurl}/api/googlereview`, {
                    headers: headersList,
                }
                );
            setData(response.data.document);
        } catch (error) {
            toast.error(error);
        }
    }, [baseurl]);

    useEffect(() => {
        fetchData();
    }, [fetchData]);

    useEffect(() => {
        reset(data?.[0])
    }, [data, reset])

    return (
        <>
            <div className="main-container">
                <div className="xs-pd-20-10 pd-ltr-20">
                    <div className="title pb-20">
                        <h2 className="h3 mb-0">Google Review </h2>
                    </div>
                    <div className="row pb-10">
                        <div className=" col-md-12 mb-20">
                            <form onSubmit={handleSubmit(onSubmit)}>
                                <div className="card-box height-100-p widget-style3 p-4">
                                    <div className="form-group">
                                        <label>Google script</label>
                                        <textarea {...register("googlereview")}
                                            className="form-control" defaultValue={""} />
                                    </div>
                                    <div className="form-group">
                                        <div className="custom-control custom-checkbox mb-5">

                                            <input
                                                type="checkbox"
                                                className="custom-control-input my-5 mx-2"
                                                id="customCheck3"
                                                {...register("isActive")}
                                            />
                                            <label
                                                className="custom-control-label"
                                                htmlFor="customCheck3">
                                                Is Active
                                            </label>
                                        </div>
                                    </div>
                                    <div className="inquirylist text-left">
                                        <button type="submit" className="btn-sm custom-btn" disabled={loading}>
                                           Save
                                        </button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default GoogleReview

import React, { useCallback, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import Select from "react-select";
import axios from "axios";
import { ImageLink } from "../constants";

const getFileExtension = (url) => {
  const path = new URL(url).pathname;
  const extension = path.split(".").pop();
  return extension;
};

const AddPage = () => {
  const baseurl = process.env.REACT_APP_API_BASE_URL;
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    setValue,
  } = useForm({});

  const location = useLocation();
  const navigate = useNavigate();
  const data = location.state?.data || {};
  const isEditing = data._id || "";
  const [selectedOptions, setSelectedOptions] = useState(
    data?.contentId?.map((item) => ({ value: item._id, label: item.title })) ||
    []
  );
  const [contentData, setContentData] = useState([]);
  const [previewImage1, setPreviewImage1] = useState("");
  const [previewImage2, setPreviewImage2] = useState("");
  const [fileExtension, setFileExtension] = useState("");

  const handleFileChange = (event, setPreviewImage) => {
    const file = event.target.files[0];
    if (file) {
      const extension = file.name.split(".").pop();
      setFileExtension(extension);
      const reader = new FileReader();
      reader.onloadend = () => {
        setPreviewImage(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };

  const fetchData = useCallback(async () => {
    try {
      setLoading(true);
      const response = await axios.get(`${baseurl}/api/admin/getAllContents`);
      const { data } = response.data;
      setContentData(data);
    } catch (error) {
      toast.error("Something went wrong. Please try again!!");
    } finally {
      setLoading(false);
    }
  }, [baseurl]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  useEffect(() => {
    reset(location.state?.data);
    setPreviewImage1(ImageLink(data?.main_banner_image));
    setPreviewImage2(ImageLink(data?.background_image));
  }, [location.state?.data, reset, data?.background_image, data?.main_banner_image]);

  const [loading, setLoading] = useState(false);

  const onSubmit = async (data) => {
    let accessToken = localStorage.getItem("accessToken");
    let headersList = {
      Accept: "*/*",
      Authorization: `Bearer ${accessToken}`,
    };

    const payload = new FormData();

    try {
      if (data.main_banner_image instanceof File) {
        payload.append("main_banner_image", data.main_banner_image);
      } else if (previewImage1) {
        const extension = fileExtension || getFileExtension(previewImage1);
        const blob = await fetch(previewImage1).then((res) => res.blob());
        payload.append("main_banner_image", blob, `previewImage1.${extension}`);
      }

      if (data.background_image instanceof File) {
        payload.append("background_image", data.background_image);
      } else if (previewImage2) {
        const extension = fileExtension || getFileExtension(previewImage2);
        const blob = await fetch(previewImage2).then((res) => res.blob());
        payload.append("background_image", blob, `previewImage2.${extension}`);
      }

      payload.append("title", data.title);
      for (let i = 0; i < selectedOptions?.length; i++) {
        payload.append("contentId[]", selectedOptions[i].value);
      }
      payload.append("subTitle", data.subTitle);
      payload.append("isActive", data.isActive);

      if (isEditing) {
        payload.append("id", data._id);
      }

      let reqOptions = {
        url: `${baseurl}/api/admin/page`,
        method: "POST",
        headers: headersList,
        data: payload,
      };

      let response = await axios.request(reqOptions);

      if (response.data.status === 1) {
        toast.success(response.data.message);
        reset();
        setSelectedOptions([]);
        navigate("/page");
      } else {
        toast.error(response.data.error);
      }
    } catch (error) {
      toast.error(error.response?.data?.error || "An error occurred. Please try again.");
    }
  };

  useEffect(() => {
    register('contentId', { required: 'ContentId is required' });
  }, [register]);
  return (
    <>
      <div className="main-container">
        <div className="xs-pd-20-10 pd-ltr-20 col-md-12">
          <div className="title pb-20">
            <h2 className="h3 mb-0">Add Page</h2>
          </div>
          <div className="row">
            <div className="col-md-12 col-sm-12 mb-30">
              <div className="pd-20 card-box height-100-p">
                <div className="pd-20  ">
                  <form onSubmit={handleSubmit(onSubmit)}>
                    <div className="form-group row">
                      <label className="col-sm-12 col-md-3 col-form-label mb-4">
                        ContentId<span className="text-danger">*</span>
                      </label>
                      <div className="col-md-9 mb-4">
                        <Select
                          name="contentId"
                          placeholder="Select content id"
                          className={errors.contentId ? 'is-invalid' : ''}
                          value={selectedOptions}
                          onChange={(selected) => {
                            setSelectedOptions(selected);
                            setValue("contentId", selected, {
                              shouldValidate: true,
                            });
                          }}
                          options={contentData?.map((item) => ({
                            value: item._id,
                            label: item.title,
                          }))}
                          isMulti
                        />
                        {errors.contentId && (
                          <div className="invalid-feedback">
                            {errors.contentId.message}
                          </div>
                        )}
                      </div>
                      <label className="col-sm-12 col-md-3 col-form-label mb-4 ">
                        Title<span className="text-danger">*</span>
                      </label>
                      <div className="col-md-9 ">
                        <input
                          className={`form-control ${errors.title ? "is-invalid" : ""
                            }`}
                          {...register("title", { required: true })}
                          placeholder="Enter title"
                          type="text"
                        />
                        {errors.title && (
                          <small className="text-danger">
                            Title is required
                          </small>
                        )}
                      </div>
                      <label className="col-sm-12 col-md-3 col-form-label ">
                        Sub Title<span className="text-danger">*</span>
                      </label>
                      <div className="col-md-9 ">
                        <input
                          className={`form-control ${errors.subTitle ? "is-invalid" : ""
                            }`}
                          {...register("subTitle", { required: true })}
                          placeholder="Enter title"
                          type="text"
                        />
                        {errors.subTitle && (
                          <small className="text-danger">
                            Sub title is required
                          </small>
                        )}
                      </div>
                    </div>

                    <div className="form-group row">
                      <label className="col-sm-12 col-md-3 col-form-label">
                        Banner Image
                      </label>
                      <div className="col-md-9">
                        <input
                          className="form-control"
                          {...register("main_banner_image")}
                          type="file"
                          onChange={(event) =>
                            handleFileChange(event, setPreviewImage1)
                          }
                        />
                        {previewImage1 && (
                          <div className="mb-3 mt-3">
                            <img
                              src={previewImage1}
                              alt=""
                              className="preview-image w-50"
                            />
                            <button type="button" className="close-btn" onClick={() => setPreviewImage1('')}>
                              X
                            </button>
                          </div>
                        )}
                        <small className="form-text text-muted">
                          Required image size: 1444 X 373 (.png, .jpg, .jpeg,
                          .webp)
                        </small>
                      </div>
                    </div>

                    <div className="form-group row mb-4">
                      <label className="col-sm-12 col-md-3 col-form-label">
                        Background Image
                      </label>
                      <div className="col-md-9 mb-4">
                        <input
                          className="form-control"
                          {...register("background_image")}
                          type="file"
                          onChange={(event) =>
                            handleFileChange(event, setPreviewImage2)
                          }
                        />
                        {previewImage2 && (
                          <div className="mb-3 mt-3">
                            <img
                              src={previewImage2}
                              alt=""
                              className="preview-image w-50"
                            />
                            <button type="button" className="close-btn" onClick={() => setPreviewImage2('')}>
                              X
                            </button>
                          </div>
                        )}
                        <small className="form-text text-muted">
                          Required image size: 1920 X 516 (.png, .jpg, .jpeg,
                          .webp)
                        </small>
                      </div>
                    </div>

                    <div className="form-group row mb-4">
                      <label className="col-sm-12 col-md-3 col-form-label">
                        Is Active
                      </label>
                      <div className="col-md-9">
                        <div className="custom-control custom-switch">
                          <input
                            type="checkbox"
                            className="custom-control-input"
                            id="customSwitch3"
                            {...register("isActive")}
                          />
                          <label
                            className="custom-control-label"
                            htmlFor="customSwitch3"
                          ></label>
                        </div>
                      </div>
                    </div>

                    <div className="modal-footer justify-content-center">
                      <Link to="/page">
                        <button
                          type="button"
                          className="btn-sm btn-secondary"
                          data-dismiss="modal"
                        >
                          Back
                        </button>
                      </Link>
                      <button
                        type="submit"
                        className="btn-sm custom-btn"
                        disabled={loading}
                      >
                        {loading ? "Loading..." : "Save"}
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AddPage;

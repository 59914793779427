

import axios from "axios";
import { toast } from "react-toastify";
import { handleTokenErrors } from "./handleTokenErrors";
const { createSlice } = require("@reduxjs/toolkit");

const baseurl = process.env.REACT_APP_API_BASE_URL;
const authSlice = createSlice({
  name: "auth",
  initialState: {
    isAuthenticated: false,
    user: null,
    error: null,
    loading: false,
    token: null,
    accessToken: null,
    isSuccess: false,
  },
  reducers: {
    signInPending: (state) => {
      state.loading = true;
    },
    signInSuccess: (state, { payload }) => {
      state.loading = false;
      state.isAuthenticated = true;
      state.isSuccess = true;
      state.user = payload;
      state.token = payload.token;
      state.accessToken = payload.accessToken;
      state.error = null;

      localStorage.setItem("token", state.token);
      localStorage.setItem("accessToken", state.accessToken);
    },
    signInFailure: (state, { payload }) => {
      state.loading = false;
      state.isAuthenticated = false;
      state.user = null;
      state.error = payload;
    },
    resetAuth: (state) => {
      state.isAuthenticated = false;
      state.user = null;
      state.error = null;
      state.loading = false;
      state.token = null;
    },
  },
});

export const { resetAuth } = authSlice.actions;

export const resetAuthAction = (formData) => async (dispatch) => {
  dispatch(resetAuth());
};

export const { signInPending, signInSuccess, signInFailure } =
  authSlice.actions;

export const signIn = (formData) => async (dispatch) => {
  dispatch(signInPending());
  try {
    const response = await axios.post(
      `${baseurl}/api/admin/login`,
      formData
    );
    const data = response.data;

    if (data.status === 1) {
      dispatch(signInSuccess(data));
      toast.success(data.message);
    } else {
      toast.error(data.error);     
      dispatch(signInFailure(data));
    }
  } catch (error) {
    handleTokenErrors(error);   
    toast.error(error.response.data.error)
    dispatch(signInFailure(error.response.data));
  }
};

export default authSlice.reducer;

import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { contactApi } from "../Redux/contactSlice";
import { useDispatch } from "react-redux";
import axios from "axios";
import Loader from "../Layout/Loader";
const url = process.env.REACT_APP_API_BASE_URL;

const Backofficesetting = () => {
  const dispatch = useDispatch();
  const [data, setData] = useState("");
  const [loading, setLoading] = useState(false);

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({});

  const onSubmit = (data) => {
    if (data._id) {
      delete data._id;
      delete data.createdAt;
      delete data.updatedAt;
      delete data.__v;
      dispatch(contactApi(data));
    } else {
      dispatch(contactApi(data));
    }
  };

  const fetchData = async () => {
    try {
      setLoading(true);
      let accessToken = localStorage.getItem("accessToken");
      let headersList = {
        Accept: "*/*",
        Authorization: `Bearer ${accessToken}`,
      };
      const response = await axios.get(`${url}/api/admin/contact`, {
        headers: headersList,
      });
      setData(response.data.document);
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    reset(data[0]);
  }, [data, reset]);

  return (
    <>
      {loading ? (
        <Loader />
      ) : (
        <div className="main-container">
          <div className="xs-pd-20-10 pd-ltr-20 col-md-12">
            <div className="title pb-20">
              <h2 className="h3 mb-0">Back Office </h2>
            </div>
            <div className="row">
              <div className="col-md-8 col-sm-12 mb-30">
                <div className="pd-20 card-box height-100-p">
                  <div className="pd-20  ">
                    <form onSubmit={handleSubmit(onSubmit)}>
                      <div className="form-group row">
                        <label className="col-sm-12 col-md-4 col-form-label mb-4">
                          Company name <span className="text-danger">*</span>
                        </label>
                        <div className="col-md-8 mb-4">
                          <input
                            className={`form-control ${
                              errors.company_name ? "is-invalid" : ""
                            }`}
                            {...register("company_name", { required: true })}
                            placeholder="compnay name"
                            type="text"
                          />
                          {errors.company_name && (
                            <small className="text-danger">
                              company name is required
                            </small>
                          )}
                        </div>
                        <label className="col-sm-12 col-md-4 col-form-label mb-4">
                          Office contact no
                          <span className="text-danger">*</span>
                        </label>
                        <div className="col-md-8 mb-4">
                          <input
                            className={`form-control ${
                              errors.office_contact_no ? "is-invalid" : ""
                            }`}
                            {...register("office_contact_no", {
                              required: true,
                            })}
                            placeholder="office contact no"
                            type="text"
                          />
                          {errors.office_contact_no && (
                            <small className="text-danger">
                              office contact no is required
                            </small>
                          )}
                        </div>{" "}
                        <label className="col-sm-12 col-md-4 col-form-label mb-4">
                          Whatsapp no.<span className="text-danger">*</span>
                        </label>
                        <div className="col-md-8 mb-4">
                          <input
                            className={`form-control ${
                              errors.whatsup_no ? "is-invalid" : ""
                            }`}
                            {...register("whatsup_no", { required: true })}
                            placeholder="whatsapp number"
                            type="tel"
                          />
                          {errors.whatsup_no && (
                            <small className="text-danger">
                              whatsapp no. is required
                            </small>
                          )}
                        </div>
                        <label className="col-sm-12 col-md-4 col-form-label mb-4">
                          Email<span className="text-danger">*</span>
                        </label>
                        <div className="col-md-8 mb-4">
                          <input
                            className={`form-control ${
                              errors.email ? "is-invalid" : ""
                            }`}
                            {...register("email", { required: true })}
                            placeholder="email "
                            type="email"
                          />
                          {errors.email && (
                            <small className="text-danger">
                              email is required
                            </small>
                          )}
                        </div>
                      </div>

                      <div className="modal-footer justify-content-center">
                        <button type="submit" className="btn-sm custom-btn">
                          Save
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default Backofficesetting;

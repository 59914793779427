import axios from "axios";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import { useLocation, useNavigate } from "react-router-dom";

const AddSocialMedia = () => {
  const baseurl = process.env.REACT_APP_API_BASE_URL;
  const navigate = useNavigate();
  const location = useLocation();
  const data = location.state?.data;
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm({});
  const [loading, setLoading] = useState(false);

  const onSubmit = async (formData) => {
    setLoading(true);
    try {
      let accessToken = localStorage.getItem("accessToken");
      let headersList = {
        Accept: "*/*",
        Authorization: `Bearer ${accessToken}`,
        "Content-Type": "application/json",
      };

      let bodyContent = {
        name: formData.name,
        icon: formData.icon,
        url: formData.url,
        isActive: formData.isActive,
      };

      if (data && data._id) {
        bodyContent.id = data._id;
      }

      let reqOptions = {
        url: `${baseurl}/api/admin/socialmedia`,
        method: "POST",
        headers: headersList,
        data: bodyContent,
      };

      const response = await axios.request(reqOptions);

      if (response.status === 200 && response.data.status === 1) {
        toast.success(response.data.message);
        reset();
        navigate("/social-media");
      } else {
        toast.error(response.data.originalError);
      }
    } catch (error) {
      toast.error("Error submitting form. Please try again.");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    reset(data);
  }, [data, reset]);

  return (
    <>
      <div className="main-container">
        <div className="xs-pd-20-10 pd-ltr-20 col-md-12">
          <div className="title pb-20">
            <h2 className="h3 mb-0">Add Social Media</h2>
          </div>
          <div className="row">
            <div className="col-md-8 col-sm-12 mb-30">
              <div className="pd-20 card-box height-100-p">
                <div className="pd-20  ">
                  <form onSubmit={handleSubmit(onSubmit)}>
                    <div className="form-group row mb-4">
                      <label className="col-sm-12 col-md-3 col-form-label">
                        Name <span className="text-danger">*</span>
                      </label>
                      <div className="col-md-9">
                        <input
                          className={`form-control ${
                            errors.name ? "is-invalid" : ""
                          }`}
                          {...register("name", { required: true })}
                          placeholder="Enter name"
                          type="text"
                        />
                        {errors.name && (
                          <small className="text-danger">
                            Name is required
                          </small>
                        )}
                      </div>
                    </div>
                    <div className="form-group row mb-4">
                      <label className="col-sm-12 col-md-3 col-form-label ">
                        Icon <span className="text-danger">*</span>
                      </label>
                      <div className="col-md-9 ">
                        <input
                          className={`form-control ${
                            errors.icon ? "is-invalid" : ""
                          }`}
                          {...register("icon", { required: true })}
                          placeholder="Enter icon"
                          type="text"
                        />
                        {errors.icon && (
                          <small className="text-danger">
                            Icon is required
                          </small>
                        )}
                      </div>
                    </div>
                    <div className="form-group row mb-4">
                      <label className="col-sm-12 col-md-3 col-form-label">
                        Url <span className="text-danger">*</span>
                      </label>
                      <div className="col-md-9 ">
                        <input
                          className={`form-control ${
                            errors.url ? "is-invalid" : ""
                          }`}
                          {...register("url", { required: true })}
                          placeholder="Enter url"
                          type="text"
                        />
                        {errors.url && (
                          <small className="text-danger">Url is required</small>
                        )}
                      </div>
                    </div>
                    <div className="form-group row mb-1">
                      <label className="col-sm-12 col-md-3 col-form-label">
                        Is Active
                      </label>
                      <div className="col-md-9">
                        <div className="custom-control custom-switch">
                          <input
                            type="checkbox"
                            className="custom-control-input"
                            id="customSwitch3"
                            {...register("isActive")}
                          />
                          <label
                            className="custom-control-label"
                            htmlFor="customSwitch3"
                          ></label>
                        </div>
                      </div>
                    </div>

                    <div className="modal-footer justify-content-center">
                      <button
                        type="submit"
                        className="btn-sm custom-btn"
                        disabled={loading}
                      >
                        Save
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AddSocialMedia;

import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import { ImageLink } from "../constants";
import axios from "axios";

const getFileExtension = (url) => {
  const path = new URL(url).pathname;
  const extension = path.split(".").pop();
  return extension;
};


const Addweblogo = () => {
  const baseurl = process.env.REACT_APP_API_BASE_URL;

  const [loading, setLoading] = useState(false);
  const [previewImage1, setPreviewImage] = useState("");
  const [footerImage, setFooterImage] = useState("");
  const [mobileImage, setMobileImage] = useState("");
  const [fileExtension, setFileExtension] = useState("");

  const handleFileChange = (event, setPreviewImage) => {
    const file = event.target.files[0];
    if (file) {
      const extension = file.name.split(".").pop();
      setFileExtension(extension);
      const reader = new FileReader();
      reader.onloadend = () => {
        setPreviewImage(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({});
  const [data, setData] = useState("");

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        let accessToken = localStorage.getItem("accessToken");
        let headersList = {
          Accept: "*/*",
          Authorization: `Bearer ${accessToken}`,
        };
        const response = await axios.get(`${baseurl}/api/admin/logo`, {
          headers: headersList,
        });
        setData(response?.data?.document);
        setLoading(false);
      } catch (error) {
        setLoading(false);
      }
    };
    fetchData();
  }, [baseurl]);

  const onSubmit = async (data) => {
    setLoading(true);
    const formData = new FormData();

    if (data.logo_image instanceof File) {
      formData.append("logo_image", data.logo_image);
    } else if (previewImage1) {
      const extension = fileExtension || getFileExtension(previewImage1);
      const blob = await fetch(previewImage1).then((res) => res.blob());
      formData.append("logo_image", blob, `previewImage1.${extension}`);
    }

    if (data.footer_logo_image instanceof File) {
      formData.append("footer_logo_image", data.footer_logo_image);
    } else if (footerImage) {
      const extension = fileExtension || getFileExtension(footerImage);
      const blob = await fetch(footerImage).then((res) => res.blob());
      formData.append("footer_logo_image", blob, `previewImage.${extension}`);
    }

    if (data.mobile_logo_image instanceof File) {
      formData.append("mobile_logo_image", data.mobile_logo_image);
    } else if (mobileImage) {
      const extension = fileExtension || getFileExtension(mobileImage);
      const blob = await fetch(mobileImage).then((res) => res.blob());
      formData.append("mobile_logo_image", blob, `mobileImage.${extension}`);
    }

    formData.append("showInHeader", data.showInHeader);
    formData.append("showInFooter", data.showInFooter);

    try {
      let accessToken = localStorage.getItem("accessToken");
      let headersList = {
        Accept: "*/*",
        Authorization: `Bearer ${accessToken}`,
      };
      const response = await fetch(`${baseurl}/api/admin/logo`, {
        method: "POST",
        body: formData,
        headers: headersList,
      });
      const responseData = await response.json();
      if (responseData.status === 1) {
        toast.success(responseData.message);
        reset();
      } else {
        toast.error(responseData.error);
      }
    } catch (error) {
      toast.error("Error submitting form. Please try again.");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    const initialData = data[0];
    reset(initialData);
    setPreviewImage(ImageLink(initialData?.logo_image));
    setFooterImage(ImageLink(initialData?.footer_logo_image));
    setMobileImage(ImageLink(initialData?.mobile_logo_image));
  }, [data, reset]);


  return (
    <>
      <div className="main-container">
        <div className="xs-pd-20-10 pd-ltr-20 col-md-12">
          <div className="title pb-20">
            <h2 className="h3 mb-0 mx-1">Logo </h2>
          </div>
          <div className="row">
            <div className="col-md-8 col-sm-12 mb-30">
              <div className="pd-20 card-box height-100-p">
                <div className="pd-20  ">
                  <form onSubmit={handleSubmit(onSubmit)}>
                    <div className="form-group row">
                      <label className="col-sm-12 col-md-4 mb-4 col-form-label">
                        Web Logo
                      </label>
                      <div className="col-md-8 mb-4">
                        <input
                          className={`form-control`}
                          {...register("logo_image")}
                          type="file"
                          onChange={(event) =>
                            handleFileChange(event, setPreviewImage)
                          }
                        />
                        {errors.logo_image && (
                          <small className="text-danger">Image required</small>
                        )}
                        <small className="form-text text-muted">
                          Required image size: 174 X 89 (.png, .jpg, .jpeg, .webp)
                        </small>
                      </div>
                      <label className="col-sm-12 col-md-4 mb-4 col-form-label">
                        Footer Logo
                      </label>
                      <div className="col-md-8 mb-4">
                        <input
                          className={`form-control`}
                          {...register("footer_logo_image")}
                          type="file"
                          onChange={(event) =>
                            handleFileChange(event, setFooterImage)
                          }
                        />
                        {errors.footer_logo_image && (
                          <small className="text-danger">Image required</small>
                        )}
                        <small className="form-text text-muted">
                          Required image size: 225 X 114 (.png, .jpg, .jpeg, .webp)
                        </small>
                      </div>
                      <label className="col-sm-12 col-md-4 mb-4 col-form-label">
                        Mobile Logo
                      </label>
                      <div className="col-md-8 mb-4">
                        <input
                          className={`form-control`}
                          {...register("mobile_logo_image")}
                          type="file"
                          onChange={(event) =>
                            handleFileChange(event, setMobileImage)
                          }
                        />
                        {errors.footer_logo_image && (
                          <small className="text-danger">Image required</small>
                        )}
                        <small className="form-text text-muted">
                          Required image size: 142 X 183(.png, .jpg, .jpeg, .webp)
                        </small>
                      </div>

                      <label className="col-sm-12 col-md-4 col-form-label">
                        Show In Header
                      </label>
                      <div className="col-md-8">
                        <div className="custom-control custom-switch">
                          <input
                            type="checkbox"
                            className="custom-control-input"
                            id="customSwitch1"
                            {...register("showInHeader")}
                          />
                          <label
                            className="custom-control-label"
                            htmlFor="customSwitch1"
                          ></label>
                        </div>
                      </div>
                      <label className="col-sm-12 col-md-4 col-form-label">
                        Show In Footer
                      </label>
                      <div className="col-md-8">
                        <div className="custom-control custom-switch">
                          <input
                            type="checkbox"
                            className="custom-control-input"
                            id="customSwitch2"
                            {...register("showInFooter")}
                          />
                          <label
                            className="custom-control-label"
                            htmlFor="customSwitch2"
                          ></label>
                        </div>
                      </div>
                    </div>
                    <div className="modal-footer justify-content-center">                     
                      <button
                        type="submit"
                        className="btn-sm custom-btn text-center"
                        disabled={loading}
                      >
                      Save
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>

            <div className="col-md-4 col-sm-12 mb-30 text-center">
              <div className="pd-5 card-box">
                <div className="modal-header justify-content-center">
                  <h5 className="text-dark ">PREVIEW IMAGE</h5>
                </div>
                <div className="mt-2">
                  <p className="image-preview mx-2">Web Logo</p>
                  {previewImage1 ? (
                    <img
                      src={previewImage1}
                      alt="Preview"
                      className="preview-image mx-4"
                      style={{ maxWidth: "100%" }}
                    />
                  ) : (
                    <p className="mx-2">No image selected</p>
                  )}
                </div>
                <hr />
                <div className="mt-2">
                  <p className="image-preview mx-2">Footer Logo</p>
                  {footerImage ? (
                    <img
                      src={footerImage}
                      alt="Preview"
                      className="preview-image mx-2 bg-secondary"
                      style={{ maxWidth: "100%" }}
                    />
                  ) : (
                    <p className="mx-2">No image selected</p>
                  )}
                </div>

                <hr />
                <div className="mt-2">
                  <p className="image-preview mx-2">Mobile Logo</p>
                  {mobileImage ? (
                    <img
                      src={mobileImage}
                      alt="Preview"
                      className="preview-image mx-2"
                      style={{ maxWidth: "100%" }}
                    />
                  ) : (
                    <p className="mx-2">No image selected</p>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Addweblogo;

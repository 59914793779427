import DataTable from "datatables.net-dt";
import React, { useCallback, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { inquirylist } from "../Redux/getInquirylistSlice";
import Loader from "../Layout/Loader";
import axios from "axios";
import { ImageLink } from "../constants";

const CareerList = () => {
  const baseurl = process.env.REACT_APP_API_BASE_URL;
  const dispatch = useDispatch();
  const navigate = useNavigate();  
  const [loading, setLoading]=useState(true)
  const [careerList, setCareerlist]=useState([])
  const fetchData = useCallback(async () => {
    try {
        setLoading(true);
        let accessToken = localStorage.getItem('accessToken');
        let headersList = {
            "Accept": "*/*",
            "Authorization": `Bearer ${accessToken}`
        }
        const response = await axios.get(`${baseurl}/api/admin/career`, {
            headers: headersList,
        });
        setCareerlist(response.data.document);
        setLoading(false);
    } catch (error) {
        setLoading(false);
    }
}, [baseurl]);

useEffect(() => {
    fetchData();
}, [fetchData]);

  useEffect(() => {
    if (careerList && careerList?.length > 0) {
      const table = new DataTable("#myTable", {
        pageLength: 10,
        lengthChange: false,
        autoWidth: true,
        paging: true,
        info: true,
        searching: false,
        columnDefs: [
          { targets: 4, orderable: false },
        ],
      });
      return () => {
        table.destroy();
      };
    }
  }, [careerList]);

  useEffect(() => {
    dispatch(inquirylist());
  }, [dispatch]);

  const toComponentB = (data, event) => {
    event.preventDefault();
    navigate("/job-application-details", { state: { data } });
  };

  return (
    <>
      <div className="main-container">
        <div className="xs-pd-20-10 pd-ltr-20">
          <div className="title pb-20">
            <h2 className="h3 mb-0">Job Applications</h2>
          </div> 

          <div className="card-box mb-30">
            {loading ? (
              <Loader />
            ) : (
              <div className="pb-20 pd-20 table-responsive">
                <table
                  className="table-striped hover nowrap table-bordered w-100"
                  id="myTable"
                >
                  <thead>
                    <tr>
                      <th className="p-2 text-center">Name</th>
                      <th className="p-2 text-center">Email</th>
                      <th className="p-2 text-center">Mobile No</th>
                      <th className="p-2 text-center">Resume</th>
                      <th className="p-2 text-center">Post</th>
                      <th className="p-2 text-center">experiance</th>
                      <th className="p-2 text-center">Action</th>
                    </tr>
                  </thead>
                  <tbody className="text-center">
                    {careerList && careerList.length > 0 ? (
                      careerList.map((data, index) => {                 
                        return(                      
                        <tr key={index}>
                          <td>{data.first_name+ " " + data.last_name}</td>                         
                          <td>{data.email}</td>
                          <td>{data.contact_no}</td>
                          <td>
                            <Link
                              to={data.apply_cv instanceof File ? URL.createObjectURL(data.apply_cv) : ImageLink(data.apply_cv)} target="_blank">
                                                <img
                                                    src="../assets/images/pdf.png"
                                                    alt="pdf_preview"
                                                    className="ms-3 me-3 mb-3"
                                                    width={60}
                                                />
                                            </Link>
                          </td>
                          <td>{data.post}</td>
                          <td>{data.experiance}</td>
                          <td>
                            <button
                              type="Submit"
                              className="btn-sm btn-outline-info mx-2"
                              onClick={(event) => {
                                toComponentB(data, event);
                              }}
                            >
                              <i className="dw dw-eye mx-2" />
                              View
                            </button>
                            {/* <button
                              type="Submit"
                              className="btn-sm btn-outline-danger mx-2"
                              onClick={() => handleDelet(data._id)}
                            >
                              <i className="dw dw-delete-3 mx-e"></i> Delete
                            </button> */}
                          </td>
                        </tr>
                      )})
                    ) : (
                      <tr>
                        <td colSpan="7" className="text-center py-2">
                          No data found
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default CareerList;

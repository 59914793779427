import axios from "axios";
import { createSlice } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import { handleTokenErrors } from "./handleTokenErrors";

const baseurl = process.env.REACT_APP_API_BASE_URL;

const MenuSlice = createSlice({
  name: "menu",
  initialState: {
    isAuthenticated: false,
    user: null,
    error: null,
    loading: false,
    status: true,
  },
  reducers: {
    menuPending: (state) => {
      state.loading = true;
    },
    menuSuccess: (state, { payload }) => {
      state.loading = false;
      state.isAuthenticated = true;
      state.user = payload;
      state.status = 1;
      state.error = null;
    },
    menuFailure: (state, { payload }) => {
      state.loading = false;
      state.isAuthenticated = false;
      state.user = null;
      state.status = 0;
      state.error = payload;
    },
  },
});

export const { menuPending, menuSuccess, menuFailure } = MenuSlice.actions;

export const menuApi = () => async (dispatch) => {
  dispatch(menuPending());
  const token = localStorage.getItem("accessToken");
  try {
    const response = await axios.get(`${baseurl}/api/getmenu`, {
      headers: {
        Authorization: `Bearer ${token}`,
        Accept: "*/*",
      },
    });
    const data = response.data;
    if (data.status === 1) {
      toast.success(data.message);
      dispatch(menuSuccess(data));
    } else {
      dispatch(menuFailure(data));
      toast.error(data.message);
    }
  } catch (error) {
    handleTokenErrors(error);
    toast.error(error.response.data.originalError);
  }
};

export default MenuSlice.reducer;

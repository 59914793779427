import axios from "axios";
import { createSlice } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import { handleTokenErrors } from "./handleTokenErrors";
const baseurl = process.env.REACT_APP_API_BASE_URL;

const getCategoryProductSlice = createSlice({
    name: "categoryProduct",
    initialState: {
        isAuthenticated: false,
        user: null,
        error: null,
        loading: false,
        status: true,
    },
    reducers: {
        categoryProductPending: (state) => {
            state.loading = true;
        },
        categoryProductSuccess: (state, { payload }) => {
            state.loading = false;
            state.isAuthenticated = true;
            state.user = payload;
            state.status = 1;
            state.error = null;
        },
        categoryProductFailure: (state, { payload }) => {
            state.loading = false;
            state.isAuthenticated = false;
            state.user = null;
            state.status = 0;
            state.error = payload;
        },
    },
});

export const { categoryProductPending, categoryProductSuccess, categoryProductFailure } =
    getCategoryProductSlice.actions;

export const categoryProductApi = (id) => async (dispatch) => {
    const token = localStorage.getItem("accessToken");
    dispatch(categoryProductPending());
    let ids = []
    ids.push(id)
    try {
        const response = await axios.get(`${baseurl}/api/admin/getcategoryproduct/${id}`,{
            headers: {
                Authorization: `Bearer ${token}`,
                "Accept": "*/*",
            },
        });
      
        const data = response.data;
        if (data.status === 1) {
            toast.success(data.message);
            dispatch(categoryProductSuccess(data));
        } else {
            dispatch(categoryProductFailure(data));
            toast.error(data.message);
        }
    } catch (error) {
        handleTokenErrors(error);
        toast.error(error.response.data.originalError);
    }
};

export default getCategoryProductSlice.reducer;

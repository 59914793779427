import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { createSlice } from "@reduxjs/toolkit";
import { handleTokenErrors } from "./handleTokenErrors";

const baseurl = process.env.REACT_APP_API_BASE_URL;

const initialState = {
    data: [],
    isLoading: false,
    isSuccess: false,
    errorMessage: "",
};

export const categoryData = createAsyncThunk(
    "categoryData/categoryDataList",
    async (data,{ rejectWithValue }) => {
        const token = localStorage.getItem("accessToken");
        try {
            const response = await axios.post(`${baseurl}/api/admin/getAllCategory`, {}, {
                headers: {
                    Authorization: `Bearer ${token}`,
                    "Accept": "*/*",
                },
            });
            return response.data;
        } catch (error) {
            handleTokenErrors(error);
            return rejectWithValue(error.message);
        }
    }
);

export const getCategorySlice = createSlice({
    name: "category",
    initialState,
    extraReducers: {
        [categoryData.pending]: (state) => {
            state.isLoading = true;
        },
        [categoryData.fulfilled]: (state, { payload }) => {
            state.isLoading = false;
            state.isSuccess = true;
            state.data = payload;
        },
        [categoryData.rejected]: (state, { payload }) => {
            state.isLoading = false;
            state.isSuccess = false;
            state.errorMessage = payload;
        },
    },
});

export default getCategorySlice.reducer;

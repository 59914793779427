import axios from "axios";
import React, { useCallback, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import { emailtemplateApi } from "../Redux/emailTemplateSlice";
import Loader from "../Layout/Loader";
import { toast } from "react-toastify";

const EmailTemplate = () => {
  const baseurl = process.env.REACT_APP_API_BASE_URL;
  const [selectedItem, setSelectedItem] = useState(null);
  const [data, setData] = useState("");
  const dispatch = useDispatch();
  const { register, handleSubmit, formState: { errors } } = useForm({});
  const [loading, setLoading] = useState(false);

  const fetchData = useCallback(async () => {
    try {
      setLoading(true);
      let accessToken = localStorage.getItem('accessToken');
      let headersList = {
        "Accept": "*/*",
        "Authorization": `Bearer ${accessToken}`
      }
      const response = await axios.get(
        `${baseurl}/api/admin/emailtemplate`, {
        headers: headersList,
      }
      );
      setData(response.data.document);
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  }, [baseurl]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const onSubmit = async (formData) => {
    const temp = {
      subject: selectedItem?.subject || formData.subject,
      id: selectedItem?._id,
      name: selectedItem?.name || formData.name,
      description: selectedItem?.description,
      isActive: selectedItem?.isActive || formData.isActive,
    };

    if (selectedItem?._id) {
      delete selectedItem.createdAt;
      delete selectedItem.updatedAt;
      delete selectedItem.__v;
    }

    try {
      await dispatch(emailtemplateApi(temp));
      await fetchData();
    } catch (error) {
      toast.error("Error updating email template:", error);
    }
  };

  const handleSelectChange = (event) => {
    const selectedIndex = event.target.selectedIndex;
    const selectedItem = selectedIndex > 0 ? data[selectedIndex - 1] : null;
    setSelectedItem(selectedItem);
  };

  if (loading) {
    return <Loader />;
  }

  return (
    <>
      <div className="main-container">
        <div className="xs-pd-20-10 pd-ltr-20">
          <div className="title pb-20">
            <h2 className="h3 mb-0">Email Template</h2>
          </div>
          <div className="card-box mb-30">
            <div className="pd-20">
              <form onSubmit={handleSubmit(onSubmit)}>
                <div className="row mb-4">
                  <div className="col-md-6 mb-4">
                    <label className="form-label">
                      Email Template Name<span className="text-danger">*</span>
                    </label>
                    <select
                      className={`form-control ${errors.name ? "is-invalid" : ""}`}
                      name="name"
                      {...register("name", { required: true })}
                      onChange={handleSelectChange}
                    >
                      <option value="">-- Select --</option>
                      {data && data.map((item, id) => (
                        <option key={id} value={item.name}>{item.name}</option>
                      ))}
                    </select>
                    {errors.name && (
                      <small className="text-danger">
                        Please Select Email Template Name
                      </small>
                    )}
                  </div>

                  {selectedItem &&
                    <>
                      <div className="col-md-6 mb-4">
                        <label className="form-label">
                          Subject<span className="text-danger">*</span>
                        </label>
                        <input
                          type="text"
                          className={`form-control ${errors.subject ? "is-invalid" : ""}`}
                          name="subject"
                          {...register("subject", { required: !selectedItem?.subject })}
                          value={selectedItem?.subject || ""}
                          onChange={(e) => setSelectedItem({ ...selectedItem, subject: e.target.value })}
                        />
                        {errors.subject && (
                          <small className="text-danger">
                            Please Enter a Subject
                          </small>
                        )}
                      </div>

                      <div className="col-md-12 mb-4">
                        <label className="form-label">
                          Email Template Content<span className="text-danger">*</span>
                        </label>
                        <textarea
                          className={`form-control templateimg ${errors.description ? "is-invalid" : ""}`}
                          name="description"
                          {...register("description", { required: !selectedItem?.description })}
                          value={selectedItem?.description || ""}
                          onChange={(e) => setSelectedItem({ ...selectedItem, description: e.target.value })}
                        />
                        {errors.description && (
                          <small className="text-danger">
                            Please Enter an Email Template Content
                          </small>
                        )}
                      </div> </>}
                </div>

                {selectedItem && <div className="modal-footer justify-content-center">
                  <button type="submit" className="btn-sm custom-btn">
                    Save
                  </button>
                </div>}
              </form>
            </div>
          </div>
        </div>
        <div className="xs-pd-20-10 pd-ltr-20">
          <div className="card-box mb-30">
            <p className="mx-2" dangerouslySetInnerHTML={{ __html: selectedItem?.description }} />
          </div>
        </div>
      </div>
    </>
  );
};

export default EmailTemplate;
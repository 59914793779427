import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import DataTable from "datatables.net-dt";
import { useDispatch, useSelector } from "react-redux";
import { searchlist } from "../Redux/getSearchlistSlice";
import axios from "axios";
import Loader from "../Layout/Loader";
import { toast } from "react-toastify";
import Select from "react-select";
import { ImageLink } from "../constants";
import { productApi } from "../Redux/productSlice";
import DeleteConfirmationModal from "../component/DeleteConfirmationModal";

const baseurl = process.env.REACT_APP_API_BASE_URL;

const Searchlist = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const searchlists = useSelector(
    (state) => state?.Getsearchlist.data?.document?.data || []
  );
  const loading = useSelector((state) => state.Getsearchlist?.isLoading);
  const [deleteItemId, setDeleteItemId] = useState(null);
  const [modalOpen, setModalOpen] = useState(false);
  const [productName, setProductName] = useState("");
  const [metalName, setMetalName] = useState(null);
  const [color, setColor] = useState(null);
  const [categories, setCategories] = useState([]);
  const [sku, setSku] = useState("");
  const [isFreezed, setIsFreezed] = useState(null);
  const [data, setData] = useState([]);
  const [selectedCategories, setSelectedCategories] = useState([]);
  const [colorsData, setColorsData] = useState([]);
  const [metalData, setMetalData] = useState([]);

  useEffect(() => {
    if (searchlists && searchlists?.length > 0) {
      const table = new DataTable("#myTable", {
        pageLength: 10,
        lengthChange: false,
        autoWidth: true,
        paging: true,
        info: true,
        searching: false,
        columnDefs: [
          { targets: 1, orderable: false },
          { targets: 2, orderable: false },
          { targets: 3, orderable: false },
        ],
      });
      return () => {
        table.destroy();
      };
    }
  }, [searchlists, loading]);

  useEffect(() => {
    dispatch(
      searchlist({
        category: categories,
        name: productName,
        metal: metalName?.value,
        color: color?.value,
        sku,
        freeze: isFreezed,
      })
    );
  }, [dispatch, categories, isFreezed, sku, color, metalName, productName]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(`${baseurl}/api/categories`);
        setData(response?.data?.document);
      } catch (error) {
        toast.error(error);
      }
    };
    fetchData();
  }, []);

  const fetchColorData = async () => {
    try {
      let accessToken = localStorage.getItem("accessToken");
      let headersList = {
        Accept: "*/*",
        Authorization: `Bearer ${accessToken}`,
      };
      const response = await axios.get(`${baseurl}/api/admin/getcolour`, {
        headers: headersList,
      });

      setColorsData(
        response?.data?.data?.map((color) => ({
          label: color?.name,
          value: color?._id,
        }))
      );
    } catch (error) {
      toast.error(error);
    }
  };

  const fetchMetalData = async () => {
    try {
      let accessToken = localStorage.getItem("accessToken");
      let headersList = {
        Accept: "*/*",
        Authorization: `Bearer ${accessToken}`,
      };
      const response = await axios.get(`${baseurl}/api/admin/getmetal`, {
        headers: headersList,
      });
      setMetalData(
        response?.data?.data?.map((metal) => ({
          label: metal?.name,
          value: metal?._id,
        }))
      );
    } catch (error) {
      toast.error(error);
    }
  };

  useEffect(() => {
    fetchColorData();
    fetchMetalData();
  }, []);

  const handleSelectChange = (selectedOptions) => {
    setSelectedCategories(selectedOptions);
    setCategories(selectedOptions?.map((option) => option?.value));
  };

  const handleColorChange = (selectedOption) => {
    setColor(selectedOption);
  };
  const handleMetalChange = (selectedOption) => {
    setMetalName(selectedOption);
  };

  const handleSearchClick = () => {
    dispatch(
      searchlist({
        category: categories,
        name: productName,
        metal: metalName?.value,
        color: color?.value,
        sku,
        freeze: isFreezed,
      })
    );
  };

  const handleClearClick = () => {
    setProductName("");
    setMetalName("");
    setColor(null);
    setSku("");
    setIsFreezed(null);
    setSelectedCategories([]);
    setCategories([]);
  };
  const handleDelete = async (id) => {
    setDeleteItemId(id);
    setModalOpen(true);
  };

  const confirmDelete = async () => {
    try {
      let accessToken = localStorage.getItem('accessToken');
      let headersList = {
        "Accept": "*/*",
        "Authorization": `Bearer ${accessToken}`
      }
      const response = await axios.delete(`${baseurl}/api/admin/product/${deleteItemId}`, {
        headers: headersList,
      });
      if (response.status === 200) {
        toast.success(response.data.message);
        dispatch(productApi());
      } else {
        toast.error(response.data.message);
      }
      setModalOpen(false);
    } catch (error) {
      toast.error('Something went wrong. Please try again!!');
    } finally {
      setDeleteItemId(null);
      setModalOpen(false);
    }
  };

  const toComponentB = (item) => {
    navigate(`/${item?._id}/add-product`, { state: { item, _id: item?._id } });
  };

  const mapCategoriesToOptions = (categories, parentLabel = "") => {
    return categories
      ?.map((category) => {
        const currentLabel = parentLabel
          ? `${parentLabel} > ${category?.name}`
          : category?.name;
        const option = {
          value: category?._id,
          label: currentLabel,
          isDisabled: false,
        };

        let subcategories = [];
        if (
          category?.nestedSubcategories &&
          category?.nestedSubcategories?.length > 0
        ) {
          subcategories = mapCategoriesToOptions(
            category?.nestedSubcategories,
            currentLabel
          );
        }
        return [option, ...subcategories];
      })
      .flat();
  };

  const categoryOptions = mapCategoriesToOptions(data);
  return (
    <>
      <div className="main-container">
        <div className="">
          <div className="title pb-10 mx-2">
            <h2 className="h3 mb-0">Search Products</h2>
          </div>
          <div className="card-box mb-30">
            <div className="pd-20">
              <form className="mx-2">
                <div className="d-flex">
                  <div className="custom-control custom-radio mb-2">
                    <input
                      type="radio"
                      id="customRadio2"
                      name="customRadio"
                      className="custom-control-input"
                      checked={isFreezed === true}
                      onChange={() => setIsFreezed(true)}
                    />
                    <label
                      className="custom-control-label"
                      htmlFor="customRadio2"
                    >
                      Is Freezed
                    </label>
                  </div>
                  <div className="custom-control custom-radio mb-2 mx-2">
                    <input
                      type="radio"
                      id="customRadio3"
                      name="customRadio"
                      className="custom-control-input"
                      checked={isFreezed === false}
                      onChange={() => setIsFreezed(false)}
                    />
                    <label
                      className="custom-control-label"
                      htmlFor="customRadio3"
                    >
                      Un Freezed
                    </label>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-4 col-sm-12">
                    <label className="mx-1 font-weight-bold">
                      Product Name
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      value={productName}
                      onChange={(e) => setProductName(e.target.value)}
                    />
                  </div>
                  <div className="col-md-4 col-sm-12">
                    <label className="mx-1 font-weight-bold">Metal</label>
                    <Select
                      options={metalData}
                      className="basic-single"
                      classNamePrefix="select"
                      value={metalName}
                      onChange={handleMetalChange}
                    />
                  </div>
                  <div className="col-md-4 col-sm-12">
                    <label className="mx-1 font-weight-bold">Color</label>
                    <Select
                      options={colorsData}
                      className="basic-single"
                      classNamePrefix="select"
                      value={color}
                      onChange={handleColorChange}
                    />
                  </div>
                  <div className="col-md-4 col-sm-12 pt-2">
                    <label className="mx-1 font-weight-bold">SKU</label>
                    <input
                      type="text"
                      className="form-control"
                      value={sku}
                      onChange={(e) => setSku(e.target.value)}
                    />
                  </div>
                  <div className="col-md-4 col-sm-12 pt-2">
                    <label className="font-weight-bold">Category</label>
                    <Select
                      isMulti
                      options={categoryOptions}
                      className="basic-multi-select"
                      classNamePrefix="select"
                      value={selectedCategories}
                      onChange={handleSelectChange}
                      isOptionDisabled={(option) => option?.isDisabled}
                    />
                  </div>
                  <div className="col-md-4 col-sm-12 pt-4">
                    <div className="pt-20">
                      <button
                        type="button"
                        className="btn-sm custom-btn"
                        onClick={handleSearchClick}
                      >
                        Search
                      </button>
                      <button
                        type="button"
                        className="btn-sm btn-secondary mx-2"
                        onClick={handleClearClick}
                      >
                        Clear
                      </button>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
        <div className="pd-20 card-box mb-30">
          {loading ? (
            <Loader />
          ) : (
            <div className="pb-20 table-responsive">
              <table className="table table-striped hover nowrap " id="myTable">
                <thead>
                  <tr>
                    <th className="p-2">Product</th>
                    <th className="p-2">Image</th>
                    <th className="p-2">Is Active</th>
                    <th className="p-2 text-center">Action</th>
                  </tr>
                </thead>
                <tbody>
                  {searchlists?.length > 0 ? (
                    searchlists &&
                    searchlists?.map((data, index) => {
                      return (
                        <tr key={index}>
                          <td>
                            <div>
                              <p className="mb-0">Name: {data?.name}</p>
                              <p className="mb-0">Metal:
                                {data?.metal?.[0]?.name}
                              </p>
                              <p className="mb-0">
                                Color:
                                {data?.color?.map((color) => color.name).join(' | ')}
                              </p>
                              <p className="mb-0">
                                Gross weight:
                                {data?.gross_weight?.map((weight) => weight).join(' | ')}
                              </p>
                              <p className="mb-0">
                                Gold purity:
                                {data?.gold_purity?.map((purity) => purity.name).join(' | ')}
                              </p>
                              <p className="mb-0">SKU {data?.sku}</p>
                            </div>
                          </td>
                          <td>
                            <img
                              src={ImageLink(data?.mediumproduct_image[0])}
                              alt="product-img"
                              width={100}
                            />
                          </td>
                          <td>
                            <div className="custom-control custom-switch">
                              <input
                                type="checkbox"
                                className="custom-control-input"
                                id="customSwitch1"
                                checked={data?.isActive}
                              />
                              <label
                                className="custom-control-label"
                                htmlFor="customSwitch1"
                              ></label>
                            </div>
                          </td>
                          <td className="text-center">
                            <button
                              type="Submit"
                              className="btn-sm btn-outline-info mx-2"
                              onClick={(e) => {
                                e.preventDefault();
                                toComponentB(data, data?._id);
                              }}
                            >
                              <i className="dw dw-edit2 mx-2 "></i>Edit
                            </button>
                            <button
                              type="Submit"
                              className="btn-sm btn-outline-danger mx-2"
                              onClick={() => handleDelete(data?._id)}
                            >
                              <i className="dw dw-delete-3 mx-e"></i> Delete
                            </button>
                          </td>
                        </tr>
                      );
                    })
                  ) : (
                    <tr>
                      <td colSpan="4" className="text-center py-2">
                        No data found
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
          )}
        </div>
      </div>
      <DeleteConfirmationModal
        isOpen={modalOpen}
        toggle={() => setModalOpen(!modalOpen)}
        onConfirm={confirmDelete}
        itemName="product"
      />
    </>
  );
};

export default Searchlist;

import axios from "axios";
import { createSlice } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import { handleTokenErrors } from "./handleTokenErrors";

const baseurl = process.env.REACT_APP_API_BASE_URL;

const subCategoriesSlice = createSlice({
    name: "getsubcategory",
    initialState: {
        isAuthenticated: false,
        user: null,
        error: null,
        loading: false,
        status: true,
    },
    reducers: {
        getsubcategoryPending: (state) => {
            state.loading = true;
        },
        getsubcategorySuccess: (state, { payload }) => {
            state.loading = false;
            state.isAuthenticated = true;
            state.user = payload;
            state.status = 1;
            state.error = null;
        },
        getsubcategoryFailure: (state, { payload }) => {
            state.loading = false;
            state.isAuthenticated = false;
            state.user = null;
            state.status = 0;
            state.error = payload;
        },
    },
});

export const { getsubcategoryPending, getsubcategorySuccess, getsubcategoryFailure } =
    subCategoriesSlice.actions;

export const getsubcategoryApi = (id) => async (dispatch) => {
    const token = localStorage.getItem("accessToken");
    dispatch(getsubcategoryPending());
    try {
        const response = await axios.get(`${baseurl}/api/getsubcategory/${id}`, {
            headers: {
                Authorization: `Bearer ${token}`,
                "Accept": "*/*",
            },
        });
        const data = response.data;
        if (data.status === 1) {
            toast.success(data.message);
            dispatch(getsubcategorySuccess(data));
        } else {
            dispatch(getsubcategoryFailure(data));
            toast.error(data.message);
        }
    } catch (error) {
        handleTokenErrors(error);
        toast.error(error.response.data.originalError);
    }
};

export default subCategoriesSlice.reducer;

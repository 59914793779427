import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { dashboardApi } from "../Redux/dashboardSlice";
import GoldRate from "../Product/GoldRate";
import { Link } from "react-router-dom";

const Dashboard = () => {
  const dispatch = useDispatch();
  const dashboard = useSelector((state) => state.dashboard?.user?.totaldata);
  useEffect(() => {
    dispatch(dashboardApi());
  }, [dispatch]);
  const totalAppointments = dashboard?.Appointments;
  const totalproducts = dashboard?.Products;
  const totalproductinquary = dashboard?.ProductInquary;
  const totalinquary = dashboard?.Inquary;
  return (
    <>
      <div className="main-container">
        <div className="xs-pd-20-10 pd-ltr-20">
          <div className="title pb-20">
            <h2 className="h3 mb-0">K M Choksi Dashboard</h2>
          </div>
          <div className="row pb-10">
            <div className="col-xl-3 col-lg-3 col-md-6 mb-20">
              <Link to="/appointment-booking">
                <div className="card-box height-100-p widget-style3">
                  <div className="d-flex flex-wrap">
                    <div className="widget-data">
                      <div className="weight-700 font-24 text-dark">
                        {totalAppointments}
                      </div>
                      <div className="font-14 text-secondary weight-500">
                        Book Appointment
                      </div>
                    </div>
                    <div className="widget-icon">
                      <div className="icon" data-color="#00eccf">
                        <i className="icon-copy dw dw-calendar1 text-white" />
                      </div>
                    </div>
                  </div>
                </div>
              </Link>
            </div>
            <div className="col-xl-3 col-lg-3 col-md-6 mb-20">
              <Link to="/category">
                <div className="card-box height-100-p widget-style3">
                  <div className="d-flex flex-wrap">
                    <div className="widget-data">
                      <div className="weight-700 font-24 text-dark">
                        {totalproducts}
                      </div>
                      <div className="font-14 text-secondary weight-500">
                        Total Products
                      </div>
                    </div>
                    <div className="widget-icon">
                      <div className="icon">
                      <i className="icon-copy ion-cube text-white"></i>
                      </div>
                    </div>
                  </div>
                </div>
              </Link>
            </div>
            <div className="col-xl-3 col-lg-3 col-md-6 mb-20">
              <Link to="/product-inquiry">
                <div className="card-box height-100-p widget-style3">
                  <div className="d-flex flex-wrap">
                    <div className="widget-data">
                      <div className="weight-700 font-24 text-dark">
                        {totalproductinquary}
                      </div>
                      <div className="font-14 text-secondary weight-500">
                        Product Inquiry
                      </div>
                    </div>
                    <div className="widget-icon">
                      <div className="icon" data-color="#09cc06">
                      <i className="icon-copy fa fa-question text-white" aria-hidden="true"></i>
                      </div>
                    </div>
                  </div>
                </div>
              </Link>
            </div>
            <div className="col-xl-3 col-lg-3 col-md-6 mb-20">
              <Link to="/inquiry">
                <div className="card-box height-100-p widget-style3">
                  <div className="d-flex flex-wrap">
                    <div className="widget-data">
                      <div className="weight-700 font-24 text-dark">
                        {totalinquary}
                      </div>
                      <div className="font-14 text-secondary weight-500">
                        Contact Inquiry
                      </div>
                    </div>
                    <div className="widget-icon">
                      <div className="icon" data-color="#09cc06">
                        <i className="icon-copy fa fa-address-book text-white" aria-hidden="true"></i>
                      </div>
                    </div>
                  </div>
                </div>
              </Link>
            </div>
          </div>
          <div className="row pb-10">
            <div className="col-md-12 mb-20">
              <div className="card-box height-100-p pd-20">
                <GoldRate />
              </div>
            </div>
            <div className="col-md-4 mb-20"></div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Dashboard;

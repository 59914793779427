

import axios from "axios";
import { toast } from "react-toastify";
import { handleTokenErrors } from "./handleTokenErrors";
const { createSlice } = require("@reduxjs/toolkit");

const baseurl = process.env.REACT_APP_API_BASE_URL;
const goldrateSlice = createSlice({
    name: "goldrate",
    initialState: {
        isAuthenticated: false,
        user: null,
        error: null,
        loading: false,
        token: null,
        accessToken: null,
        isSuccess: false,
    },
    reducers: {
        goldratePending: (state) => {
            state.loading = true;
        },
        goldrateSuccess: (state, { payload }) => {
            state.loading = false;
            state.isAuthenticated = true;
            state.isSuccess = true;
            state.user = payload;
            state.token = payload.token;
            state.accessToken = payload.accessToken;
            state.error = null;
        },
        goldrateFailure: (state, { payload }) => {
            state.loading = false;
            state.isAuthenticated = false;
            state.user = null;
            state.error = payload;
        },

    },
});


export const { goldratePending, goldrateSuccess, goldrateFailure } =
    goldrateSlice.actions;

export const goldrateApi = (formData) => async (dispatch) => {
    dispatch(goldratePending());
    const token = localStorage.getItem("accessToken");
    try {
        const response = await axios.post(
            `${baseurl}/api/admin/goldrate`,
            formData, {
            headers: {
                Authorization: `Bearer ${token}`,
                "Accept": "*/*",
            },
        }
        );
        const data = response.data;
        if (data.status === 1) {
            dispatch(goldrateSuccess(data));
            toast.success(data.message);
        } else {
            toast.error(data.error);
            dispatch(goldrateFailure(data));
        }
    } catch (error) {
        handleTokenErrors(error);
        dispatch(goldrateFailure(error.response.data));
    }
};

export default goldrateSlice.reducer;

import axios from "axios";
import { createSlice } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import { handleTokenErrors } from "./handleTokenErrors";
const baseurl = process.env.REACT_APP_API_BASE_URL;

const changePasswordSlice = createSlice({
  name: "changepassword",
  initialState: {
    isAuthenticated: false,
    user: null,
    error: null,
    loading: false,
    status: true,
  },
  reducers: {
    setInfoPending: (state) => {
      state.loading = true;
    },
    setInfoSuccess: (state, { payload }) => {
      state.loading = false;
      state.isAuthenticated = true;
      state.user = payload;
      state.status = 1;
      state.error = null;
    },
    setInfoFailure: (state, { payload }) => {
      state.loading = false;
      state.isAuthenticated = false;
      state.user = null;
      state.status = 0;
      state.error = payload;
    },
  },
});

export const { setInfoPending, setInfoSuccess, setInfoFailure } =
  changePasswordSlice.actions;

export const changePassword = (formData) => async (dispatch) => {
  dispatch(setInfoPending());
  try {
    const token = localStorage.getItem("accessToken");
    const response = await axios.post(
      `${baseurl}/api/admin/changepassword`,
      formData,
      {
        headers: {
          Authorization: `Bearer ${token}`,
          "Accept": "*/*",
        },
      }
    );
    const data = response.data;
    if (data.status === 1) {
      toast.success(data.message);
      dispatch(setInfoSuccess(data));
    } else {
      dispatch(setInfoFailure(data));
      toast.error(data.message);
    }
  } catch (error) {
    toast.error(error.response.data.error);
    handleTokenErrors(error);
  } finally {
    dispatch(setInfoPending());
  }
};

export default changePasswordSlice.reducer;

import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import axios from "axios";
const AddCareerPost = () => {
  const baseurl = process.env.REACT_APP_API_BASE_URL;
  const location = useLocation();
  const navigate = useNavigate();
  const data = location.state?.data || {};
  const isEditing = data._id || "";
  const {
    register,
    handleSubmit,
    reset,
      } = useForm({});

  useEffect(() => {
    reset(location.state?.data);
  }, [location.state?.data, reset]);

  const [loading, setLoading] = useState(false);

  const onSubmit = async (data) => {
    let accessToken = localStorage.getItem("accessToken");
    let headersList = {
      Accept: "*/*",
      Authorization: `Bearer ${accessToken}`,
    };

    let bodyContent = {
      no_of_post: data.no_of_post,
      name: data.name,      
      isActive: data.isActive,      
    };
    if (isEditing) {
      bodyContent.id = data._id;
    }
    setLoading(true);
    let reqOptions = {
      url: `${baseurl}/api/admin/careerpost`,
      method: "POST",
      headers: headersList,
      data: bodyContent,
    };

    let response = await axios.request(reqOptions);
    if (response.data.status === 1) {
      toast.success(response.data.message);
      reset();
      navigate("/career-post");
    } else {
      toast.error(response.originalError);
    }
    setLoading(false);
  };

  return (
    <>
      <div className="main-container">
        <div className="xs-pd-20-10 pd-ltr-20 col-md-12">
          <div className="title pb-20">
            <h2 className="h3 mb-0">Add Career post</h2>
          </div>
          <div className="row">
            <div className="col-md-12 col-sm-12 mb-30">
              <div className="pd-20 card-box height-100-p">
                <div className="pd-20  ">
                  <form onSubmit={handleSubmit(onSubmit)}>
                    <div>
                 <div className=" form-group row">
                        <label className="col-sm-12 col-md-2 col-form-label ">
                          Name
                        </label>
                        <div className="col-md-4">
                          <input
                            className={`form-control`}
                            {...register("name")}
                            placeholder="Enter post name"
                            type="text"
                          />
                        </div>
                      </div>
                      <div className=" form-group row">
                        <label className="col-sm-12 col-md-2 col-form-label ">
                          No. of Post 
                        </label>
                        <div className="col-md-4 ">
                          <input
                            className={`form-control`}
                            {...register("no_of_post")}
                            placeholder="Enter number of post"
                            type="text"
                          />
                        </div>
                      </div>                     
                    </div>

                    <div className="form-group row ">
                      <label className="col-sm-12 col-md-2 col-form-label">
                        Is Active
                      </label>
                      <div className="col-md-10">
                        <div className="custom-control custom-switch">
                          <input
                            type="checkbox"
                            className="custom-control-input"
                            id="customSwitch3"
                            {...register("isActive")}
                          />
                          <label
                            className="custom-control-label"
                            htmlFor="customSwitch3"
                          ></label>
                        </div>
                      </div>
                    </div>

                    <div className="col-md-6 modal-footer justify-content-center">
                      <Link to="/career-post">
                        <button
                          type="button"
                          className="btn-sm btn-secondary"
                          data-dismiss="modal"
                        >
                          Back
                        </button>
                      </Link>
                      <button
                        type="submit"
                        className="btn-sm custom-btn"
                        disabled={loading}
                      >
                        {loading ? "Loading..." : "Save"}
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AddCareerPost
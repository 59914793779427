import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { useSelector } from "react-redux";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { ImageLink } from "../constants";

const getFileExtension = (url) => {
  const path = new URL(url).pathname;
  const extension = path.split(".").pop();
  return extension;
};

const Addbranch = () => {
  const baseurl = process.env.REACT_APP_API_BASE_URL;
  const branchmasters = useSelector(
    (state) => state.branchmaster.data?.document || []
  );

  const location = useLocation();

  const navigate = useNavigate();

  const data = location.state?.data || {};
  const isEditing = data._id || "";
  const Active = data.isActive || false;
  const name = data.name || "";
  const address = data.address || "";
  const city = data.city || "";
  const mobile_no = data.mobile_no || "";
  const googleMap = data.googleMap || "";
  const email = data.email || "";
  const sortOrder = data.sortOrder || "";
  const [loading, setLoading] = useState(false);
  const [previewImage, setPreviewImage] = useState(data.branch_image && data.branch_image ? ImageLink(data.branch_image) : "");
  const [fileExtension, setFileExtension] = useState("");

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      const extension = file.name.split(".").pop();
      setFileExtension(extension);
      const reader = new FileReader();
      reader.onloadend = () => {
        setPreviewImage(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm({});

  const [phone, setPhone] = useState(mobile_no);

  const handlePhoneChange = (value, e) => {
    const formattedPhone = value.startsWith("+") ? value : `+${value}`;
    setPhone(formattedPhone);
  };

  const onSubmit = async (data) => {
    setLoading(true);
    const formData = new FormData();

    if (data.branch_image instanceof File) {
      formData.append("branch_image", data.branch_image);
    } else if (previewImage) {
      const extension = fileExtension || getFileExtension(previewImage);
      const blob = await fetch(previewImage).then((res) => res.blob());
      formData.append("branch_image", blob, `previewImage.${extension}`);
    }

    formData.append("name", data.name);
    formData.append("city", data.city);
    formData.append("address", data.address);
    formData.append("mobile_no", phone);
    formData.append("googleMap", data.googleMap);
    formData.append("sortOrder", data.sortOrder);
    formData.append("email", data.email);
    formData.append("isActive", data.isActive);
    if (isEditing) {
      formData.append("id", isEditing);
    }
    try {
      let accessToken = localStorage.getItem("accessToken");
      let headersList = {
        Accept: "*/*",
        Authorization: `Bearer ${accessToken}`,
      };
      const response = await fetch(`${baseurl}/api/admin/branch`, {
        method: "POST",
        body: formData,
        headers: headersList,
      });
      const responseData = await response.json();
      if (responseData.status === 1) {
        toast.success(responseData.message);
        reset();
        navigate("/store-locator");
      } else {
        toast.error(responseData.originalError);
      }
    } catch (error) {
      toast.error("Error submitting form. Please try again.");
    } finally {
      setLoading(false);
    }
  };


  return (
    <>
      <div className="main-container">
        <div className="xs-pd-20-10 pd-ltr-20 col-md-12">
          <div className="title pb-20">
            <h2 className="h3 mb-0"> Add Branch</h2>
          </div>
          <div className="row">
            <div className="col-md-12 col-sm-12 mb-30">
              <div className="pd-20 card-box height-100-p">
                <div className="pd-20  ">
                  <form onSubmit={handleSubmit(onSubmit)}>
                    <div className="form-group row">
                      <label className="col-sm-12 col-md-2 col-form-label mb-4">
                        Name<span className="text-danger">*</span>
                      </label>
                      <div className="col-md-4 mb-4">
                        <input
                          className={`form-control ${errors.name ? "is-invalid" : ""
                            }`}
                          {...register("name", { required: true })}
                          placeholder="Enter Name"
                          type="text"
                          defaultValue={name}
                        />
                        {errors.name && (
                          <small className="text-danger">
                            Name is required
                          </small>
                        )}
                      </div>

                      <label className="col-sm-12 col-md-2 col-form-label mb-4">
                        Address<span className="text-danger">*</span>
                      </label>
                      <div className="col-md-4 mb-4">
                        <textarea
                          className={`form-control ${errors.address ? "is-invalid" : ""
                            }`}
                          {...register("address", { required: true })}
                          type="text"
                          placeholder="Enter address"
                          defaultValue={address}
                        />
                        {errors.address && (
                          <small className="text-danger">
                            Address is required
                          </small>
                        )}
                      </div>

                      <label className="col-sm-12 col-md-2 col-form-label mb-4">
                        Sort Order<span className="text-danger">*</span>
                      </label>
                      <div className="col-md-4 mb-4">
                        <input
                          className={`form-control ${errors.sortOrder ? "is-invalid" : ""
                            }`}
                          {...register("sortOrder", { required: true })}
                          type="text"
                          placeholder="Enter sort order"
                          defaultValue={
                            sortOrder ? sortOrder : branchmasters.length + 1
                          }
                        />
                        {errors.sortOrder && (
                          <small className="text-danger">
                            Sort Order is required
                          </small>
                        )}
                      </div>

                      <label className="col-sm-12 col-md-2 col-form-label mb-4">
                        City<span className="text-danger">*</span>
                      </label>
                      <div className="col-md-4 mb-4">
                        <input
                          className={`form-control ${errors.city ? "is-invalid" : ""
                            }`}
                          {...register("city", { required: true })}
                          type="text"
                          placeholder="Enter city"
                          defaultValue={city}
                        />
                        {errors.city && (
                          <small className="text-danger">
                            City is required
                          </small>
                        )}
                      </div>

                      <label className="col-sm-12 col-md-2 col-form-label mb-4">
                        Mobile No<span className="text-danger">*</span>
                      </label>
                      <div className="col-md-4 mb-4">
                        <PhoneInput
                          country={"in"}
                          value={phone}
                          onChange={handlePhoneChange}
                          inputClass={`form-control ${errors.phone ? 'is-invalid' : ''}`}
                          inputProps={{
                            autoFocus: true,
                            placeholder: 'Enter phone number',
                            name: 'phone',
                          }}
                        />
                        {errors.phone && (
                          <small className="text-danger">
                            {errors.phone.message}
                          </small>
                        )}
                      </div>

                      <label className="col-sm-12 col-md-2 col-form-label mb-4">
                        Email<span className="text-danger">*</span>
                      </label>
                      <div className="col-md-4 mb-4">
                        <input
                          className={`form-control ${errors.email ? "is-invalid" : ""
                            }`}
                          {...register("email", { required: true })}
                          type="email"
                          placeholder="Enter email"
                          defaultValue={email}
                        />
                        {errors.email && (
                          <small className="text-danger">
                            Email is required
                          </small>
                        )}
                      </div>
                      <label className="col-sm-12 col-md-2 col-form-label">
                        Is Active
                      </label>
                      <div className="col-md-4">
                        <div className="custom-control custom-switch">
                          <input
                            type="checkbox"
                            className="custom-control-input"
                            id="customSwitch3"
                            defaultChecked={Active}
                            {...register("isActive")}
                          />
                          <label
                            className="custom-control-label"
                            htmlFor="customSwitch3"
                          ></label>
                        </div>
                      </div>

                      <label className="col-sm-12 col-md-2 mb-4 col-form-label">
                        Image<span className="text-danger">*</span>
                      </label>
                      <div className="col-md-4 ">
                        <input
                          className={`form-control ${!isEditing && errors.branch_image
                            ? "is-invalid"
                            : ""
                            }`}
                          {...register("branch_image", {
                            required: !isEditing ? true : false,
                          })}
                          type="file"
                          onChange={(event) =>
                            handleFileChange(event, setPreviewImage)
                          }
                        />
                        {previewImage && (
                          <div className="mb-3 mt-3">
                            <img
                              src={previewImage}
                              alt=""
                              className="preview-image"
                              style={{ maxWidth: "50%" }}
                            />
                            <button type="button" className="close-btn" onClick={() => setPreviewImage('')}>
                              X
                            </button>
                          </div>
                        )}
                        {!isEditing && errors.branch_image && (
                          <small className="text-danger">
                            Image is required
                          </small>
                        )}
                        <small className="form-text text-muted">
                          Required image size: 1017 X 392 (.png, .jpg, .jpeg,
                          .webp)
                        </small>
                      </div>
                    </div>

                    <div className="form-group row mb-4">
                      <label className="col-sm-12 col-md-2 col-form-label mb-4">
                        Google Map<span className="text-danger">*</span>
                      </label>
                      <div className="col-md-10 mb-4">
                        <textarea
                          className={`form-control ${errors.googleMap ? "is-invalid" : ""
                            }`}
                          {...register("googleMap", { required: true })}
                          type="text"
                          defaultValue={googleMap}
                          placeholder="Enter google map"
                        />
                        {googleMap && (
                          <div className="mb-3 mt-3">
                            <iframe
                              className="contact-map"
                              src={data.googleMap}
                              width="100%"
                              height={200}
                              style={{ border: 0 }}
                              allowFullScreen=""
                              loading="lazy"
                              referrerPolicy="no-referrer-when-downgrade"
                              title="Google Map"
                            />
                          </div>
                        )}
                        {errors.googleMap && (
                          <small className="text-danger">
                            google map is required
                          </small>
                        )}
                      </div>
                    </div>
                    <div className="modal-footer justify-content-center">
                      <Link to="/store-locator">
                        <button
                          type="button"
                          className="btn-sm btn-secondary"
                          data-dismiss="modal"
                        >
                          Back
                        </button>
                      </Link>
                      <button
                        type="submit"
                        className="btn-sm custom-btn"
                        disabled={loading}
                      >
                        {loading ? "Loading..." : "Save"}
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Addbranch;

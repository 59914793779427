import React, { useEffect, useMemo, useState } from "react";
import { useForm } from "react-hook-form";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { ImageLink } from "../constants";

const baseurl = process.env.REACT_APP_API_BASE_URL;

const getFileExtension = (url) => {
  const path = new URL(url).pathname;
  const extension = path.split(".").pop();
  return extension;
};

const TestiminialForm = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const data = useMemo(() => location.state?.data || {},
    [location.state?.data]
  );
  const isEditing = data._id || "";
  const [loading, setLoading] = useState(false);
  const [previewImage, setPreviewImage] = useState("");
  const [fileExtension, setFileExtension] = useState("");

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      const extension = file.name.split(".").pop();
      setFileExtension(extension);
      const reader = new FileReader();
      reader.onloadend = () => {
        setPreviewImage(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };


  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm({});

  const onSubmit = async (data) => {
    setLoading(true);
    const formData = new FormData();
    formData.append("sortOrder", data.sortOrder);
    formData.append("name", data.name);
    formData.append("isActive", data.isActive);

    if (data.testimonial_image instanceof File) {
      formData.append("testimonial_image", data.testimonial_image);
    } else if (previewImage) {
      const extension = fileExtension || getFileExtension(previewImage);
      const blob = await fetch(previewImage).then((res) => res.blob());
      formData.append("testimonial_image", blob, `previewImage.${extension}`);
    }
    formData.append("message", data.message);
    formData.append("rating", data.rating);
    formData.append("city", data.city);
    formData.append("state", data.state);

    if (isEditing) {
      formData.append("id", isEditing);
    }
    try {
      let accessToken = localStorage.getItem("accessToken");
      let headersList = {
        Accept: "*/*",
        Authorization: `Bearer ${accessToken}`,
      };
      const response = await fetch(`${baseurl}/api/admin/testimonial`, {
        method: "POST",
        body: formData,
        headers: headersList,
      });
      const responseData = await response.json();
      if (responseData.status === 1) {
        toast.success(responseData.message);
        reset();
        navigate("/testimonials");
      } else if (responseData.error === "collection image must be 225 x 208") {
        toast.error(responseData.error);
      } else {
        toast.error(responseData.originalError);
      }
    } catch (error) {
      toast.error("Error submitting form. Please try again.");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    reset(data);
    setPreviewImage(ImageLink(data.testimonial_image));
  }, [data, reset]);

  return (
    <>
      <div className="main-container">
        <div className="xs-pd-20-10 pd-ltr-20 col-md-12">
          <div className="title pb-20">
            <h2 className="h3 mb-0"> Add Testimonial</h2>
          </div>
          <div className="row">
            <div className="col-md-12 col-sm-12 mb-30">
              <div className="pd-20 card-box height-100-p">
                <div className="pd-20  ">
                  <form onSubmit={handleSubmit(onSubmit)}>
                    <div className="form-group row">
                      <label className="col-sm-12 col-md-2 col-form-label mb-4">
                        Name<span className="text-danger">*</span>
                      </label>
                      <div className="col-md-4 mb-4">
                        <input
                          className={`form-control ${errors.title ? "is-invalid" : ""
                            }`}
                          {...register("name", { required: true })}
                          placeholder="Enter name"
                          type="text"
                        />
                        {errors.name && (
                          <small className="text-danger">
                            Name is required
                          </small>
                        )}
                      </div>

                      <label className="col-sm-12 col-md-2 col-form-label mb-4">
                        Sort Order<span className="text-danger">*</span>
                      </label>
                      <div className="col-md-4 mb-4">
                        <input
                          className={`form-control ${errors.sortOrder ? "is-invalid" : ""
                            }`}
                          {...register("sortOrder", { required: true })}
                          placeholder="Enter sort order"
                          type="text"
                        />
                        {errors.sortOrder && (
                          <small className="text-danger">
                            Sort order is required
                          </small>
                        )}
                      </div>

                      <label className="col-sm-12 col-md-2 col-form-label mb-4">
                        Rating<span className="text-danger">*</span>
                      </label>
                      <div className="col-md-4 mb-4">
                        <input
                          className={`form-control ${errors.rating ? "is-invalid" : ""
                            }`}
                          placeholder="Enter rating"
                          {...register("rating", { required: true })}
                          type="number"
                        />
                        {errors.rating && (
                          <small className="text-danger">
                            Rating is required
                          </small>
                        )}
                      </div>

                      <label className="col-sm-12 col-md-2 col-form-label mb-4">
                        City<span className="text-danger">*</span>
                      </label>
                      <div className="col-md-4 mb-4">
                        <input
                          className={`form-control ${errors.city ? "is-invalid" : ""
                            }`}
                          {...register("city", { required: true })}
                          placeholder="Enter city"
                          type="text"
                        />
                        {errors.city && (
                          <small className="text-danger">
                            City is required
                          </small>
                        )}
                      </div>

                      <label className="col-sm-12 col-md-2 col-form-label mb-4">
                        State
                      </label>
                      <div className="col-md-4 mb-4">
                        <input
                          className={`form-control`}
                          {...register("state")}
                          placeholder="Enter state"
                          type="text"
                        />
                      </div>

                      <label className="col-sm-12 col-md-2 mb-4 col-form-label">
                        Image<span className="text-danger">*</span>
                      </label>
                      <div className="col-md-4 ">
                        <input
                          className={`form-control ${!isEditing && errors.testimonial_image
                            ? "is-invalid"
                            : ""
                            }
                          `}
                          {...register("testimonial_image", {
                            required: !isEditing ? true : false,
                          })}
                          type="file"
                          onChange={(event) =>
                            handleFileChange(event, setPreviewImage)
                          }
                        />
                        {previewImage && (
                          <div className="mt-3 mb-3">
                            <img
                              src={previewImage}
                              alt="Preview"
                              className="preview-image w-25"
                            />
                          </div>
                        )}
                        {!isEditing && errors.testimonial_image && (
                          <small className="text-danger">
                            Testimonial image is required
                          </small>
                        )}
                      </div>
                      <label className="col-sm-12 col-md-2 col-form-label mb-4">
                        Message<span className="text-danger">*</span>
                      </label>
                      <div className="col-md-10 mb-4">
                        <textarea
                          className={`form-control ${errors.message ? "is-invalid" : ""
                            }`}
                          {...register("message", { required: true })}
                          type="text"
                          placeholder="Enter Message"
                        />
                        {errors.message && (
                          <small className="text-danger">
                            Message is required
                          </small>
                        )}
                      </div>
                    </div>

                    <div className="form-group row mb-4">
                      <label className="col-sm-12 col-md-2 col-form-label">
                        Is Active
                      </label>
                      <div className="col-md-10">
                        <div className="custom-control custom-switch">
                          <input
                            type="checkbox"
                            className="custom-control-input"
                            id="customSwitch3"
                            {...register("isActive")}
                          />
                          <label
                            className="custom-control-label"
                            htmlFor="customSwitch3"
                          ></label>
                        </div>
                      </div>
                    </div>

                    <div className="modal-footer justify-content-center">
                      <Link to="/testimonials">
                        <button
                          type="button"
                          className="btn-secondary btn-sm"
                          data-dismiss="modal"
                        >
                          Back
                        </button>
                      </Link>
                      <button
                        type="submit"
                        className="btn-primary btn-sm"
                        disabled={loading}
                      >
                        {loading ? "Loading..." : "Save"}
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default TestiminialForm;
